import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListAddressesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListAddressesQuery = { __typename?: 'Query', listAddresses: Array<{ __typename?: 'AddressType', id: number, address: string, isFavorite: boolean, createdAt: any, isUsed: boolean }> };


export const ListAddressesDocument = gql`
    query ListAddresses {
  listAddresses {
    id
    address
    isFavorite
    createdAt
    isUsed
  }
}
    `;

/**
 * __useListAddressesQuery__
 *
 * To run a query within a React component, call `useListAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAddressesQuery(baseOptions?: Apollo.QueryHookOptions<ListAddressesQuery, ListAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAddressesQuery, ListAddressesQueryVariables>(ListAddressesDocument, options);
      }
export function useListAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAddressesQuery, ListAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAddressesQuery, ListAddressesQueryVariables>(ListAddressesDocument, options);
        }
export type ListAddressesQueryHookResult = ReturnType<typeof useListAddressesQuery>;
export type ListAddressesLazyQueryHookResult = ReturnType<typeof useListAddressesLazyQuery>;
export type ListAddressesQueryResult = Apollo.QueryResult<ListAddressesQuery, ListAddressesQueryVariables>;