import { PaymentType, DeliveryStatus } from 'types';

export const accessTokenName = 'accessToken';

export const refreshTokenName = 'refreshToken';

export const expiresName = 'expiresIn';

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const enNameRegex = /^[A-Za-z]{3,30}$/;

export const ruNameRegex = /^[А-Яа-я]{3,30}$/;

export const statusesLabels: Record<DeliveryStatus, string> = {
  USA_STOCK: 'На складе',
  SENT: 'Отправлены в РФ',
  CUSTOMS: 'На таможне в РФ',
  RU_STOK: 'Сортировка в Москве',
  READY_FOR_DELIVERY: 'Готовы к выдаче',
  DELIVERED: 'Доставленные',
};

export const paymentTypes: Record<PaymentType, string> = {
  [PaymentType.BankTransfer]: 'Рубли карта',
  [PaymentType.PaymentGateway]: 'Робокасса',
  [PaymentType.RubCash]: 'Наличными руб',
  [PaymentType.UsdCash]: 'Наличными $',
};
