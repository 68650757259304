import React, { FC, ReactElement } from 'react';
import { FormikProps } from 'formik';
import { Button } from '@consta/uikit/Button';
import { Loader } from '@consta/uikit/Loader';
import { TextField } from '@consta/uikit/TextField';
import { useUser } from 'entities/user';
import { openChat } from 'shared/api/chat';
import { UserData } from '..';
import { FieldWrapper, FieldsWrapper, ProfileBlock, TitleContainer } from './styled';

interface IProps extends FormikProps<UserData> {
  isEditMode: boolean,
  setIsEditMode: (isEditMode: boolean) => void,
};

const ProfileForm: FC<IProps> = ({
  errors,
  isEditMode,
  touched,
  values,
  handleSubmit,
  setFieldValue,
  setIsEditMode,
}: IProps) => {
  const { loading } = useUser();

  const labels: UserData = {
    name: 'Имя на кириллице',
    surname: 'Фамилия на кириллице',
    nameEn: 'Имя латинскими буквами',
    surnameEn: 'Фамилия латинскими буквами',
    email: 'Ваш email',
    phone: 'Номер телефона',
  };

  const renderProfileFields = (): ReactElement => (
    <FieldsWrapper>
      {Object.keys(values).map(renderField)}
    </FieldsWrapper>
  );

  const renderField = (fieldName: string): ReactElement => {
    const isPhone = fieldName === 'phone';
    let value: string = values?.[fieldName as keyof typeof values] ?? '';
    if (isPhone) value = `+${value}`;

    return (
      <FieldWrapper key={fieldName}>
        <label>{labels?.[fieldName as keyof typeof values]}</label>
        <div>
          <TextField
            value={value}
            disabled={!isEditMode || isPhone}
            type='text'
            width='full'
            size='m'
            onChange={(e) => handleFieldChange(fieldName, e.value ?? '')}
            status={hasFieldError(fieldName) ? 'alert' : undefined}
          />
          {isPhone &&
            <p>Если вам необходимо сменить номер телефона, напишите в <a onClick={openChat}>службу поддержки</a></p>
          }
        </div>
      </FieldWrapper>
    );
  };

  const handleFieldChange = (fieldName: string, value: string): void => {
    setFieldValue(fieldName, value);
  };

  const hasFieldError = (name: string): boolean => name in errors && name in touched;

  const handleChangeMode = () => {
    if (!isEditMode) setIsEditMode(true);
    else handleSubmit();
  };

  return (
      <ProfileBlock>
        <TitleContainer>
          <h1>Профиль</h1>
          <Button
            label={isEditMode ? 'Сохранить изменения' : 'Редактировать'}
            form='round'
            className='edit_btn'
            onClick={handleChangeMode}
          />
        </TitleContainer>

        {loading ? <Loader/> : renderProfileFields()}
      </ProfileBlock>
  );
};

export default ProfileForm;
