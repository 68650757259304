import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@consta/uikit/Button';
import { Container } from './ui/styled';
import error404 from './ui/img/error404.png';

const Page404 = () => {
  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  return (
    <Container>
      <img src={error404} alt='error404' />
      <h1>Такой страницы нет</h1>
      <h2>Возможно, эту страницу уже удалили, или в вашей ссылке ошибка</h2>
      <Button label='Вернуться назад' onClick={handleBack}/>
    </Container>
  );
};

export default Page404;
