import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisableTelegramIntegrationMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type DisableTelegramIntegrationMutation = { __typename?: 'Mutation', disableTelegramIntegration: { __typename?: 'UserType', id: number, name?: string | null, nameEn?: string | null, phone: string, email?: string | null, createdAt: any, isTelegramNotificationsEnabled: boolean } };


export const DisableTelegramIntegrationDocument = gql`
    mutation DisableTelegramIntegration {
  disableTelegramIntegration {
    id
    name
    nameEn
    phone
    email
    createdAt
    isTelegramNotificationsEnabled
  }
}
    `;
export type DisableTelegramIntegrationMutationFn = Apollo.MutationFunction<DisableTelegramIntegrationMutation, DisableTelegramIntegrationMutationVariables>;

/**
 * __useDisableTelegramIntegrationMutation__
 *
 * To run a mutation, you first call `useDisableTelegramIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTelegramIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTelegramIntegrationMutation, { data, loading, error }] = useDisableTelegramIntegrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisableTelegramIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DisableTelegramIntegrationMutation, DisableTelegramIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableTelegramIntegrationMutation, DisableTelegramIntegrationMutationVariables>(DisableTelegramIntegrationDocument, options);
      }
export type DisableTelegramIntegrationMutationHookResult = ReturnType<typeof useDisableTelegramIntegrationMutation>;
export type DisableTelegramIntegrationMutationResult = Apollo.MutationResult<DisableTelegramIntegrationMutation>;
export type DisableTelegramIntegrationMutationOptions = Apollo.BaseMutationOptions<DisableTelegramIntegrationMutation, DisableTelegramIntegrationMutationVariables>;