import styled from 'styled-components';

export const UserDataInputContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .TextField {
    width: 100%;
    max-width: 375px;
    margin-bottom: 20px;

    label {
      color: rgba(0, 32, 51, 0.60);
      font-size: 12px;
      font-weight: 700;
      line-height: 140%;
      text-transform: uppercase;
    }
  }

  .row {
    display: flex;
    width: fit-content;
    .TextField {
      width: calc(50% - 10px);
      max-width: 280px;

      &:first-child {
        margin-right: 20px;
      }
    }

    @media (max-width: 640px) {
      flex-direction: column;

      .TextField {
        width: 100%;
        max-width: 375px;
  
        &:first-child {
          margion-right: 0;
        }
      }
    }
  }
`;