import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from '@consta/uikit/Button';
import { Checkbox } from '@consta/uikit/Checkbox';
import styled from 'styled-components';
import { PhoneInput, usePhoneValidation } from 'react-international-phone';
import 'react-international-phone/style.css';
import Title from 'shared/ui/components/title';
import Subtitle from 'shared/ui/components/subtitle';
import Margin from 'shared/ui/components/margin';
import { CaptchaWrapper, PhoneInputContainer, CheckboxContainer } from './styled';
import './captcha.css';

interface dataFormProps{
  onSend: (phone: string, captchaCode?: string) => void
}

const PhoneView = ({ onSend }: dataFormProps) => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [phone, setPhone] = useState('');
  const [checkbox, setCheckbox] = useState(true);
  const phoneValidation = usePhoneValidation(phone);

  const handleSendPhone = async () => {
    const token = await captchaRef.current?.executeAsync();

    onSend(phone, token ?? undefined);
  };

  return (
    <div>
      <div>
        <Title>Добро пожаловать в USA Club Shop!</Title>
        <Margin mb={48} mt={16}>
          <Subtitle>Войдите или зарегистрируйтесь с помощью своего номера телефона</Subtitle>
        </Margin>
        <PhoneInputContainer>
          <PhoneInput
            defaultCountry='ru'
            value={phone}
            onChange={(p) => setPhone(p)}
          />
          <Button
            label='Продолжить'
            form='round'
            disabled={!phoneValidation.isValid || !checkbox}
            onClick={handleSendPhone} />
        </PhoneInputContainer>
        {/* <Margin mt={16}>
          <CheckboxContainer>
            <Checkbox
              size='xs'
              checked={checkbox}
              onChange={
                (object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void =>
                  setCheckbox(object.checked)
              }
            />
            <p>
              Я согласен с <a href='/docs/privacy.pdf' target='_blank'>политикой конфиденциальности</a>
              и <a href='/docs/oferta.pdf' target='_blank'>публичной офертой</a>
            </p>
          </CheckboxContainer>
        </Margin> */}
      </div>
      <CaptchaWrapper>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_TOKEN as string}
          ref={captchaRef}
          size='invisible'
        />
      </CaptchaWrapper>
    </div>
  );
};

export default PhoneView;
