import React, { FC } from 'react';
import HeaderMenu from './ui/header-menu';
import HeaderUser from './ui/header-user';
import { CustomHeader } from './styled';

const Header: FC = () => {
  return (
    <CustomHeader
      rowCenter={{ left: <HeaderMenu />, center: '', right: <HeaderUser /> }}
    />
  );
};

export default Header;