import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrdersListQueryVariables = Types.Exact<{
  filter: Types.OrdersListFilterInput;
  pagination: Types.OrdersListPaginationInput;
}>;


export type OrdersListQuery = { __typename?: 'Query', ordersList: { __typename?: 'PaginatedOrdersType', total: number, items: Array<{ __typename?: 'OrderType', id: number, status: Types.DeliveryStatus, paymentType?: Types.PaymentType | null, paid?: number | null, comment?: string | null, summary: { __typename?: 'OrderSummaryType', totalPackages: number, totalApproximateWeigh?: number | null, totalActualWeigh?: number | null, totalClientWeigh?: number | null, totalWeight?: number | null, shipmentId?: number | null, totalCost?: { __typename?: 'CostType', costUsd: number, costRub?: number | null } | null }, user: { __typename?: 'UserType', name?: string | null, email?: string | null, phone: string, createdAt: any } }> } };


export const OrdersListDocument = gql`
    query ordersList($filter: OrdersListFilterInput!, $pagination: OrdersListPaginationInput!) {
  ordersList(filter: $filter, pagination: $pagination) {
    items {
      id
      status
      summary {
        totalPackages
        totalApproximateWeigh
        totalActualWeigh
        totalClientWeigh
        totalWeight
        totalCost {
          costUsd
          costRub
        }
        shipmentId
      }
      paymentType
      paid
      user {
        name
        email
        phone
        createdAt
      }
      comment
    }
    total
  }
}
    `;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOrdersListQuery(baseOptions: Apollo.QueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
      }
export function useOrdersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
        }
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<typeof useOrdersListLazyQuery>;
export type OrdersListQueryResult = Apollo.QueryResult<OrdersListQuery, OrdersListQueryVariables>;