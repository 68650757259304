import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BoxesListQueryVariables = Types.Exact<{
  filter: Types.BoxesListFilterInput;
  pagination: Types.BoxesListPaginationInput;
}>;


export type BoxesListQuery = { __typename?: 'Query', boxesList: { __typename?: 'PaginatedBoxesType', total: number, items: Array<{ __typename?: 'BoxType', id: number, number: number, weight?: number | null, shipmentId: number, createdAt: any, packagesWeight: number, packages: Array<{ __typename?: 'PackageType', id: number, track: string, createdAt: any, approximateWeight?: number | null }> }> } };


export const BoxesListDocument = gql`
    query boxesList($filter: BoxesListFilterInput!, $pagination: BoxesListPaginationInput!) {
  boxesList(filter: $filter, pagination: $pagination) {
    items {
      id
      number
      weight
      shipmentId
      createdAt
      packagesWeight
      packages {
        id
        track
        createdAt
        approximateWeight
      }
    }
    total
  }
}
    `;

/**
 * __useBoxesListQuery__
 *
 * To run a query within a React component, call `useBoxesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoxesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoxesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBoxesListQuery(baseOptions: Apollo.QueryHookOptions<BoxesListQuery, BoxesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoxesListQuery, BoxesListQueryVariables>(BoxesListDocument, options);
      }
export function useBoxesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoxesListQuery, BoxesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoxesListQuery, BoxesListQueryVariables>(BoxesListDocument, options);
        }
export type BoxesListQueryHookResult = ReturnType<typeof useBoxesListQuery>;
export type BoxesListLazyQueryHookResult = ReturnType<typeof useBoxesListLazyQuery>;
export type BoxesListQueryResult = Apollo.QueryResult<BoxesListQuery, BoxesListQueryVariables>;