import React, { FC, useState, useEffect, useMemo, FocusEvent } from 'react';
import dayjs from 'dayjs';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Table } from '@consta/uikit/Table';
import { Loader } from '@consta/uikit/Loader';
import { Badge } from '@consta/uikit/Badge';
import { PackageType, SortEnum } from 'types';
import MobileFullScreenMenu from 'shared/ui/layout/mobileFullScreenMenu';
import Margin from 'shared/ui/components/margin';
import Padding from 'shared/ui/components/padding';
import { useWindowSize } from 'shared/hooks/useWindowSize';
import Breadcrumbs from 'shared/ui/components/breadcrumbs';
import TableInput from 'shared/ui/components/tableInput';
import { usePackagesListQuery } from '../../../../api/packagesList.generated';
import { useEditPackageMutation } from '../../../../api/editPackage.generated';
import { LinkButton } from '../../../../styled';
import { menu } from '../../../../index';
import MobileView from '../../../waiting/ui/mobileView';
import Counter from '../../../counter';
import { MobilePackagesHeader } from './styled';

interface dataFormProps {
  orderId: number,
  onClose: () => void,
  q?: string,
};

const OrderPackages: FC<dataFormProps> = ({ orderId, onClose, q }: dataFormProps) => {
  const { loading, data, refetch } = usePackagesListQuery({
    variables: {
      filter: {
        orderId,
        q,
      },
      pagination: {
        limit: 100,
        order: { createdAt: SortEnum.Desc },
      },
    },
  });

  const [packages, setPackages] = useState<any[]>([]);

  useEffect(() => {
    if (data?.packagesList?.items) {
      setPackages(data.packagesList.items);
    }
  }, [data]);

  const [editPackage, {data: dataEdit, error: errorEdit}] = useEditPackageMutation();

  useEffect(() => {
    if (dataEdit?.editPackage?.id && !errorEdit) {
      setEditingPackageId(undefined);
      setPackages(packages.map((item: PackageType): PackageType => {
        if (item.id === dataEdit.editPackage.id) {
          return {...item, description: dataEdit.editPackage?.description};
        }
        return item;
      }));
    }
  }, [dataEdit, errorEdit]);

  const [chosenPackagesList, setChosenPackagesList] = useState<number[]>([]);
  const [editingPackageId, setEditingPackageId] = useState<number | undefined>(undefined);

  const onCheck = (id: number, value: boolean): void => {
    if (value) {
      setChosenPackagesList([...chosenPackagesList, id]);
    } else {
      setChosenPackagesList(chosenPackagesList.filter(elem => elem !== id));
    }
  };

  const onCheckAll = (value: boolean): void => {
    if (value) {
      setChosenPackagesList(
        packages.filter(item => item.users[0]?.userId === item.users[1]?.userId).map(item => item.id),
      );
    } else {
      setChosenPackagesList([]);
    }
  };

  const saveDescriptionChange = async (description: string | null) => {
    if (typeof editingPackageId === 'number') {
      await editPackage({
        variables: {
          id: editingPackageId,
          input: { description },
        },
      });
    }
  };

  const [width] = useWindowSize();

  const isMobile = useMemo(() => {
    return width < 640;
  }, [width]);

  const columns = [
    {
      title: <Checkbox
          checked={
            chosenPackagesList.length === packages.filter(item => item.users[0]?.userId === item.users[1]?.userId).length
          }
          onChange={(object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
            onCheckAll(object.checked);
          }}
          size='l'
        />,
      accessor: 'id',
      renderCell: ({id, users}: PackageType) => (
        <Checkbox
          checked={chosenPackagesList.includes(id)}
          onChange={
            (object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
              onCheck(id, object.checked);
            }
          }
          disabled={users[0]?.userId !== users[1]?.userId}
        />
      ),
      width: 50,
      align: 'center',
    },
    {
      title: 'ТРЕК-НОМЕР',
      accessor: 'track',
      sortable: false,
      renderCell: ({ id }: PackageType) => (
        <a href={`/packages/${id}`}>
          <LinkButton>{id}</LinkButton>
        </a>
      ),
    },
    {
      title: 'ОПИСАНИЕ ПОСЫЛКИ',
      accessor: 'description',
      sortable: false,
      renderCell: ({ id, description }: PackageType) => (
        <TableInput
          inputValue={description}
          disabled={id !== editingPackageId}
          onClear={() => setEditingPackageId(undefined)}
          onSave={saveDescriptionChange}
          onClickEdit={() => {
            setEditingPackageId(id);
          }}
          onBlur={() => setEditingPackageId(undefined)}
          small
        />
      ),
    },
    {
      title: 'СТАТУС',
      accessor: 'status',
      sortable: false,
      renderCell: ({ status }: PackageType) => (
        <>{status && <Badge status='normal' label={menu[status]} />}</>
      ),
    },
    {
      title: 'ПРИМЕРНЫЙ ВЕС',
      accessor: 'weight',
      sortable: false,
      renderCell: ({ weight }: PackageType) => weight && (
        <>{weight} кг</>
      ),
    },
    {
      title: 'ПРИМЕРНАЯ СТОИМОСТЬ',
      accessor: 'totalCost',
      sortable: false,
      renderCell: ({ totalCost }: PackageType) => totalCost?.costRub && (
        <>{totalCost.costRub} руб</>
      ),
    },
    {
      title: 'ДАТА ДОБАВЛЕНИЯ',
      accessor: 'createdAt',
      sortable: false,
      width: 160,
      align: 'right',
      renderCell: ({ createdAt }: PackageType) => (
        <>{dayjs(createdAt).format('DD.MM.YYYY')}</>
      ),
    },
  ];

  return (
    <>
      {!packages?.length && loading && <Loader />}
      {packages?.length ? (
        <>
          {!isMobile && (
            <Margin mt={20}>
              {/* @ts-ignore */}
              <Table rows={packages} columns={columns}
                verticalAlign='center'
                borderBetweenColumns
                zebraStriped='odd'
              />
            </Margin>
          )}
          {isMobile && (
            <MobileFullScreenMenu zIndex={1}>
              <Padding pt={26} pb={26} pr={20} pl={20}>
              <MobilePackagesHeader>
                <Breadcrumbs prevText='Посылки' prevClick={onClose} currentText={`Заказ №${orderId}`} />
                <LinkButton
                   className='chooseAll'
                    onClick={() =>
                    onCheckAll(
                      chosenPackagesList.length !==
                      packages.filter((item) => item.users[0]?.userId === item.users[1]?.userId).length,
                    )
                  }
                >
                  {chosenPackagesList.length &&
                  chosenPackagesList.length ===
                    packages.filter((item) => item.users[0]?.userId === item.users[1]?.userId).length
                    ? 'Снять выделение со всех посылок'
                    : 'Выбрать все посылки'}
                </LinkButton>
              </MobilePackagesHeader>
              <MobileView
                packages={packages}
                chosenPackagesList={chosenPackagesList}
                onCheck={onCheck}
                onCheckAll={onCheckAll}
                editingPackageId={editingPackageId}
                onClickEdit={(id: number) => {
                  setEditingPackageId(id);
                }}
                onClear={() => setEditingPackageId(undefined)}
                onSave={saveDescriptionChange}
                onBlur={() => setEditingPackageId(undefined)}
                orderId={orderId}
              />
              </Padding>
            </MobileFullScreenMenu>
          )}
          <Counter ids={chosenPackagesList} onClose={() => setChosenPackagesList([])} onTransfer={() => refetch()} />
        </>
      ) : <></>
      }
    </>
  );
};

export default OrderPackages;
