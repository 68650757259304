import React, { FC, useEffect, useState } from 'react';
import { Tabs } from '@consta/uikit/Tabs';
import { useShipmentsListQuery } from 'pages/admin/boxes/api/shipmentsList.generated';
import { SortEnum } from 'types';
import Margin from '../margin';

interface dataFormProps {
  onChange: (activeTab: number) => void,
  newShipment?: number,
};

const AdminTabs: FC<dataFormProps> = ({ onChange, newShipment }: dataFormProps) => {
  const { data, refetch } = useShipmentsListQuery({
    variables: {
      filter: {},
      pagination: {
        limit: 10000,
        order: {
          createdAt: SortEnum.Desc,
        },
      },
    },
  });

  useEffect(() => {
    if (newShipment && !shipments.find(item => item.id === newShipment)) {
      refetch();
    }
  }, [newShipment]);

  const [activeTab, setActiveTab] = useState<number | undefined>();
  const [shipments, setShipments] = useState<{ id: number }[]>([]);

  useEffect(() => {
    if (data?.shipmentsList?.items) {
      setShipments(data.shipmentsList.items);
      setActiveTab(data.shipmentsList.items[0]?.id);
    }
  }, [data]);

  useEffect(() => {
    if (typeof activeTab === 'number') {
      onChange(activeTab);
    }
  }, [activeTab]);
  
  return (
    <Margin mb={30}>
      <Tabs
        value={activeTab}
        onChange={({ value }) => setActiveTab(value)}
        items={shipments.map(item => item.id)}
        getItemLabel={(item: number) => item}
        fitMode='scroll'
      />
    </Margin>
  );
};

export default AdminTabs;