import React, { FC, useEffect, useState } from 'react';
import { Modal } from '@consta/uikit/Modal';
import { Select } from '@consta/uikit/Select';
import { Button } from '@consta/uikit/Button';
import { useOrdersListQuery } from 'pages/packages/api/ordersList.generated';
import { DeliveryStatus } from 'types';
import { useGetOrderQuery } from '../../api/getOrder.generated';
import { ModalContent } from './styled';

interface dataFormProps {
  packageInfo: any,
  isOpen: boolean,
  onClose: () => void,
  onSelect: (orderId: number) => void,
  setOrderData: (order: any) => void,
};

type OrderItem = {
  label: string
  id: number
}

const OrderModal: FC<dataFormProps> = ({ packageInfo, isOpen, onClose, onSelect, setOrderData }: dataFormProps) => {
  const [ordersItems, setOrdersItems] = useState<OrderItem[]>([]);

  const { data: dataOrder } = useGetOrderQuery({
    variables: {
      id: packageInfo?.orderId,
    },
  });

  const [value, setValue] = useState<OrderItem | null>(null);

  useEffect(() => {
    if (dataOrder?.getOrder) {
      setOrderData(dataOrder?.getOrder);
    }
  }, [dataOrder]);

  const { data } = useOrdersListQuery({
    variables: {
      filter: {
        status: [DeliveryStatus.UsaStock, DeliveryStatus.Sent],
      },
      pagination: {
        limit: 1000,
      },
    },
  });

  useEffect(() => {
    if (data) {
      const newOrdersItems: OrderItem[] = data?.ordersList?.items.map(({ id }) => ({
        id,
        label: `${id}`,
      }));
      setOrdersItems(newOrdersItems);
    }
  }, [data]);

  const handleChangeOrder = (item: OrderItem | null) => {
    if (item) {
      setValue(item);
    }
  };
  
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onClickOutside={onClose}
      onEsc={onClose}
      rootClassName='orderModal'
    >
      <ModalContent>
        <div className='orderModalTitle'>
          Перенос посылки в другой заказ
        </div>
        <Select
          form='round'
          items={ordersItems}
          value={value}
          placeholder='Выберите заказ для переноса'
          size='s'
          onChange={(e) => handleChangeOrder(e.value)}
        />
        <Button label='Переместить' form='round' onClick={() => value && onSelect(value.id)} />
      </ModalContent>
    </Modal>
  );
};

export default OrderModal;