import React, { FC } from 'react';
import { Container, Card } from '../login/styled';
import UserDataView from './ui/userDataView';

const WelcomeForm: FC = () => {
  return (
    <Container>
      <Card>
        <UserDataView />
      </Card>
    </Container>
  );
};

export default WelcomeForm;
