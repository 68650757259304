import styled from 'styled-components';

export const CustomFilterWrapper = styled.div`
  background-color: var(--color-bg-default);
  border: var(--control-border-width) solid var(--color-bg-border);
  border-radius: var(--control-radius);
  box-shadow: var(--shadow-layer);
  padding: var(--space-s);
  width: 312px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 12px;
`;
