import styled from 'styled-components';

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    color: #FAFAFA;
  }

  @media (max-width: 1280px) {
    h1 {
      font-size: 24px;
    }
    button {
      font-size: 14px;
      height: 32px;
    }
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    margin-bottom: 0;
    h1 {
      font-size: 20px;
    }
    button {
      font-size: 12px;
      height:24px;
    }
  }
`;

export const CardsContainer = styled.div`
  margin-top: 40px;
  .card {
    border-radius: 16px;
    font-size: 14px;
    color: rgba(250, 250, 250, 1);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  @media (max-width: 1280px) {
    margin-top: 32px;
    .card {
      font-size: 12px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 14px;
    .card {
      flex-direction: column;
      align-items: start;
      height: unset;
      border-radius: 0;
      margin-bottom: 12px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  .card_btn {
    margin-left: 16px;
  }

  @media (max-width: 1280px) {
    .card_btn {
      margin-left: 12px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 8px;
    width: 100%;
    justify-content: end;
    .card_btn {
      height: 24px;
      width: 24px;
      span {
        height: 12px;
      }
    }
  }
`;

export const EmptyBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  p {
    font-size: 20px;
    color: #FAFAFA;
    margin: 0;
  }

  @media (max-width: 1280px) {
    p {
      font-size: 18px;
    }
  }

  @media (max-width: 640px) {
    align-items: start;
    p {
      font-size: 14px;
    }
  }
`;

export const CustomModal = styled.div`
  width: 800px;
  padding: 10px;
  p {
    color: rgba(250, 250, 250, 1);
  }

  @media (max-width: 900px) {
    width: 560px;
  }

  @media (max-width: 640px) {
    width: calc(100% - 48px);
  }
`;

export const CloseBtnWrapper = styled.div`
  display: none;
  position: fixed;
  top: 10px;
  right: 0;
  z-index: 1;

  @media (max-width: 900px) {
    display: block;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  .hidden {
    opacity: 0;
  }
`;

export const CustomTooltip = styled.div`
  position: absolute;
  background-color: rgba(250, 250, 250, 0.16);
  padding: 8px;
  right: 0;
  margin-top: 6px;
  white-space: nowrap;
  font-size: 12px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.75);
  transition: opacity .2s;
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 5px solid rgba(250, 250, 250, 0.16);
    transform: translateX(-10px);
  }

  @media (max-width: 640px) {
    font-size: 10px;
  }
`;

export const SdekWidgetContainer = styled.div`
  width: 100%;
  height: 600px;
  margin-bottom: 10px;

  .CDEK-widget__logo {
    display: none !important;
  }

  .CDEK-widget__sidebar {
    background: none;
    .CDEK-widget__sidebar-button_phone {
      display: none !important;
    }
    hr {
      display: none !important;
    }
    .CDEK-widget__sidebar-button.active {
      background: none;
    }
  }

  .CDEK-widget__panel {
    right: 0;
  }

  .CDEK-widget__panel-address-search, .CDEK-widget__panel-tag {
    padding: 0 18px;
    .cdek-tag {
      cursor: pointer;
    }
    .active p {
      color: #50a631;
    }
  }

  .CDEK-widget__panel-address-search input {
    width: 100%;
  }

  .CDEK-widget__panel-content {
    height: calc(100% - 197px);
  }

  @media (max-width: 640px) {
    height: calc(100vh - 123px);
  }
`;

export const MobileContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #22272B;
  width: 100vw;
  height: 100vh;
  padding: 0 14px;
`;
