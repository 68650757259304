import React, { FC, useState, useEffect, useRef, FocusEvent } from 'react';
import { Loader } from '@consta/uikit/Loader';
import { PackageType, SortEnum } from 'types';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import { usePackagesListQuery } from '../../api/packagesList.generated';
import { useEditPackageMutation } from '../../api/editPackage.generated';
import { useDeletePackageMutation } from '../../api/deletePackage.generated';
import Empty from '../empty';
import Counter from '../counter';
import MobileView from './ui/mobileView';
import TableView from './ui/tableView';

interface dataFormProps {
  q: string,
}

const Waiting: FC<dataFormProps> = ({ q }: dataFormProps) => {
  const pageSize = 30;
  const [offset, setOffset] = useState(0);

  const { loading, data, refetch } = usePackagesListQuery({
    variables: {
      filter: {
        orderId: null,
        q: q || undefined,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
        order: { createdAt: SortEnum.Desc },
      },
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const [packages, setPackages] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data?.packagesList?.items) {
      if (offset) {
        setPackages([...packages, ...data.packagesList.items]);
      } else {
        setPackages(data.packagesList.items);
        setTotal(data.packagesList.total);
      }
    }
  }, [data]);

  const footerRef = useRef(document.getElementById('footer'));

  const inViewport = useIsInViewport(footerRef);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  const [editPackage, {data: dataEdit, error: errorEdit}] = useEditPackageMutation();

  const [deletePackage, {data: dataDelete, error: errorDelete}] = useDeletePackageMutation();

  const [chosenPackagesList, setChosenPackagesList] = useState<number[]>([]);
  const [editingPackageId, setEditingPackageId] = useState<number | undefined>();

  const saveDescriptionChange = async (description: string | null) => {
    if (typeof editingPackageId === 'number') {
      await editPackage({
        variables: {
          id: editingPackageId,
          input: { description },
        },
      });
    }
  };

  useEffect(() => {
    if (dataEdit?.editPackage?.id && !errorEdit) {
      setEditingPackageId(undefined);
      setPackages(packages.map((item: PackageType) => {
        if (item.id === dataEdit.editPackage.id) {
          return {...item, description: dataEdit.editPackage?.description};
        }
        return item;
      }));
    }
  }, [dataEdit, errorEdit]);

  const onDelete = async (id: number) => {
    await deletePackage({
      variables: {
        id,
      },
    });

    setPackages(packages.filter((item: PackageType) => item.id !== id));
  };

  useEffect(() => {
    if (dataDelete?.deletePackage && !errorDelete) {
      setEditingPackageId(undefined);
    }
  }, [dataDelete, errorDelete]);

  const onCheck = (id: number, value: boolean): void => {
    if (value) {
      setChosenPackagesList([...chosenPackagesList, id]);
    } else {
      setChosenPackagesList(chosenPackagesList.filter(elem => elem !== id));
    }
  };

  const onCheckAll = (value: boolean): void => {
    if (value) {
      setChosenPackagesList(
        packages.filter(item => item.users[0]?.userId === item.users[1]?.userId).map(item => item.id),
      );
    } else {
      setChosenPackagesList([]);
    }
  };

  return (
    <>
      {!packages?.length && (
        loading ? <Loader /> : <Empty />
      ) || null}
      {packages?.length && (
        <>
          <DesktopOnly breakpoint={640}>
            <TableView
              packages={packages}
              chosenPackagesList={chosenPackagesList}
              onCheck={onCheck}
              onCheckAll={onCheckAll}
              onDelete={onDelete}
              editingPackageId={editingPackageId}
              onClickEdit={(id: number) => {
                setEditingPackageId(id);
              }}
              onClear={() => setEditingPackageId(undefined)}
              onSave={saveDescriptionChange}
              onBlur={() => setEditingPackageId(undefined)}
            />
          </DesktopOnly>
          <MobileOnly breakpoint={640}>
            <MobileView
              packages={packages}
              chosenPackagesList={chosenPackagesList}
              onCheck={onCheck}
              onCheckAll={onCheckAll}
              onDelete={onDelete}
              editingPackageId={editingPackageId}
              onClickEdit={(id: number) => {
                setEditingPackageId(id);
              }}
              onClear={() => setEditingPackageId(undefined)}
              onSave={saveDescriptionChange}
              onBlur={() => setEditingPackageId(undefined)}
            />
          </MobileOnly>
          <Counter ids={chosenPackagesList} onClose={() => setChosenPackagesList([])} onTransfer={() => refetch()} />
        </>
      ) || null}
    </>
  );
};

export default Waiting;
