import React, { FC, useState, useEffect, useRef } from 'react';
import { Loader } from '@consta/uikit/Loader';
import { PackageType, SortEnum } from 'types';
import { useTransferredPackagesListQuery } from 'pages/packages/api/transferredPackagesList.generated';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import Empty from '../empty';
import MobileView from './ui/mobileView';
import TableView from './ui/tableView';

interface dataFormProps {
  q: string,
}

const Transferred: FC<dataFormProps> = ({ q }: dataFormProps) => {
  const pageSize = 30;
  const [offset, setOffset] = useState(0);

  const { loading, data } = useTransferredPackagesListQuery({
    variables: {
      filter: {
        q: q || undefined,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
        order: { createdAt: SortEnum.Desc },
      },
    },
  });

  const [packages, setPackages] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data?.transferredPackagesList?.items) {
      if (offset) {
        setPackages([...packages, ...data.transferredPackagesList.items]);
      } else {
        setPackages(data.transferredPackagesList.items);
        setTotal(data.transferredPackagesList.total);
      }
    }
  }, [data]);

  const footerRef = useRef(document.getElementById('footer'));

  const inViewport = useIsInViewport(footerRef);

  useEffect(() => {
    if (inViewport && ((offset + 1) * 15 <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  return (
    <>
      {!packages?.length && (
        loading ? <Loader /> : <Empty />
      ) || null}
      {packages?.length && (
        <>
          <DesktopOnly breakpoint={640}>
            <TableView
              packages={packages}
            />
          </DesktopOnly>
          <MobileOnly breakpoint={640}>
            <MobileView
              packages={packages}
            />
          </MobileOnly>
        </>
      ) || null}
    </>
  );
};

export default Transferred;
