import React, { FC, FocusEvent, useRef, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconClose } from '@consta/uikit/IconClose';
import { IconCheck } from '@consta/uikit/IconCheck';
import { TextField } from '@consta/uikit/TextField';
import { Maybe } from 'types';
import Ellipsis from 'shared/ui/components/ellipsis';
import { CustomTableInput } from './styled';

interface dataFormProps {
  inputValue?: Maybe<string>,
  disabled: boolean,
  onClickEdit: () => void,
  onClear: () => void,
  onSave: (value: string | null) => void,
  onBlur: () => void,
  small?: boolean,
  number?: boolean
  height?: number
  onKeyDown?: (value: string | null) => void
};

const TableInput: FC<dataFormProps> = ({
  inputValue,
  disabled,
  onClickEdit,
  onClear,
  onSave,
  onBlur,
  small,
  number,
  height,
  onKeyDown,
}: dataFormProps) => {
  const [input, setInput] = useState<string | null>(inputValue ?? null);
  const ref = useRef<HTMLDivElement>(null);

  const clear = () => {
    setInput(inputValue ?? null);
    onClear();
  };

  const blur = (event: FocusEvent<HTMLElement>) => {
    if (!event.relatedTarget || !ref.current?.contains(event.relatedTarget)) {
      setInput(inputValue ?? null);
      onBlur();
    }
  };
  
  return (
    <CustomTableInput ref={ref} small={small} height={height}>
      {disabled && (
        <>
          <div className='customInputText'>
            <Ellipsis>{input}</Ellipsis>
          </div>
           <Button
            label='Редактировать'
            view='clear'
            iconLeft={IconEdit}
            onlyIcon
            size='s'
            onClick={onClickEdit}
          />
        </>
      )}
      {!disabled && (
        <>
          <TextField
            view='clear'
            value={input}
            onChange={({ value }) => setInput(value)}
            onBlur={blur}
            autoFocus
            type={number ? 'number' : 'text'}
            incrementButtons={false}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                if (onKeyDown) {
                  onKeyDown(input);
                }
              }
            }}
          />
          <div className='customInputRow'>
            <Button
              view='clear'
              iconLeft={IconClose}
              onlyIcon
              size='s'
              onClick={clear}
            />
            <Button
              label='Сохранить'
              view='clear'
              iconLeft={IconCheck}
              onlyIcon
              size='s'
              onClick={() => onSave(input)}
            />
          </div>
        </>
      )}
    </CustomTableInput>
  );
};

export default TableInput;
