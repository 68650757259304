import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateShipmentMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateShipmentInput;
}>;


export type UpdateShipmentMutation = { __typename?: 'Mutation', updateShipment: { __typename?: 'ShipmentType', id: number, currencyRate?: number | null, status: Types.DeliveryStatus, createdAt: any, summary: { __typename?: 'ShipmentSummaryType', totalApproximateWeigh?: number | null, totalActualWeigh?: number | null, totalPackages: number, totalWeightedPackages: number, totalClientWeigh?: number | null, shipmentWeight: number, totalBoxes: number, totalCost?: { __typename?: 'CostType', costUsd: number, costRub?: number | null } | null, paymentSummary: Array<{ __typename?: 'ShipmentPaymentSummaryType', paymentType: Types.PaymentType, total?: number | null }> } } };


export const UpdateShipmentDocument = gql`
    mutation UpdateShipment($id: Int!, $input: UpdateShipmentInput!) {
  updateShipment(id: $id, input: $input) {
    id
    currencyRate
    status
    summary {
      totalApproximateWeigh
      totalActualWeigh
      totalPackages
      totalWeightedPackages
      totalClientWeigh
      shipmentWeight
      totalBoxes
      totalCost {
        costUsd
        costRub
      }
      paymentSummary {
        paymentType
        total
      }
    }
    createdAt
  }
}
    `;
export type UpdateShipmentMutationFn = Apollo.MutationFunction<UpdateShipmentMutation, UpdateShipmentMutationVariables>;

/**
 * __useUpdateShipmentMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentMutation, { data, loading, error }] = useUpdateShipmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShipmentMutation, UpdateShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShipmentMutation, UpdateShipmentMutationVariables>(UpdateShipmentDocument, options);
      }
export type UpdateShipmentMutationHookResult = ReturnType<typeof useUpdateShipmentMutation>;
export type UpdateShipmentMutationResult = Apollo.MutationResult<UpdateShipmentMutation>;
export type UpdateShipmentMutationOptions = Apollo.BaseMutationOptions<UpdateShipmentMutation, UpdateShipmentMutationVariables>;