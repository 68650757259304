import React, { FC, ReactElement, useState } from 'react';
import * as Apollo from '@apollo/client';
import { Button, ButtonPropView } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { IconComponent } from '@consta/uikit/Icon';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconFavoriteFilled } from '@consta/icons/IconFavoriteFilled';
import { IconFavoriteStroked } from '@consta/icons/IconFavoriteStroked';
import { IconTrash } from '@consta/uikit/IconTrash';
import { AddressType, Maybe } from 'types';
import { useAddresses } from 'entities/addresses';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import Ellipsis from 'shared/ui/components/ellipsis';
import { useEditAddressMutation, EditAddressMutation } from '../api/editAddress.generated';
import { useDeleteAddressMutation } from '../api/deleteAddress.generated';
import { ButtonsWrapper, CustomTooltip, ButtonWrapper } from './styled';

interface IProps {
  address: AddressType,
  idx: number,
  currentFavoriteAddressIds: Maybe<number[]>,
  onEditAddress: (address: AddressType) => void
};

interface IToolsBtn {
  title: string,
  icon: IconComponent,
  isDisabled?: boolean,
  view?: ButtonPropView,
  callback?: () => void,
};

const AddressCard: FC<IProps> = ({ address, idx, currentFavoriteAddressIds, onEditAddress }: IProps) => {
  const { id, isFavorite, address: addressValue, isUsed } = address;
  const [editAddress] = useEditAddressMutation();
  const [deleteAddress] = useDeleteAddressMutation();
  const { refetch: refetchAddresses } = useAddresses();
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);

  const handleSetAddressFavorite = async () => {
    if (isFavorite) return;

    const promises: Promise<Apollo.FetchResult<EditAddressMutation>>[] = [];

    if (currentFavoriteAddressIds) {
      currentFavoriteAddressIds.forEach(favoriteId => {
        promises.push(editAddress({
          variables: {
            id: favoriteId,
            input: {
              isFavorite: false,
            },
          },
        }));
      });

      await Promise.all(promises).then(() => {
        editFavoriteAddress();
      });
    } else editFavoriteAddress();
  };

  const editFavoriteAddress = () => {
    editAddress({
      variables: {
        id,
        input: {
          isFavorite: true,
        },
      },
    });
  };

  const handleDeleteAddress = () => {
    deleteAddress({
      variables: {
        id,
      },
    }).then(() => {
      refetchAddresses();
    });
  };

  const buttons: IToolsBtn[] = [
    {
      title: 'Сделать избранным',
      icon: isFavorite ? IconFavoriteFilled : IconFavoriteStroked,
      view: isFavorite ? 'primary' : 'ghost',
      callback: handleSetAddressFavorite,
    },
    {
      title: 'Редактировать',
      icon: IconEdit,
      isDisabled: isUsed,
      callback: () => onEditAddress(address),
    },
    {
      title: 'Удалить',
      icon: IconTrash,
      isDisabled: isUsed,
      callback: handleDeleteAddress,
    },
  ];

  const renderButtons = (): ReactElement => (
    <ButtonsWrapper>
      {buttons.map(renderButton)}
    </ButtonsWrapper>
  );

  const renderButton = ({ title, icon, view, isDisabled, callback }: IToolsBtn, idBtn: number): ReactElement => {
    const tooltipClass = title === 'Редактировать' && !isTooltipEdit || title === 'Удалить' && !isTooltipDelete
      ? 'hidden'
      : undefined;

    return (
      <ButtonWrapper>
        <Button
          key={idBtn}
          size='s'
          iconLeft={icon}
          onlyIcon
          title={title}
          disabled={isDisabled}
          view={view ?? 'ghost'}
          className='card_btn'
          onClick={callback}
          onMouseEnter={() => handleMouseOnBtn(title, true)}
          onMouseLeave={() => handleMouseOnBtn(title, false)}
        />
        {isDisabled && (
          <CustomTooltip className={tooltipClass}>
            Адрес используется
          </CustomTooltip>
        )}
      </ButtonWrapper>
    );
  };

  const handleMouseOnBtn = (title: string, value: boolean) => {
    if (title === 'Редактировать') setIsTooltipEdit(value);
    else if (title === 'Удалить') setIsTooltipDelete(value);
  };

  return (
    <>
      <DesktopOnly breakpoint={640}>
        <Card border={true} className='card' verticalSpace='m' horizontalSpace='xl' key={idx}>
          <Ellipsis>{addressValue}</Ellipsis>
          {renderButtons()}
        </Card>
      </DesktopOnly>
      <MobileOnly breakpoint={640}>
        <Card form='square' className='card' verticalSpace='m' horizontalSpace='s' key={idx}>
          {addressValue}
          {renderButtons()}
        </Card>
      </MobileOnly>
    </>
  );
};

export default AddressCard;
