import styled from 'styled-components';

export const NameBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    position: absolute;
    left: 40px;
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    color: #FAFAFA;

    .Button {
      margin-left: 20px;
    }

    @media (max-width: 1280px) {
      font-size: 24px;
    }

    @media (max-width: 640px) {
      font-size: 20px;
    }
  }

  .TextField {
    width: 310px;

    @media (max-width: 640px) {
      width: 140px;
    }
  }

  .Select_type_combobox {
    max-width: 310px;
    margin: 0 10px;
  }

  .delete {
    color: #F73B3B !important;

    svg {
      path {
        fill: #F73B3B !important;
      }
    }
  }
`;

export const LinkButton = styled.div`
  color: #0F9FFF;
  cursor: pointer;
  margin: 0;
  padding: 0;

  &.red {
    color: #F73B3B;
    margin-left: auto;
  }

  &.chooseAll {
    margin-bottom: 10px;
    width: fit-content;
    margin-left: auto;
  }

  @media (max-width: 640px) {
    font-size: 10px;
  }
`;