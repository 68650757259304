import React, { FC } from 'react';
import dayjs from 'dayjs';
import { PackageType } from 'types';
import { MobileCardBlock } from '../../../waiting/ui/mobileView/styled';

interface dataFormProps {
  packages: PackageType[],
};

const MobileView: FC<dataFormProps> = ({
  packages,
}: dataFormProps) => {
  return (
    <>
      {packages.map((item: PackageType) => (
        <MobileCardBlock key={item.id}>
          <div className='row'>
            <div className='title'>ТРЕК-НОМЕР</div>
            <div className='text'>{item.track}</div>
          </div>
          <div className='row'>
            <div className='title'>ДАТА ДОБАВЛЕНИЯ</div>
            <div className='text'>
              {dayjs(item.createdAt).format('DD.MM.YYYY')}
            </div>
          </div>
          <div className='row'>
            <div className='title'>ОПИСАНИЕ</div>
            <div className='text'>
              {item.description}
            </div>
          </div>
        </MobileCardBlock>
      ))}
    </>
  );
};

export default MobileView;
