import React, { FC, useState, useEffect } from 'react';
import { Select } from '@consta/uikit/Select';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { useAddresses } from 'entities/addresses';
import AddressAddModal from 'pages/addresses/ui/addressAddModal';
import { AddressType, Maybe } from 'types';
import Margin from 'shared/ui/components/margin';
import { AddressFieldContainer } from './styled';

type AddressItem = {
  id: Maybe<number>
  label: string
}

interface dataFormProps {
  address?: Maybe<AddressType>,
  onSelect: (addressId: number) => void,
}

const AddressField: FC<dataFormProps> = ({ address: currentAddress, onSelect }: dataFormProps) => {
  const [addressesItems, setAddressesItems] = useState<AddressItem[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { listAddresses } = useAddresses();

  const pickup = {
    id: null,
    label: 'Самовывоз',
  };

  const [value, setValue] = useState<AddressItem>(
    currentAddress ? { id: currentAddress.id, label: currentAddress.address } : pickup,
  );

  useEffect(() => {
    if (listAddresses) {
      const newAddressesItems: AddressItem[] = listAddresses.map(({ id, address }) => ({
        id,
        label: address,
      }));
      setAddressesItems([pickup, ...newAddressesItems]);
    }
  }, [listAddresses]);

  const handlePackageAddressChange = (item) => {
    if (item) {
      setValue(item);
      onSelect(item.id);
    }
  };

  const addAddressCallback = (id: number, inputValue: string) => {
    setValue({ id, label: inputValue });
  };

  return (
    <>
      <AddressFieldContainer>
        <Select
          items={addressesItems}
          getItemKey={(item) => item.id ?? 'pickup'}
          value={value}
          placeholder='Выберите вариант'
          size='s'
          onChange={(e) => handlePackageAddressChange(e.value)}
        />
        <Margin ml={6}>
          <Button
            size='s'
            width='full'
            iconLeft={IconAdd}
            onlyIcon
            onClick={() => setIsModalOpen(true)}
          />
        </Margin>
      </AddressFieldContainer>
      <AddressAddModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        createAddressCallback={addAddressCallback}
      />
    </>
  );
};

export default AddressField;