import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPackageQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetPackageQuery = { __typename?: 'Query', getPackage: { __typename?: 'PackageType', id: number, track: string, description?: string | null, createdAt: any, weight?: number | null, status?: Types.DeliveryStatus | null, orderId?: number | null, users: Array<{ __typename?: 'PackageToUserRelationType', userId: number, relation: Types.PackagesOnUsersRelation, user: { __typename?: 'UserType', id: number, name?: string | null, nameEn?: string | null } }>, totalCost?: { __typename?: 'CostType', costUsd: number, costRub?: number | null } | null, address?: { __typename?: 'AddressType', id: number, address: string, createdAt: any, isFavorite: boolean } | null } };


export const GetPackageDocument = gql`
    query getPackage($id: Int!) {
  getPackage(id: $id) {
    id
    track
    description
    createdAt
    users {
      userId
      user {
        id
        name
        nameEn
      }
      relation
    }
    weight
    totalCost {
      costUsd
      costRub
    }
    status
    orderId
    address {
      id
      address
      createdAt
      isFavorite
    }
  }
}
    `;

/**
 * __useGetPackageQuery__
 *
 * To run a query within a React component, call `useGetPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPackageQuery(baseOptions: Apollo.QueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
      }
export function useGetPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
        }
export type GetPackageQueryHookResult = ReturnType<typeof useGetPackageQuery>;
export type GetPackageLazyQueryHookResult = ReturnType<typeof useGetPackageLazyQuery>;
export type GetPackageQueryResult = Apollo.QueryResult<GetPackageQuery, GetPackageQueryVariables>;