import React, { FC, useState, useEffect, useRef } from 'react';
import * as Apollo from '@apollo/client';
import { Modal } from '@consta/uikit/Modal';
import { Button } from '@consta/uikit/Button';
import { Informer } from '@consta/uikit/Informer';
import { IconQuestion } from '@consta/uikit/IconQuestion';
import { Tooltip } from '@consta/uikit/Tooltip';
import { PhoneInput, usePhoneValidation } from 'react-international-phone';
import { AddRecipientMutation, useAddRecipientMutation } from 'pages/packages/api/addRecipient.generated';
import { ModalContent } from './styled';
import 'react-international-phone/style.css';
import './modal.css';

interface dataFormProps {
  ids: number[],
  isOpen: boolean,
  onClose: () => void,
  onTransfer: () => void,
};

const TransferModal: FC<dataFormProps> = ({ ids, isOpen, onClose, onTransfer }: dataFormProps) => {
  const [phone, setPhone] = useState('');
  const phoneValidation = usePhoneValidation(phone);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (!isOpen) {
      setPhone('');
      setStep(1);
    }
  }, [isOpen]);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsTooltipVisible(false);
    };
  });

  const [addRecipient] = useAddRecipientMutation();

  const handleClickPhone = () => {
    setStep(2);
  };

  const handleApproveClick = async () => {
    const promises: Promise<Apollo.FetchResult<AddRecipientMutation>>[] = [];

    ids.forEach(packageId => {
      promises.push(
        addRecipient({
          variables: {
            input: {
              packageId,
              phone: phone.replace(/[^0-9]/g, ''),
            },
          },
        }),
      );
    });

    await Promise.all(promises).then(() => {
      onTransfer();
      onClose();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onClickOutside={onClose}
      onEsc={onClose}
      rootClassName='transferModal'
    >
      <ModalContent>
        {(step === 1) && (
          <>
            <div className='modalTitle'>
              Передача посылок другому пользователю
            </div>
            <div className='modalText'>
              <p>Вы можете передать посылки другому пользователю USA Club Shop по номеру телефона.</p>
              <Button
                ref={buttonRef}
                onClick={() => setIsTooltipVisible(!isTooltipVisible)}
                label='Информация'
                view='clear'
                form='round'
                size='s'
                iconLeft={IconQuestion}
                onlyIcon
              />
              {isTooltipVisible && <Tooltip anchorRef={buttonRef} onClickOutside={() => setIsTooltipVisible(false)}>
                Если человек, которому вы хотите передать посылки, еще не зарегистрирован в сервисе USA Club Shop, ему будет автоматически создан аккаунт. Получатель посылок сможет войти в свой аккаунт с помощью номера телефона, который вы укажете.
              </Tooltip>}
            </div>
            <div className='modalCount'>
              <div className='modalSubtitle'>
                Количество посылок для передачи:
              </div>
              <p>{ids.length} шт.</p>
            </div>
            <div className='modalSubtitle'>
              Введите номер телефона получателя
            </div>
            <div className='phoneContainer'>
              <PhoneInput
                defaultCountry='ru'
                value={phone}
                onChange={(p) => setPhone(p)}
              />
              <Button
                label='Передать посылку'
                form='round'
                disabled={!phoneValidation.isValid}
                onClick={handleClickPhone}
                className='modalSendButton'
              />
            </div>
            <Informer
              status='warning'
              view='filled'
              title='Убедитесь в правильности номера телефона'
              label='После передачи посылок другому пользователю, вы не сможете самостоятельно вернуть их назад в свой аккаунт.'
            />
          </>
        )}
        {(step === 2) && (
          <>
            <div className='modalTitle'>
              Подтвердите передачу посылок
            </div>
            <Informer
              status='warning'
              view='filled'
              title='Убедитесь в правильности номера телефона'
              label='После передачи посылок другому пользователю, вы не сможете самостоятельно вернуть их назад в свой аккаунт.'
            />
            <div className='modalCount marginTop'>
              <div className='modalSubtitle'>
                Количество посылок для передачи:
              </div>
              <p>{ids.length} шт.</p>
            </div>
            <div className='modalCount'>
              <div className='modalSubtitle'>
                Номер телефона получателя
              </div>
              <p>{phone}</p>
            </div>
            <Button
              label='Подтвердить'
              form='round'
              onClick={handleApproveClick}
              className='modalApproveButton'
            />
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default TransferModal;
