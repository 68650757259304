import styled from 'styled-components';

export const Container = styled.div`
  background: #22272B;
  min-height: 100vh;
  width: 100wv;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const Card = styled.div`
  background: #ffffff;
  width: 100%;
  max-width: 786px;
  border-radius: 35px;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 640px) {
    padding: 40px 20px;
  }
`;