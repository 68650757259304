import React, { FC, FocusEvent } from 'react';
import dayjs from 'dayjs';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Badge } from '@consta/uikit/Badge';
import { PackageType } from 'types';
import TableInput from 'shared/ui/components/tableInput';
import { LinkButton } from '../../../../styled';
import { menu } from '../../../../index';
import { MobileCardBlock } from './styled';

interface dataFormProps {
  packages: PackageType[],
  chosenPackagesList: number[],
  editingPackageId?: number,
  onCheck: (id: number, value: boolean) => void,
  onCheckAll: (value: boolean) => void,
  onDelete?: (id: number) => void,
  onClickEdit: (id: number) => void,
  onClear: () => void,
  onSave: (value: string | null) => void,
  onBlur: () => void,
  orderId?: number,
};

const MobileView: FC<dataFormProps> = ({
  packages,
  chosenPackagesList,
  onCheck,
  onCheckAll,
  onDelete,
  editingPackageId,
  onClickEdit,
  onClear,
  onSave,
  onBlur,
  orderId,
}: dataFormProps) => {
  return (
    <>
      {!orderId && (
        <LinkButton
          className='chooseAll'
          onClick={
            () => onCheckAll(chosenPackagesList.length !== packages.filter(item => item.users[0]?.userId === item.users[1]?.userId).length)
          }
        >
          {(chosenPackagesList.length && chosenPackagesList.length === packages.filter(item => item.users[0]?.userId === item.users[1]?.userId).length)
            ? 'Снять выделение со всех посылок'
            : 'Выбрать все посылки'
          }
        </LinkButton>
      ) || null}
      {packages.map((item: PackageType) => (
        <MobileCardBlock key={item.id}>
          <Checkbox
            checked={chosenPackagesList.includes(item.id)}
            onChange={(object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
              onCheck(item.id, object.checked);
            }}
            disabled={item.users[0]?.userId !== item.users[1]?.userId}
          />
          {orderId && item.status && (
            <div className='row'>
              <Badge status='normal' label={menu[item.status]} />
            </div>
          ) || null}
          <div className='row'>
            <div className='title'>ТРЕК-НОМЕР</div>
            <div className='text'>
              <a href={`/packages/${item.id}`}>
                <LinkButton>{item.track}</LinkButton>
              </a>  
            </div>
          </div>
          <div className='row'>
            <div className='title'>ДАТА СОЗДАНИЯ</div>
            <div className='text'>
              {dayjs(item.createdAt).format('DD.MM.YYYY')}
            </div>
          </div>
          <div className='row'>
            <div className='title'>ОПИСАНИЕ</div>
            <TableInput
              inputValue={item.description}
              disabled={item.id !== editingPackageId}
              onClear={onClear}
              onSave={onSave}
              onClickEdit={() => onClickEdit(item.id)}
              onBlur={onBlur}
            />
          </div>
          {!orderId && onDelete && <div className='row'>
            <LinkButton className='red' onClick={() => onDelete(item.id)}>Удалить</LinkButton>
          </div> || null}
          {orderId && (
            <>
              <div className='row'>
                <div className='title'>ПРИМЕРНЫЙ ВЕС</div>
                <div className='text'>
                  {item.approximateWeight} кг
                </div>
              </div>
              <div className='row'>
                <div className='title'>ПРИМЕРНАЯ СТОИМОСТЬ</div>
                <div className='text'>
                  {item.insuredPrice} руб
                </div>
              </div>
            </>
          ) || null}
        </MobileCardBlock>
      ))}
    </>
  );
};

export default MobileView;
