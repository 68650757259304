import styled from 'styled-components';

export const CustomTableInput = styled.div<{small?: boolean, height?: number}>`
  width: 100%;
  position: relative;
  height: ${({ small, height }) => height ? `${height}px` : small ? '38px' : '100%'};
  display: flex;
  align-items: center;

  .customInputText {
    width: calc(100% - 45px);
    padding-right: 45px;
    white-space: pre-wrap;
    color: #FAFAFA;
  }

  .TextField {
    width: 100%;
    input {
      color: hsla(0,0%,100%,.8) !important;
      font-size: 14px;
      padding-right: 80px;
    }
  }

  & > button, .customInputRow {
    position: absolute;
    right: 10px;
    top: 0;
  }

  .customInputRow {
    display: flex;
  }
`;
