import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PackagesListQueryVariables = Types.Exact<{
  filter: Types.PackagesListFilterInput;
  pagination: Types.PackagesListPaginationInput;
}>;


export type PackagesListQuery = { __typename?: 'Query', packagesList: { __typename?: 'PaginatedPackagesType', total: number, items: Array<{ __typename?: 'PackageType', id: number, track: string, orderId?: number | null, description?: string | null, createdAt: any, approximateWeight?: number | null, actualWeight?: number | null, clientWeight?: number | null, weight?: number | null, deliveryRate?: number | null, deliveryCost?: number | null, insured?: boolean | null, insuredPrice?: number | null, comment?: string | null, status?: Types.DeliveryStatus | null, order?: { __typename?: 'OrderType', id: number, paymentType?: Types.PaymentType | null } | null, box?: { __typename?: 'BoxType', number: number, shipmentId: number } | null, stock?: { __typename?: 'StockType', id: number, code: string } | null, users: Array<{ __typename?: 'PackageToUserRelationType', userId: number, relation: Types.PackagesOnUsersRelation, user: { __typename?: 'UserType', id: number, name?: string | null, nameEn?: string | null } }>, totalCost?: { __typename?: 'CostType', costUsd: number, costRub?: number | null } | null, address?: { __typename?: 'AddressType', id: number, address: string } | null }> } };


export const PackagesListDocument = gql`
    query packagesList($filter: PackagesListFilterInput!, $pagination: PackagesListPaginationInput!) {
  packagesList(filter: $filter, pagination: $pagination) {
    items {
      id
      track
      orderId
      description
      createdAt
      approximateWeight
      actualWeight
      clientWeight
      weight
      deliveryRate
      deliveryCost
      insured
      insuredPrice
      comment
      order {
        id
        paymentType
      }
      box {
        number
        shipmentId
      }
      stock {
        id
        code
      }
      users {
        userId
        user {
          id
          name
          nameEn
        }
        relation
      }
      totalCost {
        costUsd
        costRub
      }
      status
      comment
      address {
        id
        address
      }
    }
    total
  }
}
    `;

/**
 * __usePackagesListQuery__
 *
 * To run a query within a React component, call `usePackagesListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePackagesListQuery(baseOptions: Apollo.QueryHookOptions<PackagesListQuery, PackagesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesListQuery, PackagesListQueryVariables>(PackagesListDocument, options);
      }
export function usePackagesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesListQuery, PackagesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesListQuery, PackagesListQueryVariables>(PackagesListDocument, options);
        }
export type PackagesListQueryHookResult = ReturnType<typeof usePackagesListQuery>;
export type PackagesListLazyQueryHookResult = ReturnType<typeof usePackagesListLazyQuery>;
export type PackagesListQueryResult = Apollo.QueryResult<PackagesListQuery, PackagesListQueryVariables>;