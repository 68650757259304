import styled from 'styled-components';

export const MobilePackagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .chooseAll {
    margin-bottom: 0 !important;
  }
`;