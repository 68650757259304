import * as Yup from 'yup';
import { emailRegex, ruNameRegex, enNameRegex } from 'shared/constants';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required().matches(ruNameRegex),
  nameEn: Yup.string().required().matches(enNameRegex),
  surname: Yup.string().required().matches(ruNameRegex),
  surnameEn: Yup.string().required().matches(enNameRegex),
  email: Yup.string().required().matches(emailRegex),
});
