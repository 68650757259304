import { Navigate, useLocation } from 'react-router-dom';
import { Loader } from '@consta/uikit/Loader';
import { useUser } from 'entities/user';
import { getAuthToken } from 'shared/api/auth';

function RedirectToLogin() {
  const location = useLocation();

  return <Navigate to='/login' state={{ from: location }} replace />;
}

function RedirectToWelcomeForm() {
  const location = useLocation();

  return <Navigate to='/welcome-form' state={{ from: location }} replace />;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, isAuth, loading } = useUser();
  const token = getAuthToken();

  return isAuth
    ? (!user?.name || !user?.email ? <RedirectToWelcomeForm /> : children)
    : (loading || token ? <Loader /> : <RedirectToLogin />);
}

function RedirectToMainPage() {
  const location = useLocation();

  return <Navigate to='/packages' state={{ from: location }} replace />;
}

export function RequireNotAuth({ children }: { children: JSX.Element }) {
  const { isAuth, loading } = useUser();
  const token = getAuthToken();

  return isAuth ? <RedirectToMainPage /> : (loading || token ? <Loader /> : children);
}
