import styled from 'styled-components';

export const HistoryBlock = styled.div`
  width: 350px;
  background: rgba(246, 251, 253, 0.10);
  margin-top: -40px;
  margin-bottom: -40px;
  padding: 52px 40px;
  height: calc(100vh - 314px);

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    color: #FAFAFA;
    margin-bottom: 20px;
  }

  .history {
    border-left: 2px solid rgba(11, 165, 255, 1);
    padding-left: 14px;

    .historyItem {
      font-size: 14px;
      font-weight: 400;
      line-height: 120%;
      color: #FAFAFA;
      margin-bottom: 24px;
      position: relative;

      &::before {
        content: '';
        display: block;
        background: rgba(11, 165, 255, 1);
        border-radius: 50%;
        width: 17px;
        height: 17px;
        position: absolute;
        left: -23px;
      }
    }
  }
`;