import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePackageMutationVariables = Types.Exact<{
  input: Types.CreatePackageInput;
}>;


export type CreatePackageMutation = { __typename?: 'Mutation', createPackage: { __typename?: 'PackageType', id: number } };


export const CreatePackageDocument = gql`
    mutation CreatePackage($input: CreatePackageInput!) {
  createPackage(input: $input) {
    id
  }
}
    `;
export type CreatePackageMutationFn = Apollo.MutationFunction<CreatePackageMutation, CreatePackageMutationVariables>;

/**
 * __useCreatePackageMutation__
 *
 * To run a mutation, you first call `useCreatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackageMutation, { data, loading, error }] = useCreatePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePackageMutation, CreatePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePackageMutation, CreatePackageMutationVariables>(CreatePackageDocument, options);
      }
export type CreatePackageMutationHookResult = ReturnType<typeof useCreatePackageMutation>;
export type CreatePackageMutationResult = Apollo.MutationResult<CreatePackageMutation>;
export type CreatePackageMutationOptions = Apollo.BaseMutationOptions<CreatePackageMutation, CreatePackageMutationVariables>;