import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 32px;
  position: relative;

  .modalTitle {
    color: #FAFAFA;
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 12px;
  }

  .modalText {
    display: flex;
    
    p {
      margin: 0;
      color: #FAFAFA;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      width: calc(100% - 44px);
      margin-right: 20px;
      margin-bottom: 40px;
    }
  }

  .modalSubtitle {
    color: rgba(255, 255, 255, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-right: 12px;
  }

  .modalCount {
    display: flex;
    margin-bottom: 24px;

    &.marginTop {
      margin-top: 28px;
    }

    p {
      margin: 0;
      color: #FAFAFA;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .phoneContainer {
    display: flex;
    margin-top: 8px;
    width: 100%;
    margin-bottom: 40px;

    .react-international-phone-input-container {
      width: calc(100% - 190px);
    }

    .react-international-phone-country-selector-button {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
      height: 48px;
      padding: 0 10px;
      background: transparent;
      border: 1px solid rgba(246, 251, 253, 0.28);
    }

    .react-international-phone-input {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      margin-right: 24px;
      height: 48px;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: rgba(255, 255, 255, 0.80);
      width: calc(100% - 65px);
      background: transparent;
      border: 1px solid rgba(246, 251, 253, 0.28);
      border-left: none;
    }

    .modalSendButton {
      height: 48px;
      width: 190px;
    }
  }

  .modalApproveButton {
    width: 100%;
    margin-top: 40px;
  }

  @media (max-width: 640px) {
    .phoneContainer {
      flex-direction: column;
  
      .react-international-phone-input-container {
        width: 100%;
      }
  
      .react-international-phone-input {
        margin-right: 0;
      }
  
      .modalSendButton {
        height: 48px;
        width: 100%;
        margin-top: 20px;
      }
    }

    .modalSubtitle {
      font-size: 10px;
      font-weight: 700;
      margin-right: 0px;
    }

    .modalCount {
      flex-direction: column;
  
      p {
        margin-top: 4px;
        font-size: 12px;
      }
    }

    .modalTitle {
      font-size: 18px;
    }
  
    .modalText {
      display: flex;
      
      p {
        font-size: 12px;
      }
    }
  }
`;