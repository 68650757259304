import React, { FC, useState, useEffect, useRef } from 'react';
import * as Apollo from '@apollo/client';
import { Table, TableFilterContainer, TableFilters } from '@consta/uikit/Table';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconTrash } from '@consta/uikit/IconTrash';
import { Checkbox } from '@consta/uikit/Checkbox';
import { NameBlock } from 'pages/packages/styled';
import { BoxesListFilterInput, BoxType, BoxesListOrderInput, SortEnum, PackageType } from 'types';
import { useEditPackageMutation } from 'pages/packages/api/editPackage.generated';
import { showErrorNotification } from 'widgets/notifications/api/showErrorNotification';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import AdminTabs from 'shared/ui/components/adminTabs';
import TableInput from 'shared/ui/components/tableInput';
import { InfoWrapper } from '../reconciliation/ui/styled';
import { useGetShipmentQuery } from '../reconciliation/api/getShipment.generated';
import { TableCellPadding } from './styled';
import { useBoxesListQuery } from './api/boxesList.generated';
import { useCreateShipmentMutation } from './api/createShipment.generated';
import { useUpdateBoxMutation } from './api/updateBox.generated';
import ExcelImport from './ui/excelImport';
import BoxesCounter from './ui/boxesCounter';
import { DeleteBoxMutation, useDeleteBoxMutation } from './api/deleteBox.generated';
import { useDeleteShipmentMutation } from './api/deleteShipment.generated';
import { useShipmentsListQuery } from './api/shipmentsList.generated';
import ApproveModal from './ui/approveModal';
import '../table.css';
import './style.css';

const Boxes: FC = () => {
  const [createShipment, {data: dataCreate, error}] = useCreateShipmentMutation();

  useEffect(() => {
    if (dataCreate?.createShipment && !error) {
      setActiveShipment(dataCreate.createShipment.id);
      setNewShipment(dataCreate.createShipment.id);
    }
  }, [dataCreate, error]);

  const pageSize = 40;
  const [activeShipment, setActiveShipment] = useState<number | undefined>();
  const [newShipment, setNewShipment] = useState<number | undefined>();

  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState<Omit<BoxesListFilterInput, 'shipmentId'>>({});
  const [filterCopy, setFilterCopy] = useState<Omit<BoxesListFilterInput, 'shipmentId'>>({});
  const [sortOrder, setSortOrder] = useState<BoxesListOrderInput>({ number: SortEnum.Asc });

  const onChangeShipment = (value: number) => {
    setActiveShipment(value);
  };

  const { data, refetch } = useBoxesListQuery({
    skip: typeof activeShipment !== 'number',
    variables: {
      filter: {
        shipmentId: activeShipment as number,
      },
      pagination: {
        order: sortOrder,
        limit: pageSize,
        offset: offset * pageSize,
      },
    },
  });

  const [boxes, setBoxes] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  useEffect(() => {
    if (data?.boxesList?.items) {
      if (offset) {
        setBoxes([...boxes, ...data.boxesList.items.map(item => ({ ...item, rows: item.packages }))]);
      } else {
        setBoxes(data.boxesList.items.map(item => ({ ...item, rows: item.packages })));
        setTotal(data.boxesList.total);
      }
    }
  }, [data]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  useEffect(() => {
    updateList();
  }, [filter, offset, sortOrder]);

  const updateList = async () => {
    if (typeof activeShipment === 'number') {
      await refetch({
        filter: { shipmentId: activeShipment, ...(filter || {}) },
        pagination: {
          limit: pageSize,
          offset: offset * pageSize,
          order: sortOrder || {},
        },
      });
    }
  };

  const [editingBoxId, setEditingBoxId] = useState<number | undefined>();
  const [updateBox, {data: dataEdit, error: errorEdit}] = useUpdateBoxMutation();

  const saveChanges = async (value: string | null) => {
    const weight = value ? parseFloat(value) : NaN;

    if (typeof editingBoxId === 'number' && !isNaN(weight)) {
      await updateBox({
        variables: {
          id: editingBoxId,
          input: { weight },
        },
      });
    } else {
      showErrorNotification('Поле не может быть пустым');
    }
  };

  useEffect(() => {
    if (dataEdit?.updateBox?.id && !errorEdit) {
      setEditingBoxId(undefined);
      updateList();
    }
  }, [dataEdit, errorEdit]);

  const [editingPackageId, setEditingPackageId] = useState<number | undefined>();
  const [editPackage, {data: dataEditPackage, error: errorEditPackage}] = useEditPackageMutation();

  const savePackageChanges = async (value: string | null) => {
    const approximateWeight = value ? parseFloat(value) : NaN;

    if (typeof editingPackageId === 'number' && !isNaN(approximateWeight)) {
      await editPackage({
        variables: {
          id: editingPackageId,
          input: { approximateWeight },
        },
      });
    } else {
      showErrorNotification('Поле не может быть пустым');
    }
  };

  useEffect(() => {
    if (dataEditPackage?.editPackage?.id && !errorEditPackage) {
      setEditingPackageId(undefined);
      updateList();
    }
  }, [dataEditPackage, errorEditPackage]);

  const [chosenBoxesList, setChosenBoxesList] = useState<number[]>([]);

  const onCheck = (id: number, value: boolean): void => {
    if (value) {
      setChosenBoxesList([...chosenBoxesList, id]);
    } else {
      setChosenBoxesList(chosenBoxesList.filter(elem => elem !== id));
    }
  };

  const onCheckAll = (value: boolean): void => {
    if (value) {
      setChosenBoxesList(
        boxes.map(item => item.id),
      );
    } else {
      setChosenBoxesList([]);
    }
  };

  const [deleteBox] = useDeleteBoxMutation();

  const deleteBoxesFunction = async () => {
    const promises: Promise<Apollo.FetchResult<DeleteBoxMutation>>[] = [];

    chosenBoxesList.forEach(id => {
      promises.push(
        deleteBox({
          variables: {
            id,
          },
        }),
      );
    });

    await Promise.all(promises).then(() => {
      refetch();
    });
  };

  const [deleteShipment] = useDeleteShipmentMutation();

  const [openApproveModal, setOpenApproveModal] = useState(false);

  const { refetch: refetchShipments } = useShipmentsListQuery({
    variables: {
      filter: {},
      pagination: {
        limit: 10000,
        order: {
          createdAt: SortEnum.Desc,
        },
      },
    },
  });

  const onDeleteShipment = () => {
    if (typeof activeShipment === 'number') {
      deleteShipment({
        variables: {
          id: activeShipment,
        },
      }).then(() => refetchShipments());
    }
  };

  const { data: dataShipment, refetch: refetchDataShipment } = useGetShipmentQuery({
    skip: typeof activeShipment !== 'number',
    variables: {
      id: activeShipment as number,
    },
  });

  const columns = [
    {
      title: 'НОМЕР БОКСА',
      accessor: 'number',
      sortable: true,
    },
    {
      title: 'ТРЕК-НОМЕР ПОСЫЛКИ',
      accessor: 'track',
      sortable: true,
    },
    {
      title: 'ВЕС ПОСЫЛКИ',
      accessor: 'approximateWeight',
      sortable: true,
      renderCell: ({ id, approximateWeight, __typename }: PackageType) => {
        if (__typename === 'PackageType') {
          return (<TableInput
            inputValue={approximateWeight?.toString()}
            disabled={id !== editingPackageId}
            onClear={() => setEditingPackageId(undefined)}
            onSave={savePackageChanges}
            onClickEdit={() => {
              setEditingPackageId(id);
            }}
            onBlur={() => setEditingPackageId(undefined)}
            small
            number
          />);
        };
      },
    },
    {
      title: 'ВЕС ВСЕХ ПОСЫЛОК',
      accessor: 'packagesWeight',
      sortable: false,
    },
    {
      title: 'ВЕС ВМЕСТЕ С БОКСОМ',
      accessor: 'weight',
      sortable: true,
      renderCell: ({ id, weight, __typename }: BoxType) => {
        if (__typename === 'BoxType') {
          return (<TableInput
            inputValue={weight?.toString()}
            disabled={id !== editingBoxId}
            onClear={() => setEditingBoxId(undefined)}
            onSave={saveChanges}
            onClickEdit={() => {
              setEditingBoxId(id);
            }}
            onBlur={() => setEditingBoxId(undefined)}
            small
            number
          />);
        }
      },
    },
    {
      title: <Checkbox
          checked={!!boxes.length && chosenBoxesList.length === boxes.length}
          onChange={(object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
            onCheckAll(object.checked);
          }}
          size='l'
        />,
      accessor: 'id',
      renderCell: ({ id, number }: BoxType) => (
        <>
          {number && <Checkbox
            checked={chosenBoxesList.includes(id)}
            onChange={
              (object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
                onCheck(id, object.checked);
              }
            }
          />}
        </>
      ),
      width: 50,
      align: 'center',
    },
  ];

  const handleSortingUpdated = (value) => {
    setOffset(0);

    let sortVal: string = value?.sortingBy;
    if (value?.sortingBy === 'approximateWeight') {
      sortVal = 'packageWeight';
    } else if (value?.sortingBy === 'track') {
      sortVal = 'packageTrack';
    }

    if (sortVal) {
      const sort = {
        [sortVal]: value?.sortOrder.toUpperCase() === SortEnum.Asc ? SortEnum.Asc : SortEnum.Desc,
      };
      setSortOrder(sort);
    }
  };

  const handleFilterUpdated = (value: any) => {
    setOffset(0);

    const filtersObj = { ...filter };
    
    Object.keys(value).forEach(item => {
      const filterVal = item === 'track' ? 'packageTrack' : item;
      if (value[item]?.selected?.length) {
        filtersObj[filterVal] = filterCopy[filterVal];
      }

      if (filter && filter[filterVal] && !value[item]?.selected?.length) {
        filtersObj[filterVal] = undefined;
      }
    });

    setFilter(filtersObj);
    setFilterCopy(filtersObj);
  };

  const filters: TableFilters<any> = [
    {
      id: 'number',
      name: `Номер бокса: ${filter?.number}`,
      field: 'number',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.number?.toString()}
              onChange={({ value }) => setFilterCopy({ ...filterCopy, number: value ? parseInt(value) : undefined })}
              width='full'
            />
          ),
        },
      },
    },
    {
      id: 'track',
      name: `Трек-номер: ${filter?.packageTrack}`,
      field: 'track',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: (
            <TextField
              value={filterCopy?.packageTrack}
              onChange={({ value }) => setFilterCopy({ ...filterCopy, packageTrack: value })}
              width='full'
            />
          ),
        },
      },
    },
  ];

  const getAdditionalClassName = ({ row }: any): string => {
    return row.number ? 'filled' : '';
  };

  return (
    <>
      <NameBlock>
        <h1>
          США: Формирование
          <Button
            label='Добавить новую партию'
            form='round'
            iconLeft={IconAdd}
            onClick={() => createShipment()}
          />
        </h1>
        {typeof activeShipment === 'number' && <ExcelImport
          shipmentId={activeShipment}
          onImport={() => Promise.all([refetch(), refetchDataShipment()])}
        />}
        {typeof activeShipment === 'number' && <Button
          label='Удалить партию'
          className='delete'
          view='clear'
          iconLeft={IconTrash}
          onClick={() => setOpenApproveModal(true)}
        />}
      </NameBlock>
      <AdminTabs onChange={onChangeShipment} newShipment={newShipment} />
      <InfoWrapper>
        <div>
          <p>Всего боксов в партии</p>
          <p className='info_value'>
            {dataShipment?.getShipment.summary.totalBoxes}
          </p>
        </div>
        <div>
          <p>Всего посылок в партии</p>
          <p className='info_value'>
            {dataShipment?.getShipment.summary.totalPackages}
          </p>
        </div>
      </InfoWrapper>
      <TableCellPadding>
        {/* @ts-ignore */}
        <Table rows={boxes} columns={columns} filters={filters}
          verticalAlign='center'
          borderBetweenColumns
          borderBetweenRows
          getAdditionalClassName={getAdditionalClassName}
          onSortBy={(value) => handleSortingUpdated(value)}
          onFiltersUpdated={(value) => handleFilterUpdated(value)}
        />
      </TableCellPadding>
      <BoxesCounter ids={chosenBoxesList} onClose={() => setChosenBoxesList([])} onClick={() => deleteBoxesFunction()} />
      <ApproveModal
        title='Вы точно хотите удалить партию? '
        isOpen={openApproveModal}
        onClose={() => setOpenApproveModal(false)}
        onClick={onDeleteShipment}
      />
    </>
  );
};

export default Boxes;
