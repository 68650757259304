import React, { FC, useState, useEffect, useRef } from 'react';
import { Loader } from '@consta/uikit/Loader';
import { OrderType, DeliveryStatus } from 'types';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { useOrdersListQuery } from '../../api/ordersList.generated';
import Empty from '../empty';
import OrderCard from './ui/orderCard';

interface dataFormProps {
  status: DeliveryStatus,
  q: string,
}

const Orders: FC<dataFormProps> = ({ status, q }: dataFormProps) => {
  const pageSize = 30;
  const [offset, setOffset] = useState(0);

  const { loading, data } = useOrdersListQuery({
    variables: {
      filter: {
        status: [status],
        q: q || undefined,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
      },
    },
  });

  const [orders, setOrders] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (data?.ordersList?.items) {
      if (offset) {
        setOrders([...orders, ...data.ordersList.items]);
      } else {
        setOrders(data.ordersList.items);
        setTotal(data.ordersList.total);
      }
    }
  }, [data]);

  const footerRef = useRef(document.getElementById('footer'));

  const inViewport = useIsInViewport(footerRef);

  useEffect(() => {
    if (inViewport && ((offset + 1) * 15 <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  const [expandedOrderId, setExpandedOrderId] = useState<number | undefined>();

  useEffect(() => {
    setExpandedOrderId(0);
  }, [status]);

  return (
    <>
      {!orders?.length && (
        loading ? <Loader /> : <Empty />
      ) || null}
      {orders?.length && orders.map((item) => (
        <OrderCard
          key={`order-${item.id}`}
          order={item}
          expandedOrderId={expandedOrderId}
          setExpandedOrderId={setExpandedOrderId}
          q={q || undefined}
        />
      )) || null}
    </>
  );
};

export default Orders;
