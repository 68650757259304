import styled from 'styled-components';

export const AddButton = styled.div`
  margin: 12px 24px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    color: #0F9FFF;
    border: none;
    cursor: pointer;
    background: transparent;
    margin-bottom: 30px;
    padding: 0;
  }

  .divider {
    width: 100%;
    height: 1px;
    background: rgba(246,251,253,0.28);
  }

  .white {
    color: white;
    margin-bottom: 0;
    margin-top: 30px;
  }
`;

export const NotificationsBtnWrapper = styled.div`
  span {
    content: '';
    height: 32px;
    width: 32px;
    border-radius: 50%;
    transition: all .4s;
    cursor: pointer;
    &:hover {
      background: rgba(0, 120, 210, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
  span.no_notifications_icon {
    color: rgba(250, 250, 250, 0.5);
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      color: rgba(255, 255, 255, 0.75);
    }
  }
  svg {
    height: 16px;
  }
`;
