import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBoxMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateBoxInput;
}>;


export type UpdateBoxMutation = { __typename?: 'Mutation', updateBox: { __typename?: 'BoxType', id: number, number: number, packagesWeight: number } };


export const UpdateBoxDocument = gql`
    mutation UpdateBox($id: Int!, $input: UpdateBoxInput!) {
  updateBox(id: $id, input: $input) {
    id
    number
    packagesWeight
  }
}
    `;
export type UpdateBoxMutationFn = Apollo.MutationFunction<UpdateBoxMutation, UpdateBoxMutationVariables>;

/**
 * __useUpdateBoxMutation__
 *
 * To run a mutation, you first call `useUpdateBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBoxMutation, { data, loading, error }] = useUpdateBoxMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBoxMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBoxMutation, UpdateBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBoxMutation, UpdateBoxMutationVariables>(UpdateBoxDocument, options);
      }
export type UpdateBoxMutationHookResult = ReturnType<typeof useUpdateBoxMutation>;
export type UpdateBoxMutationResult = Apollo.MutationResult<UpdateBoxMutation>;
export type UpdateBoxMutationOptions = Apollo.BaseMutationOptions<UpdateBoxMutation, UpdateBoxMutationVariables>;