
import { FC } from 'react';
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';

const withConsta = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithConsta(props) {
    return (
      <Theme preset={presetGpnDefault}>
        <Component {...props} />
      </Theme>
    );
  };
};
export default withConsta;
