import React, { FC, useState, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@consta/uikit/Button';
import { IconQuestion } from '@consta/uikit/IconQuestion';
import { Tooltip } from '@consta/uikit/Tooltip';
import { Badge } from '@consta/uikit/Badge';
import { IconAlert } from '@consta/uikit/IconAlert';
import { IconCheck } from '@consta/uikit/IconCheck';
import { OrderType, PaymentType, DeliveryStatus } from 'types';
import OrderPackages from '../orderPackages';
import { OrderCardContainer, TooltipContent } from './styled';

interface dataFormProps {
  order: Omit<OrderType, 'user'>,
  expandedOrderId?: number,
  setExpandedOrderId: (id: number | undefined) => void,
  q?: string,
};

const OrderCard: FC<dataFormProps> = ({ order, expandedOrderId, setExpandedOrderId, q }: dataFormProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpenTooltip, setIsOpenTooltip] = useState<boolean>(false);
  const showPackages = useMemo(() => expandedOrderId === order.id, [expandedOrderId, order]);
  const isMobile = window.innerWidth < 640;

  const handleToggleTooltip = () => setIsOpenTooltip(!isOpenTooltip);

  const showPayBadge = useMemo(() => {
    return order.summary.totalCost?.costRub &&
      (order.status === DeliveryStatus.ReadyForDelivery || order.status === DeliveryStatus.Delivered);
  }, [order]);

  const requiresPayment = useMemo(() => {
    return order.paymentType === PaymentType.PaymentGateway &&
      showPayBadge &&
      order.paid === null;
  }, [order, showPayBadge]);

  return (
    <OrderCardContainer>
      <div className='orderCardTitle'>
        <p>Заказ №{order.id}</p>
        <Button
          label='Информация'
          view='ghost'
          form='round'
          size='s'
          iconLeft={IconQuestion}
          onlyIcon
          ref={buttonRef}
          onClick={handleToggleTooltip}
        />
        {isOpenTooltip && (
          <Tooltip
            size={isMobile ? 'm' : 'l'}
            direction={isMobile ? 'downRight' : 'rightDown'}
            anchorRef={buttonRef}
            onClickOutside={handleToggleTooltip}
          >
            <TooltipContent>
              <p>
                <span>Что такое заказ?</span><br/>
                Раз в неделю на складе в США мы складываем ваши посылки в одну большую коробку
                и отправляем в Россию. Посылки, которые находятся в одной коробке называются заказом.
                Если на момент отправки часть ваших посылок не успела доехать до склада в США,
                они будут отправлены другим заказом.<br/>
                Когда заказ приедет в Москву,
                мы отправим в ваш город посылки из этого заказа с помощью почтовой службы.<br/>
                Вы можете перемещать посылки из одного заказа в другой, пока он не доехал до таможни в РФ.
              </p>
              <Button
                label='Понятно'
                view='ghost'
                size='s'
                onClick={handleToggleTooltip}
              />
            </TooltipContent>
          </Tooltip>
        )}
        {showPayBadge && !order.paid && <Badge iconLeft={IconAlert} status='warning' label='Ожидает оплаты' />}
        {showPayBadge && order.paid && <Badge iconLeft={IconCheck} status='success' label='Оплачено' />}
      </div>
      <div className='orderCardRow'>
        <div className='block'>
          <div className='subtitle'>
            Количество посылок в заказе
          </div>
          <div className='content'>
            { order.summary.totalPackages } шт
          </div>
        </div>
        <div className='block'>
          <div className='subtitle'>
            Примерный вес посылок
          </div>
          {order.summary.totalWeight && (
            <div className='content'>
              { order.summary.totalWeight } кг
            </div>
          )} 
        </div>
        <div className='block'>
          {order.summary.totalCost?.costRub && (
            <>
              <div className='subtitle'>
                Примерная стоимость доставки
              </div>
              <div className='content'>
                { [
                    order.summary.totalCost.costUsd ? `${order.summary.totalCost.costUsd}$` : null,
                    order.summary.totalCost.costRub ? `${order.summary.totalCost.costRub}РУБ` : null,
                  ].filter(Boolean).join(' - ') }
              </div>
            </>
          )}
        </div>
        <div className={'block ' + (requiresPayment ? 'buttons' : '')}>
          {requiresPayment && (
            <Link to={`${process.env.REACT_APP_API_URL}/orders/${order.id}/pay`}>
              <Button
                label='Оплатить доставку'
                form='round'
                className='paymentButton'
              />
            </Link>
          )}
          <Button
            label={showPackages ? 'Скрыть посылки' : 'Показать посылки в заказе'}
            form='round'
            onClick={() => setExpandedOrderId(showPackages ? undefined : order.id)}
          />
        </div>
      </div>
      {showPackages && <OrderPackages q={q} orderId={order.id} onClose={() => setExpandedOrderId(undefined)} />}
    </OrderCardContainer>
  );
};

export default OrderCard;
