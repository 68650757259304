import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setAuthToken } from 'shared/api/auth';
import PhoneView from './ui/phoneView';
import CodeView from './ui/codeView';
import { useEnterPhoneMutation } from './api/enterPhone.generated';
import { useEnterCodeMutation } from './api/enterCode.generated';
import { Container, Card } from './styled';

const LoginPage = () => {
  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState('');
  const [enterPhone, {data: dataPhone, error: errorPhone}] = useEnterPhoneMutation();
  const [enterCode, {data: dataCode, error: errorCode}] = useEnterCodeMutation();

  const navigate = useNavigate();

  const sendPhone = async (value: string, captchaCode?: string) => {
    setPhone(value);
    await enterPhone({
      variables: {
        input: {
          phone: value.replace(/[^0-9]/g, ''),
          captchaCode,
        },
      },
    });
  };

  useEffect(() => {
    if (dataPhone?.enterPhone && !errorPhone) {
      setStep(2);
    }
  }, [dataPhone, errorPhone]);

  const sendCode = async (code: string) => {
    await enterCode({
      variables: {
        input: {
          code,
        },
      },
    });
  };

  useEffect(() => {
    if (dataCode?.enterCode?.accessToken && !errorCode) {
      setAuthToken(dataCode?.enterCode?.accessToken, dataCode?.enterCode?.refreshToken, dataCode?.enterCode?.expiresIn);
      navigate('/welcome-form');
    }
  }, [dataCode, errorCode]);

  return (
    <Container>
      <Card>
        {step === 1 && <PhoneView onSend={sendPhone}/>}
        {step === 2 && <CodeView onSend={sendCode} onSendPhone={sendPhone} phone={phone} />}
      </Card>
    </Container>
  );
};

export default LoginPage;
