import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddRecipientMutationVariables = Types.Exact<{
  input: Types.AddRecipientInput;
}>;


export type AddRecipientMutation = { __typename?: 'Mutation', addRecipient: boolean };


export const AddRecipientDocument = gql`
    mutation addRecipient($input: AddRecipientInput!) {
  addRecipient(input: $input)
}
    `;
export type AddRecipientMutationFn = Apollo.MutationFunction<AddRecipientMutation, AddRecipientMutationVariables>;

/**
 * __useAddRecipientMutation__
 *
 * To run a mutation, you first call `useAddRecipientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRecipientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRecipientMutation, { data, loading, error }] = useAddRecipientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRecipientMutation(baseOptions?: Apollo.MutationHookOptions<AddRecipientMutation, AddRecipientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRecipientMutation, AddRecipientMutationVariables>(AddRecipientDocument, options);
      }
export type AddRecipientMutationHookResult = ReturnType<typeof useAddRecipientMutation>;
export type AddRecipientMutationResult = Apollo.MutationResult<AddRecipientMutation>;
export type AddRecipientMutationOptions = Apollo.BaseMutationOptions<AddRecipientMutation, AddRecipientMutationVariables>;