import React, { FC } from 'react';
import dayjs from 'dayjs';
import { Table } from '@consta/uikit/Table';
import { PackageType } from 'types';

interface dataFormProps {
  packages: PackageType[],
};

const TableView: FC<dataFormProps> = ({ packages }: dataFormProps) => {
  const columns = [
    {
      title: 'ТРЕК-НОМЕР',
      accessor: 'track',
      sortable: false,
    },
    {
      title: 'ОПИСАНИЕ ПОСЫЛКИ',
      accessor: 'description',
      sortable: false,
    },
    {
      title: 'ДАТА ДОБАВЛЕНИЯ',
      accessor: 'createdAt',
      sortable: false,
      width: 160,
      align: 'right',
      renderCell: ({ createdAt }: PackageType) => (
        <>{dayjs(createdAt).format('DD.MM.YYYY')}</>
      ),
    },
  ];

  return (
    <>
      {/* @ts-ignore */}
      <Table rows={packages} columns={columns}
        verticalAlign='center'
        borderBetweenColumns
        zebraStriped='odd'
      />
    </>
  );
};

export default TableView;
