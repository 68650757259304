import styled from 'styled-components';

const MobileOnly = styled.div<{breakpoint: number}>`
  display: none;

  @media (max-width: ${({ breakpoint }) => `${breakpoint}px`}) {
    display: block;
  }
`;

export default MobileOnly;