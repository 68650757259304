import { NotifyItem } from './types';

// Class for creating a notification instance
type AddNotification = (notification: NotifyItem) => void
type Register = (addNotification: AddNotification) => void

class StoreNotify {
  public add: AddNotification = (_notification: NotifyItem): void => {
    // return
  };

  // Called when the Notifications component is mounted.
  public register: Register = (addNotification: AddNotification): void => {
    this.add = addNotification;
  };
}

export default new StoreNotify();
