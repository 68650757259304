import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/Button';
import { useUser } from 'entities/user';
import { useUpdateUserMutation } from 'pages/login/api/updateUser.generated';
import Title from 'shared/ui/components/title';
import Subtitle from 'shared/ui/components/subtitle';
import Margin from 'shared/ui/components/margin';
import { emailRegex, ruNameRegex, enNameRegex } from 'shared/constants';
import { UserDataInputContainer } from './styled';

const UserDataView = () => {
  const [name, setName] = useState<string | null>('');
  const [nameError, setNameError] = useState(false);
  const [surname, setSurname] = useState<string | null>('');
  const [surnameError, setSurnameError] = useState(false);
  const [ruName, setRuName] = useState<string | null>('');
  const [ruNameError, setRuNameError] = useState(false);
  const [ruSurname, setRuSurname] = useState<string | null>('');
  const [ruSurnameError, setRuSurnameError] = useState(false);
  const [email, setEmail] = useState<string | null>('');
  const [emailError, setEmailError] = useState(false);

  const navigate = useNavigate();

  const { user, isAuth, loading } = useUser();
  const [updateUser, {data, error}] = useUpdateUserMutation();

  useEffect(() => {
    if (user?.name && user?.email) {
      navigate('/');
    } else if (!isAuth && !loading) {
      navigate('/login');
    }
  }, [user, isAuth, loading]);

  const isDisabled = useMemo(() => nameError ||
    surnameError ||
    ruNameError ||
    ruSurnameError ||
    emailError ||
    !name ||
    !surname ||
    !ruName ||
    !ruSurname ||
    !email, [
      nameError,
      surnameError,
      ruNameError,
      ruSurnameError,
      emailError,
      name,
      surname,
      ruName,
      ruSurname,
      email,
    ]);

  const onSend = async () => {
    await updateUser({
      variables: {
        input: {
          name: `${ruName} ${ruSurname}`,
          nameEn: `${name} ${surname}`,
          email,
        },
      },
    });
  };

  useEffect(() => {
    if (data?.updateUser && !error) {
      navigate('/');
    }
  }, [data, error]);

  const handleNameChange = (value: string | null) => {
    setName(value);
    setNameError(false);
  };

  const handleSurnameChange = (value: string | null) => {
    setSurname(value);
    setSurnameError(false);
  };

  const handleRuNameChange = (value: string | null) => {
    setRuName(value);
    setRuNameError(false);
  };

  const handleRuSurnameChange = (value: string | null) => {
    setRuSurname(value);
    setRuSurnameError(false);
  };

  const handleEmailChange = (value: string | null) => {
    setEmail(value);
    setEmailError(false);
  };

  const handleNameBlur = () => {
    if (!enNameRegex.test(name || '')) {
      setNameError(true);
    }
  };

  const handleSurnameBlur = () => {
    if (!enNameRegex.test(surname || '')) {
      setSurnameError(true);
    }
  };

  const handleRuNameBlur = () => {
    if (!ruNameRegex.test(ruName || '')) {
      setRuNameError(true);
    }
  };

  const handleRuSurnameBlur = () => {
    if (!ruNameRegex.test(ruSurname || '')) {
      setRuSurnameError(true);
    }
  };

  const handleEmailBlur = () => {
    if (!emailRegex.test(email || '')) {
      setEmailError(true);
    }
  };

  return (
    <>
      {user && (
        <>
          <Title>Последний шаг</Title>
          <Margin mb={32} mt={16}>
            <Subtitle>
              Для завершения регистрации заполните информацию о себе.<br />
              Эти данные понадобятся для заказа посылок через наш сервис.
            </Subtitle>
          </Margin>
          <UserDataInputContainer>
            <div className='row'>
              <TextField
                size='l'
                label='Ваше имя латинскими буквами'
                placeholder='Ivan'
                form='round'
                value={name}
                onChange={({ value }) => handleNameChange(value)}
                onBlur={handleNameBlur}
                status={nameError ? 'alert' : undefined}
              />
              <TextField
                size='l'
                label='Ваше имя кириллицей'
                placeholder='Иван'
                form='round'
                value={ruName}
                onChange={({ value }) => handleRuNameChange(value)}
                onBlur={handleRuNameBlur}
                status={ruNameError ? 'alert' : undefined}
              />
            </div>
            <div className='row'>
              <TextField
                size='l'
                label='Ваша фамилия латинскими буквами'
                placeholder='Ivanov'
                form='round'
                value={surname}
                onChange={({ value }) => handleSurnameChange(value)}
                onBlur={handleSurnameBlur}
                status={surnameError ? 'alert' : undefined}
              />
              <TextField
                size='l'
                label='Ваша фамилия кириллицей'
                placeholder='Иванов'
                form='round'
                value={ruSurname}
                onChange={({ value }) => handleRuSurnameChange(value)}
                onBlur={handleRuSurnameBlur}
                status={ruSurnameError ? 'alert' : undefined}
              />
            </div>
            <TextField
              size='l'
              label='Ваш email'
              placeholder='example@site.com'
              form='round'
              value={email}
              onChange={({ value }) => handleEmailChange(value)}
              onBlur={handleEmailBlur}
              status={emailError ? 'alert' : undefined}
            />
          </UserDataInputContainer>
          <Button
            label='Завершить регистрацию'
            form='round'
            disabled={isDisabled}
            onClick={onSend}
          />
        </>
      )}
    </>
  );
};

export default UserDataView;
