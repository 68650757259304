import React, { FC } from 'react';
import { User } from '@consta/uikit/User';
import { useUser } from 'entities/user';

interface dataFormProps{
  withArrow: boolean,
};

const UserBlock: FC<dataFormProps> = ({ withArrow }: dataFormProps) => {
  const { user } = useUser();

  return (
    <User name={user?.name || ''} info={`ID: ${user?.id}`} withArrow={withArrow} />
  );
};

export default UserBlock;