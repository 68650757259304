import React, { FC, useState, ReactElement, useEffect } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconCopy } from '@consta/uikit/IconCopy';
import { useUser } from 'entities/user';
import { useStocksListQuery } from 'pages/packagesAdd/api/stocksList.generated';
import {
  AddressItem,
  AddressesWrapper,
  ChapterDescription,
  ChapterTitle,
  InstructionsList,
  WarningCaution,
} from './ui/styled';

interface IAddressItem {
  title: string
  userInfo: IAddressInfoItem[]
  addressInfo: IAddressInfoItem[]
};

interface IAddressInfoItem {
  label: string
  value: string
};

const InstructionsPage: FC = () => {
  const { user } = useUser();
  const userName = user?.nameEn?.split(' ')[0] ?? '(Ваше имя на английском языке)';
  const userLastName = user?.nameEn?.split(' ')[1] ?? '(Ваша фамилия на английском языке)';

  const [addresses, setAddresses] = useState<IAddressItem[]>([]);

  const { data } = useStocksListQuery();

  useEffect(() => {
    if (data?.stocksList) {
      const arr: IAddressItem[] = data.stocksList.map(item => {
        const userInfo: IAddressInfoItem[] = [
          { label: 'First name', value: userName },
          { label: 'Last name', value: userLastName },
          { label: 'Suite', value: user?.id ? `U${user.id}` : '' },
        ];

        item.address.addressFirst && userInfo.push({ label: 'Adress 1', value: item.address.addressFirst });
        item.address.addressSecond && userInfo.push({ label: 'Adress 2', value: item.address.addressSecond });

        const addressInfo: IAddressInfoItem[] = [];

        item.address.city && addressInfo.push({ label: 'City', value: item.address.city });
        item.address.state && addressInfo.push({ label: 'State', value: item.address.state });
        item.address.zipCode && addressInfo.push({ label: 'Zip code', value: item.address.zipCode });
        item.address.country && addressInfo.push({ label: 'Country', value: item.address.country });
        item.address.phone && addressInfo.push({ label: 'Telephone', value: item.address.phone });

        return {
          title: item.name,
          userInfo,
          addressInfo,
        };
      });

      setAddresses(arr);
    }
  }, [data]);

  const renderAddressInfoItem = ({ label, value }: IAddressInfoItem): ReactElement => {
    const handleCopy = () => navigator.clipboard.writeText(value);

    return (
      <p>
        <span className='label'>{label}</span>
        {value}
        {value && <Button iconLeft={IconCopy} view='clear' size='xs' onClick={handleCopy}/>}
      </p>
    );
  };

  const renderAddressItem = ({ title, userInfo, addressInfo }: IAddressItem): ReactElement => (
    <AddressItem>
      <p>{title}</p>
      <div className='info_wrapper'>
        <div>
          {userInfo.map(renderAddressInfoItem)}
        </div>
        <div>
          {addressInfo.map(renderAddressInfoItem)}
        </div>
      </div>
    </AddressItem>
  );

  return (
    <>
      <WarningCaution>
        После оформления каждого заказа в интернет-магазинах необходимо обязательно заполнить форму в разделе
        “Добавить посылку”. Будьте внимательны при заполнении своих данных. Вносите все в точности как указано в
        инструкции! Из-за некорректно внесенных данных посылки могут не вылететь и срок доставки увеличится!
      </WarningCaution>

      <ChapterTitle>Адрес склада</ChapterTitle>
      <ChapterDescription>
        <p className='address'>Данные, которые необходимо заполнить при оформлении заказа в интернет-магазине</p>
      </ChapterDescription>

      <AddressesWrapper>
        {addresses.map(renderAddressItem)}
      </AddressesWrapper>

      <ChapterTitle>Инструкция</ChapterTitle>
      <ChapterDescription>
        <p>В этой инструкции мы постараемся подробно и доступно рассказать вам о том,
        как начать работу с нашим сервисом и оформить свой самый первый заказ.</p>
      </ChapterDescription>
      <InstructionsList>
        <li>Оформление заказа
          <p>
            Когда вы находите желаемый товар, вам необходимо оформить заказ на том сайте,
            где вы нашли подходящую продукцию.
            При оформлении покупки важно правильно внести свои данные и адрес склада в США.
          </p>
          <p>
            В разделе с адресом вы увидите несколько строк, которые необходимо заполнить:<br/>
            <span>Full name</span> — Фамилия Имя (на английском).<br/>
            <span>Address line 1 и 2</span> — в эти поля нужно ввести номер дома + улицу.<br/>
            Если при заказе в магазине есть только одно поле Address line, то можно указать все в одной строчке.<br/>
            <span>Suite</span> — в это поле нужно ввести ваш личный ID.
            Он указан в карточке с адресом склада и в меню сайта под вашим именем.<br/>
            <span>City</span> — город.<br/>
            State/Province — штат, в котором находится склад.<br/>
            <span>ZIP code</span> — индекс.<br/>
            <span>Phone number</span> — укажите наш номер телефона: +1&nbsp;302&nbsp;345&nbsp;77&nbsp;71.<br/>
            Телефон на некоторых сайтах нужно указывать в формате код страны + номер телефона:
            +1&nbsp;302&nbsp;345&nbsp;77&nbsp;71,
            а в некоторых магазинах нужно вводить без кода страны т. е. просто 302&nbsp;345&nbsp;77&nbsp;71.
          </p>
        </li>

        <li>Добавление посылки в личный кабинет
          <p>
            Когда вы оформляете заказ на сайте, вам присылают трекинг для отслеживания посылки.
            Главное, не спутать трек-номер посылки с номером заказа (order number).
          </p>
          <p>
            После того, как вы оформили заказ в интернет-магазине, необходимо на нашем сайте нажать на кнопку
            «Добавить посылку». Вам откроется окно для заполнения трек-номеров.  Если вы заказали сразу несколько
            товаров, внесите все трек-номера с новой строки и нажмите кнопку «Продолжить».
          </p>
          <p>
            Заполните данные по каждой посылке. Добавьте описание и укажите адрес, где бы вы хотели забрать свои
            посылки. Вы можете выбрать адрес из списка сохраненных ранее адресов или добавить новый, нажав на иконку
            плюса.
            Отметьте галочками те посылки, которые вы хотите застраховать и укажите стоимость товаров в долларах.
          </p>
        </li>

        <li>Формирование заказов
          <p>
            Раз в неделю на складе в США мы собираем все поступившие посылки и отправляем в Россию.
            Посылки, которые попали в одну отправку, собираются в Заказ.
          </p>
          <p>
            Если вы заказали сразу несколько товаров в разных интернет-магазинах, они могут приехать на склад в
            США не одновременно. Так как мы формируем отправку каждую неделю, ваши посылки могут попасть в разные
            Заказы. У вас есть возможность перемещать посылки из одного Заказа в другой. В этом случае, когда первая
            партия ваших посылок приедет в Москву, мы отложим ее до тех пор, пока не приедут все остальные посылки
            из Заказа. И только после этого отправим их вам курьерской службой.
          </p>
        </li>

        <li>Отслеживание посылок
          <p>
            Для отслеживания посылок вы можете пользоваться личным кабинетом. Также, по мере изменения места пребывания
            посылки, вам будут приходить уведомления в личном кабинете. В разделе «Профиль» вы можете подключить
            уведомления в Telegram. Когда вам придет уведомление о том, что Заказ готов к выдаче, вам необходимо будет
            оплатить доставку посылок.
          </p>
        </li>

        <li>Оплата доставки и получение заказа
          <p>
            После того, как Заказ переместится на этап “Готов к выдаче”, у вас появится возможность оплатить доставку
            всех посылок в Заказе. После оплаты мы отправим вам посылки с помощью курьерской службы. Доставка посылок
            курьерской службой до вашего адреса оплачивается отдельно.
          </p>
        </li>
      </InstructionsList>
    </>
  );
};

export default InstructionsPage;
