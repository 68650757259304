import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { VerticalMenu } from '@consta/header/VerticalMenu';
import Margin from 'shared/ui/components/margin';
import MobileOnly from 'shared/ui/components/mobileOnly';
import MobileFullScreenMenu from 'shared/ui/layout/mobileFullScreenMenu';
import { removeAuthToken } from 'shared/api/auth';
import UserBlock from './userBlock';
import { AddButton } from './styled';

interface IProps {
  closeMobileMenu: () => void
}

const Footer: FC<IProps> = ({ closeMobileMenu }: IProps) => {
  const navigate = useNavigate();

  const handlePackageAddClick = (): void => {
    navigate('/packages/add');
    closeMobileMenu();
  };

  const handleLogout = (): void => {
    removeAuthToken();
    closeMobileMenu();
    navigate('/login');
  };

  return (
    <AddButton>
      <button onClick={handlePackageAddClick}>Добавить посылку →</button>
      <span className='divider' />
      <button className='white' onClick={handleLogout}>Выйти</button>
    </AddButton>
  );
};

const MobileMenu: FC<IProps> = ({ closeMobileMenu }: IProps) => {
  const location = useLocation();

  const menu = [
    {
      label: 'Посылки',
      href: '/packages',
      active: location?.pathname === '/packages',
    },
    {
      label: 'Мои адреса',
      href: '/addresses',
      active: location?.pathname === '/addresses',
    },
    {
      label: 'Наши склады / Инструкция',
      href: '/instructions',
      active: location?.pathname === '/instructions',
    },
    {
      label: 'Профиль',
      href: '/profile',
      active: location?.pathname === '/profile',
    },
    {
      label: 'Выкуп',
      href: 'https://usaclubshop.com/buy',
      active: false,
    },
  ];

  return (
    <MobileFullScreenMenu zIndex={2}>
      <>
        <MobileOnly breakpoint={640}>
          <Margin ml={24} mt={12} mb={12}>
            <UserBlock withArrow={false} />
          </Margin>
        </MobileOnly>
        <VerticalMenu items={menu} footer={<Footer closeMobileMenu={closeMobileMenu}/>} />
      </>
    </MobileFullScreenMenu>
  );
};

export default MobileMenu;