import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserShipmentPackagesQueryVariables = Types.Exact<{
  filter: Types.UserShipmentPackagesFilterInput;
  order: Types.UserShipmentPackagesOrderInput;
}>;


export type UserShipmentPackagesQuery = { __typename?: 'Query', userShipmentPackages: Array<{ __typename?: 'UserShipmentPackageType', recipientId: number, recipientName?: string | null, count: number, actualWeight?: number | null, clientWeight?: number | null, insuranceCost?: number | null, deliveryCost?: number | null, totalCost?: { __typename?: 'CostType', costUsd: number, costRub?: number | null } | null }> };


export const UserShipmentPackagesDocument = gql`
    query userShipmentPackages($filter: UserShipmentPackagesFilterInput!, $order: UserShipmentPackagesOrderInput!) {
  userShipmentPackages(filter: $filter, order: $order) {
    recipientId
    recipientName
    count
    actualWeight
    clientWeight
    insuranceCost
    deliveryCost
    totalCost {
      costUsd
      costRub
    }
  }
}
    `;

/**
 * __useUserShipmentPackagesQuery__
 *
 * To run a query within a React component, call `useUserShipmentPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserShipmentPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserShipmentPackagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUserShipmentPackagesQuery(baseOptions: Apollo.QueryHookOptions<UserShipmentPackagesQuery, UserShipmentPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserShipmentPackagesQuery, UserShipmentPackagesQueryVariables>(UserShipmentPackagesDocument, options);
      }
export function useUserShipmentPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserShipmentPackagesQuery, UserShipmentPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserShipmentPackagesQuery, UserShipmentPackagesQueryVariables>(UserShipmentPackagesDocument, options);
        }
export type UserShipmentPackagesQueryHookResult = ReturnType<typeof useUserShipmentPackagesQuery>;
export type UserShipmentPackagesLazyQueryHookResult = ReturnType<typeof useUserShipmentPackagesLazyQuery>;
export type UserShipmentPackagesQueryResult = Apollo.QueryResult<UserShipmentPackagesQuery, UserShipmentPackagesQueryVariables>;