import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #22272B;
`;

export const Title = styled.h1`
  color: rgba(250, 250, 250, 1);
  line-height: 38.4px;
  margin: 0;

  @media (max-width: 1280px) {
    font-size: 24px;
  }

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const SubtitleInstruction = styled.p`
  margin: 20px 0 0 0;
  font-size: 12px;
  line-height: 14.4px;
  p {
    margin: 0;
  }

  @media (max-width: 1280px) {
    margin: 16px 0 0 0;
    max-width: 271px;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1280px) {
    grid-template-columns: unset;
    margin-top: 20px;
  }

  @media (max-width: 640px) {
    margin-top: 16px;
  }
`;

export const TracksContent = styled.div`
  width: 477px;
  textarea {
    min-height: 100px;
    font-size: 18px;
  }
  label {
    margin-bottom: 8px !important;
  }
  button {
    margin-top: 16px;
  }

  @media (max-width: 1280px) {
    order: 1;
    margin-top: 60px;
  }

  @media (max-width: 640px) {
    width: 100%;
    label, textarea {
     font-size: 12px;
    }
    button {
      margin-top: 8px;
      width: 140px;
      font-size: 10px;
      line-height: 24px;
      height: 24px;
    }
  }
`;

export const InfoContent = styled.div`
  margin-top: 20px;
  p {
    color: rgba(250, 250, 250, 1);
    font-size: 14px;
    line-height: 21px;
    margin: 0;
    &:nth-child(2) {
      margin: 20px 0 4px 0;
    }
  }
  a {
    color: rgba(15, 159, 255, 1);
    cursor: pointer;
  }

  @media (max-width: 1280px) {
    margin-top: 0;
    p {
      font-size: 12px;
      line-height: 18px;
      &:nth-child(2) {
        margin: 16px 0 8px 0;
      }
    }
    a {
      font-size: 12px;
    }
  }

  @media (max-width: 640px) {
    p {
      &:nth-child(2) {
        margin: 12px 0 8px 0;
      }
    }
  }
`;

export const InfoContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  p, a {
    color: rgba(250, 250, 250, 1);
    font-size: 12px;
    line-height: 14.4px;
  }
  a {
    color: rgba(15, 159, 255, 1);
    cursor: pointer;
  }

  @media (max-width: 640px) {
    grid-template-columns: unset;
    p {
      line-height: 18px;
    }
  }
`;

export const InsuranceInfo = styled.div`
  text-align: end;
  max-width: 355px;
  @media (max-width: 1280px) {
    max-width: 215px;
    margin-top: 8px;
    p, a {
      font-size: 10px;
      line-height: 12px;
    }
  }

  @media (max-width: 640px) {
    margin-top: 0px;
    text-align: start;
    max-width: 100%;
    p, a {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export const ParcelsTable = styled.table`
  margin-top: 40px;
  border-collapse: collapse;
  width: 100%;
  min-width: 1200px;
`;

export const TableWrapper = styled.div`
  @media (max-width: 1280px) {
    max-width: calc(100vw - 40px);
    overflow-x: scroll;
  }
`;

export const HeaderRow = styled.tr`
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  th {
    text-transform: uppercase;
    padding: 12px;
    font-size: 12px;
    line-height: 16.8px;
    color: rgba(250, 250, 250, 1);
    text-align: start;
  }

  @media (max-width: 1280px) {
    th {
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

export const TrackHeader = styled.th`
  width: 12.5%;
`;

export const DescriptionHeader = styled.th`
  width: 15.8%;
`;

export const AddressHeader = styled.th`
  width: 24.2%;
`;

export const StockHeader = styled.th`
  width: 22%;
`;

export const InsuranceHeader = styled.th`
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 8px 12px !important;
`;

export const DeleteHeader = styled.th`
  width: 48px;
`;

export const ParcelRow = styled.tr`
  td {
    padding: 4px 12px;
    vertical-align: top;
    height: 52px;
    a {
      color: rgba(15, 159, 255, 1);
      cursor: pointer;
      font-size: 12px;
      line-height: 14.4px;
    }
  }

  @media (max-width: 1280px) {
    td {
      input {
        font-size: 12px;
      }
      a {
        font-size: 10px;
      }
    }
  }
`;

export const DFlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SettingsWrapper = styled.div`
  .save_button {
    margin-top: 20px;
    width: 270px;
  }

  @media (max-width: 1280px) {
    .save_button {
      margin-top: 16px;
    }
  }

  @media (max-width: 640px) {
    .save_button {
      width: 140px;
      font-size: 10px;
      line-height: 24px;
      height: 24px;
    }
  }
`;

export const InsuredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 30px;

  @media (max-width: 640px) {
    justify-content: start;
    .cost_input {
      width: 128px;
    }
  }
`;

export const CardsWrapper = styled.div`
  margin-top: 40px;
  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 302px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    p {
      margin: 0;
      color: rgba(250, 250, 250, 1);
      font-size: 10px;
    }
    .track {
      margin-top: 8px;
    }
    .label_wrapper {
      margin: 20px 0 0 0;
      display: flex;
      align-items: center;
    }
    .label {
      color: rgba(250, 250, 250, 1);
      font-weight: 700;
      font-size: 10px;
      line-height: 18px;
      text-transform: uppercase;
      margin-right: 16px;
    }
    a {
      color: rgba(15, 159, 255, 1);
      cursor: pointer;
      font-size: 10px;
      &.delete_link {
        color: rgba(247, 59, 59, 1);
        position: absolute;
        bottom: 12px;
        right: 12px;
      }
    }

    @media (max-width: 640px) {
      input {
        font-size: 10px;
      }
    }
  }
`;

export const CustomModal = styled.div`
  padding: 20px;
  p {
    color: rgba(250, 250, 250, 1);
  }
`;
