import React, { FC, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@consta/uikit/Button';
import { TextField, TextFieldOnChangeArguments } from '@consta/uikit/TextField';
import { Title, ContentWrapper, InfoContent, TracksContent } from './styled';

interface IProps {
  onContinue: (value: string) => void,
}

const TracksInputView: FC<IProps> = ({ onContinue }: IProps) => {
  const navigate = useNavigate();
  const [value, setValue] = useState<string | null>(null);

  const handleChange = ({ value: valueInut, e } : TextFieldOnChangeArguments) => {
    if ('inputType' in e.nativeEvent && e.nativeEvent.inputType === 'insertLineBreak') {
      const formattedInputValue = valueInut
        ?.split('\n')
        .map(str => str.trim())
        .filter(str => str !== '' ) ?? [];
      formattedInputValue.push('');
      setValue(formattedInputValue.join('\n'));
    } else  setValue(valueInut);
  };

  const handleContinue = () => {
    if (value) onContinue(value);
  };

  const renderFunctionalContent = (): ReactElement => (
    <TracksContent>
      <TextField
        onChange={handleChange}
        value={value}
        type='textarea'
        width='full'
        label='Введите один или несколько трек-номеров через Enter'
        size='s'
      />
      <Button
        label='Продолжить'
        form='round'
        size='m'
        width='full'
        disabled={!value?.length}
        onClick={handleContinue}
      />
    </TracksContent>
  );

  const renderInfoContent = (): ReactElement => (
    <InfoContent>
      <p>
        Вы можете добавить одну или сразу несколько посылок.<br/>
        Но есть ограничение: за один раз не получится добавить больше ста посылок.
      </p>
      <p>
        Если у вас возникли трудности, вы можете ознакомиться с инструкцией<br/>
        по добавлению посылок.
      </p>
      <a onClick={() => navigate('/instructions')}>Инструкция по добавлению посылок</a>
    </InfoContent>
  );

  return (
    <>
      <Title>Добавление посылок</Title>
      <ContentWrapper>
        {renderFunctionalContent()}
        {renderInfoContent()}
      </ContentWrapper>
    </>
  );
};

export default TracksInputView;
