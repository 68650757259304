import { Layout } from '@consta/header/Layout';
import styled from 'styled-components';

export const CustomHeader = styled(Layout)`
  & > div {
    padding: 0 40px;

    @media (max-width: 1280px) {
      padding: 0 20px;
    }
  }
`;