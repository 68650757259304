import React, { FC, useState, useEffect, ReactElement, MouseEventHandler, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import prettyNum from 'prettify-numbers';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Checkbox } from '@consta/uikit/Checkbox';
import { IconAdd } from '@consta/uikit/IconAdd';
import { IconTrash } from '@consta/uikit/IconTrash';
import { TextField, TextFieldOnChangeArguments } from '@consta/uikit/TextField';
import { Select } from '@consta/uikit/Select';
import { showSuccessNotification } from 'widgets/notifications/api/showSuccessNotification';
import { useAddresses } from 'entities/addresses';
import { Maybe } from 'types';
import AddressAddModal from 'pages/addresses/ui/addressAddModal';
import Margin from 'shared/ui/components/margin';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import { useCreatePackageMutation } from '../api/createPackage.generated';
import { useStocksListQuery } from '../api/stocksList.generated';
import {
  CardsWrapper,
  Title,
  InfoContentWrapper,
  InsuranceInfo,
  HeaderRow,
  TrackHeader,
  DescriptionHeader,
  AddressHeader,
  StockHeader,
  InsuranceHeader,
  DeleteHeader,
  ParcelRow,
  DFlexContainer,
  ParcelsTable,
  SettingsWrapper,
  SubtitleInstruction,
  TableWrapper,
  InsuredContainer,
} from './styled';

interface IProps {
  tracks: string[],
  setStep: (step: number) => void
}

export type Package = {
  address?: AddressItem
  addressId: Maybe<number>
  stock?: AddressItem
  stockId: Maybe<number>
  description?: string
  insured?: boolean
  packageCost?: number | string
  track: string
}

type AddressItem = {
  label: string
  id: Maybe<number>
}

const TracksSettingsView: FC<IProps> = ({ tracks, setStep }: IProps) => {
  const navigate = useNavigate();
  const { listAddresses } = useAddresses();

  const [createPackage] = useCreatePackageMutation();

  const [allChecked, setAllChecked] = useState(false);
  const [addressesItems, setAddressesItems] = useState<AddressItem[]>([]);
  const [stocksItems, setStocksItems] = useState<AddressItem[]>([]);
  const [currentInputIndex, setCurrentInputIndex] = useState<Maybe<number>>(null);
  const [currentInput, setCurrentInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [modalPackageId, setModalPackageId] = useState<Maybe<number>>(null);
  const [packages, setPackages] = useState<Package[]>([]);

  useEffect(() => {
    const initialPackages = tracks.map(track => ({
      track,
      addressId: null,
      stockId: null,
      insured: false,
    }));
    setPackages(initialPackages);
  }, [tracks]);

  const { data: stocks } = useStocksListQuery();

  useEffect(() => {
    if (stocks?.stocksList) {
      setStocksItems(stocks.stocksList.map(item => ({
        id: item.id,
        label: item.name,
      })));
    }
  }, [stocks]);

  useEffect(() => {
    if (listAddresses) {
      const pickup: AddressItem = {
        id: null,
        label: 'Самовывоз',
      };
      const newAddressesItems: AddressItem[] = listAddresses.map(({ id, address }) => ({
        id,
        label: address,
      }));
    setAddressesItems([pickup, ...newAddressesItems]);
    }
  }, [listAddresses]);

  const sendPackage = async () => {
    const promises = packages.map(({ track, insured, description, packageCost, address, stock }) =>
      createPackage({
        variables: {
          input: {
            track,
            insured,
            description,
            packageCost: packageCost ? +packageCost : undefined,
            addressId: address?.label === 'Cамовывоз' ? null : address?.id as number,
            stockId: stock?.id as number,
          },
        },
      }),
    );

    await Promise.all(promises).then(() => {
      showSuccessNotification('Посылка успешно добавлена');
      navigate('/packages');
    });
  };

  const renderTrack = (value: Package, isCard = false) => (
    isCard
      ? <p className='track'><span className='label'>трек-номер</span>{value.track}</p>
      : <TextField
          value={value.track}
          disabled={true}
          type='text'
          width='full'
          size='s'
        />
  );

  const renderDescription = (value: Package, idx: number, isCard = false) => {
    const isApplyToAllShow = !!value.description?.length;
    const onApplyToAllClick = () => handleAllPackagesDescriptionsChange(idx);
    const applyToAllLabel = renderApplyToAllLabel(isApplyToAllShow, onApplyToAllClick);

    return (
      <>
        {isCard && renderCardLabel('описание посылки', idx, applyToAllLabel)}
        <TextField
          value={value.description}
          type='text'
          width='full'
          size={isCard ? 'xs' : 's'}
          onChange={(e) => handlePackageDescriptionChange(idx, e.value?.toString())}
        />
        {!isCard && applyToAllLabel}
      </>
    );
  };

  const handlePackageDescriptionChange = (idx: number, value?: string) => {
    setPackages((prevPackages) => {
      const newPackages = [...prevPackages];
      if (newPackages[idx]) newPackages[idx].description = value;

      return newPackages;
    });
  };

  const handleAllPackagesDescriptionsChange = (idx: number) => {
    setPackages((prevPackages) =>
      [...prevPackages].map(pckg => ({...pckg, description: packages[idx].description})));
  };

  const renderAddress = (value: Package, idx: number, isCard = false) => {
    const isApplyToAllShow = !!value.address;
    const onApplyToAllClick = () => handleAllPackagesAddressesChange(idx);
    const applyToAllLabel = renderApplyToAllLabel(isApplyToAllShow, onApplyToAllClick);

    return (
      <>
        {isCard && renderCardLabel('адрес получения', idx, applyToAllLabel)}
        <DFlexContainer>
          <Select
            items={addressesItems}
            getItemKey={(item) => item.id ?? 'pickup'}
            value={value.address}
            placeholder='Выберите вариант'
            size={isCard ? 'xs' : 's'}
            status={isTouched && !value.address ? 'alert' : undefined}
            onChange={(e) => handlePackageAddressChange(idx, e.value)}
          />
          <Margin ml={6}>
            <Button
              size={isCard ? 'xs' : 's'}
              width='full'
              iconLeft={IconAdd}
              onlyIcon
              onClick={() => handleOpenModal(idx)}
            />
          </Margin>
        </DFlexContainer>
        {!isCard && applyToAllLabel}
      </>
    );
  };

  const renderStocks = (value: Package, idx: number, isCard = false) => {
    const isApplyToAllShow = !!value.stock;
    const onApplyToAllClick = () => handleAllPackagesStockChange(idx);
    const applyToAllLabel = renderApplyToAllLabel(isApplyToAllShow, onApplyToAllClick);

    return (
      <>
        {isCard && renderCardLabel('склад отправления', idx, applyToAllLabel)}
        <DFlexContainer>
          <Select
            items={stocksItems}
            getItemKey={(item) => item.id}
            value={value.stock}
            placeholder='Выберите вариант'
            size={isCard ? 'xs' : 's'}
            status={isTouched && !value.stock ? 'alert' : undefined}
            onChange={(e) => handlePackageStockChange(idx, e.value)}
          />
        </DFlexContainer>
        {!isCard && applyToAllLabel}
      </>
    );
  };

  const handlePackageAddressChange = (idx: number, value: Maybe<AddressItem>) => {
    setPackages((prevPackages) => {
      const newPackages = [...prevPackages];
      if (newPackages[idx]) newPackages[idx].address = value ?? undefined;

      return newPackages;
    });
  };

  const handleAllPackagesAddressesChange = (idx: number) => {
    setPackages((prevPackages) =>
      [...prevPackages].map(pckg => ({...pckg, address: packages[idx].address})));
  };

  const handlePackageStockChange = (idx: number, value: Maybe<AddressItem>) => {
    setPackages((prevPackages) => {
      const newPackages = [...prevPackages];
      if (newPackages[idx]) newPackages[idx].stock = value ?? undefined;

      return newPackages;
    });
  };

  const handleAllPackagesStockChange = (idx: number) => {
    setPackages((prevPackages) =>
      [...prevPackages].map(pckg => ({...pckg, stock: packages[idx].stock})));
  };

  const handleOpenModal = (idx: number) => {
    setIsModalOpen(true);
    setModalPackageId(idx);
  };

  const renderInsurance = (value: Package, idx: number, isCard = false) => {
    const costValue = currentInputIndex === idx ? currentInput : printFormattedValue(value.packageCost);

    return (
      <>
        {isCard && renderCardLabel('застраховать посылку', idx)}
        <InsuredContainer>
          {value.insured && (
            <TextField
              value={costValue}
              type='text'
              width='full'
              size={isCard ? 'xs' : 's'}
              rightSide='$'
              className='cost_input'
              placeholder='Стоимость посылки'
              onBlur={() => handlePackageCostChange(idx)}
              onFocus={() => handlePackageCostOnFocus(value, idx)}
              onChange={(e) => handleChangeCost(e, idx)}
            />
          )}
          {!isCard && <Margin ml={8}>
            <Checkbox
              size='m'
              checked={value.insured}
              onChange={(e) => handlePackageInsuranceChange(e.checked, idx)}
            />
          </Margin>}
        </InsuredContainer>
      </>
  );
};
  const handlePackageCostChange = (idx: number) => {
    setPackages((prevPackages) => {
      const newPackages = [...prevPackages];
      if (newPackages[idx]) newPackages[idx].packageCost = currentInput;

      return newPackages;
    });

    setCurrentInput('');
    setCurrentInputIndex(null);
  };

  const handlePackageInsuranceChange = (checked: boolean, idx: number) => {
    setPackages((prevPackages) => {
      const newPackages = [...prevPackages];
      if (newPackages[idx]) newPackages[idx].insured = checked;
      return newPackages;
    });

    if (allChecked && !checked) setAllChecked(false);
    if (!allChecked && checked && !packages.find((pckg, i) => !pckg.insured && i !== idx)) setAllChecked(true);
  };

  const handlePackageCostOnFocus = (value: Package, idx: number) => {
    setCurrentInput(value.packageCost?.toString() ?? '');
    setCurrentInputIndex(idx);
  };

  const handleChangeCost = ({ value }: TextFieldOnChangeArguments, idx: number): void => {
    const numberOfDecimalPlaces = 2;

    const formattedValue = value ? Math.abs(+value) : value;
    const currencyResult = formattedValue?.toString()?.replace(/[ ]/gi, '').replace(/[.]/gi, ',') ?? '';
    const currencyReg = getRegExp(numberOfDecimalPlaces);

    if (currencyResult !== '' && !currencyResult?.match(currencyReg)) return;

    setCurrentInput(currencyResult);
    if (currentInputIndex !== idx) setCurrentInputIndex(idx);
  };

  const getRegExp = (numberOfDecimalPlaces: number): RegExp => {
    let re = '^-?([0-9]+)?';
    if (numberOfDecimalPlaces !== 0) {
      re += `([.,])?([0-9]{1,${numberOfDecimalPlaces}})?`;
    }

    return new RegExp(`${re}$`);
  };

  const printFormattedValue = (value?: number | string): string | undefined => {
    if (!value) return undefined;

    return prettyNum(value, ' ', ',');
  };

  const renderDelete = (idx: number, isCard = false) => (
    isCard
      ? <a className='delete_link' onClick={() => handleDeletePackage(idx)}>Удалить</a>
      : <Button
          view='ghost'
          size='s'
          width='full'
          iconLeft={IconTrash}
          onlyIcon
          onClick={() => handleDeletePackage(idx)}
        />

  );

  const handleDeletePackage = (idx: number) => {
    if (packages.length === 1) setStep(1);
    else setPackages(prevPackages => [...prevPackages].filter((_pckg, pckgIdx) => pckgIdx !== idx));
  };

  const renderApplyToAllLabel = (isShow: boolean, onClick: MouseEventHandler<HTMLAnchorElement>): ReactNode => (
    isShow &&
      <a onClick={onClick}>
        Применить ко всем посылкам
      </a>
  );

  const renderTable = () => {
    return (
      <TableWrapper>
        <ParcelsTable>
          <thead>
            <HeaderRow>
              <TrackHeader>трек-номер</TrackHeader>
              <DescriptionHeader>oписание посылки</DescriptionHeader>
              <AddressHeader>адрес получения</AddressHeader>
              <StockHeader>Склад отправления</StockHeader>
              <InsuranceHeader>
                застраховать
                <Margin ml={12}>
                  <Checkbox
                    size='l'
                    checked={allChecked}
                    onChange={(e) => handleAllCheck(e.checked)}
                  />
                </Margin>
              </InsuranceHeader>
              <DeleteHeader></DeleteHeader>
            </HeaderRow>
          </thead>
          <tbody>
            {packages.map(renderTableRow)}
          </tbody>
        </ParcelsTable>
      </TableWrapper>
    );
  };

  const renderTableRow = (value: Package, idx: number) => {
    return (
      <ParcelRow key={idx}>
        <td>{renderTrack(value)}</td>
        <td>{renderDescription(value, idx)}</td>
        <td>{renderAddress(value, idx)}</td>
        <td>{renderStocks(value, idx)}</td>
        <td>{renderInsurance(value, idx)}</td>
        <td>{renderDelete(idx)}</td>
      </ParcelRow>
    );
  };

  const renderCards = () => {
    return (
      <CardsWrapper>
        {packages.map(renderCard)}
      </CardsWrapper>
    );
  };

  const renderCard = (value: Package, idx: number) => {
    return (
      <Card key={idx} verticalSpace='s' horizontalSpace='s' form='square' className='card'>
        {renderTrack(value, true)}
        {renderDescription(value, idx, true)}
        {renderAddress(value, idx, true)}
        {renderStocks(value, idx, true)}
        {renderInsurance(value, idx, true)}
        {renderDelete(idx, true)}
      </Card>
    );
  };

  const renderCardLabel = (label: string, idx: number, applyToAllLabel?: ReactNode) => (
    <div className='label_wrapper'>
      <span className='label'>{label}</span>
      {applyToAllLabel ?? (
        <Checkbox
          size='m'
          checked={packages[idx].insured}
          onChange={(e) => handlePackageInsuranceChange(e.checked, idx)}
        />
      )}
    </div>
  );

  const handleAllCheck = (checked: boolean) => {
    setAllChecked(checked);
    setPackages((prevPackages) => [...prevPackages].map((pckg) => ({...pckg, insured: checked})));
  };

  const renderInsuranceInfo = (): ReactElement => (
    <InsuranceInfo>
      <p>
        Вы можете застраховать ваши посылки. Для этого нажмите
        на галочку рядом с теми посылками которые вы хотите
        застраховать и введите стоимость посылки в долларах.
      </p>
      <Margin mt={8}>
        <a>Условия страхования</a>
      </Margin>
    </InsuranceInfo>
  );

  const renderSaveButton = (): ReactElement => (
    <Button
      className='save_button'
      label='Сохранить'
      form='round'
      size='m'
      onClick={handleSave}
    />
  );

  const handleSave = () => {
    if (!packages.find(pckg => !pckg.address || !pckg.track || !pckg.stock)) sendPackage();
    else if (!isTouched) setIsTouched(true);
  };

  const handleCloseModal = () => setIsModalOpen(false);

  const addAddressCallback = (id: number, inputValue: string) => {
    setPackages((prevPackages) => {
      return [...prevPackages].map((pckg, idx) => (idx === modalPackageId
        ? {...pckg, address: { id, label: inputValue }}
        : {...pckg}
      ));
    });
  };

  return (
    <SettingsWrapper>
      <InfoContentWrapper>
        <div>
          <Title>Добавление посылок</Title>
          <SubtitleInstruction>
            <p>Заполните информацию о каждой посылке: описание и адрес получения посылки в России.</p>
          </SubtitleInstruction>
        </div>
        {renderInsuranceInfo()}
      </InfoContentWrapper>
      <DesktopOnly breakpoint={640}>
        {renderTable()}
      </DesktopOnly>
      <MobileOnly breakpoint={640}>
        {renderCards()}
      </MobileOnly>
      {renderSaveButton()}

      <AddressAddModal
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        createAddressCallback={addAddressCallback}
      />
    </SettingsWrapper>
  );
};

export default TracksSettingsView;
