import React, { FC, useState, useRef, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@consta/uikit/Button';
import { IconHamburger } from '@consta/uikit/IconHamburger';
import { IconRing } from '@consta/uikit/IconRing';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import { Popover } from '@consta/uikit/Popover';
import NotificationsList from 'widgets/notificationsList';
import { useNotifications } from 'entities/notifications';
import { NotificationType } from 'types';
import Margin from 'shared/ui/components/margin';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileFullScreenMenu from 'shared/ui/layout/mobileFullScreenMenu';
import MobileOnly from 'shared/ui/components/mobileOnly';
import { removeAuthToken } from 'shared/api/auth';
import MobileMenu from './ui/mobileMenu';
import UserBlock from './ui/userBlock';
import { NotificationsBtnWrapper } from './ui/styled';

interface ContextMenuItem {
  title: string,
  callback?: () => void,
};

const HeaderUser: FC = () => {
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { listNotifications } = useNotifications();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileNotifications, setShowMobileNotifications] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleClickOnAnchor = () => setIsPopoverVisible(!isPopoverVisible);

  const ref = useRef(null);

  const handleLogout = () => {
    removeAuthToken();
    setShowUserMenu(false);
    navigate('/login');
  };

  const goToProfile = () => {
    setShowUserMenu(false);
    navigate('/profile');
  };

  const menu: ContextMenuItem[] = [
    {
      title: 'Профиль',
      callback: goToProfile,
    },
    {
      title: 'Выйти',
      callback: handleLogout,
    },
  ];

  const renderNotifyBtn = (isMobile = false): ReactElement => {
    const btnClass = listNotifications?.filter((notify: NotificationType) => !notify.read).length
      ? undefined
      : 'no_notifications_icon';

    return (
      <NotificationsBtnWrapper>
        <IconRing
          view='link'
          size='m'
          ref={!isMobile ? anchorRef : undefined}
          onClick={isMobile ? toggleMobileNotifications : handleClickOnAnchor}
          className={btnClass}
        />
      </NotificationsBtnWrapper>
    );
  };

  const toggleMobileNotifications = () => {
    setShowMobileNotifications(!showMobileNotifications);
    setShowMobileMenu(false);
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setShowMobileNotifications(false);
  };

  return (
    <>
      <DesktopOnly breakpoint={1280}>
        <Button
          label='Добавить посылку'
          form='round'
          size='s'
          onClick={() => navigate('/packages/add')}
        />
      </DesktopOnly>
      <Margin mr={16} ml={16}>
        <DesktopOnly breakpoint={1280}>
          {renderNotifyBtn()}
          {isPopoverVisible && (
            <Popover
              direction='downCenter'
              spareDirection='downStartLeft'
              offset='m'
              arrowOffset={0}
              onClickOutside={()=> setIsPopoverVisible(false)}
              isInteractive={true}
              anchorRef={anchorRef}
              equalAnchorWidth={false}
            >
              <NotificationsList/>
            </Popover>
          )}
        </DesktopOnly>
        <MobileOnly breakpoint={1280}>
          {renderNotifyBtn(true)}
        </MobileOnly>
      </Margin>
      <DesktopOnly breakpoint={640}>
        <div ref={ref} onClick={() => setShowUserMenu(!showUserMenu)}>
          <UserBlock withArrow={true} />
        </div>
      </DesktopOnly>
      <ContextMenu
        isOpen={showUserMenu}
        items={menu}
        getItemLabel={(item) => item.title} anchorRef={ref}
        getItemOnClick={(item) => item.callback}
        direction='downCenter'
      />
      <MobileOnly breakpoint={1280}>
        <Margin ml={16}>
          <Button
            label='Меню'
            view='clear'
            iconLeft={IconHamburger}
            onlyIcon
            size='s'
            onClick={toggleMobileMenu}
          />
        </Margin>
      </MobileOnly>
      {showMobileMenu && <MobileMenu closeMobileMenu={() => setShowMobileMenu(false)}/>}
      {showMobileNotifications && (
        <MobileFullScreenMenu zIndex={2}>
          <NotificationsList/>
        </MobileFullScreenMenu>
      )}
    </>
  );
};

export default HeaderUser;