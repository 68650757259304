import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/Button';
import { Container, Card } from 'pages/login/styled';
import { UserDataInputContainer } from 'pages/welcomeForm/ui/styled';
import Title from 'shared/ui/components/title';
import Subtitle from 'shared/ui/components/subtitle';
import Margin from 'shared/ui/components/margin';
import { setAuthToken } from 'shared/api/auth';
import { useAdminLoginMutation } from './api/adminLogin.generated';

const LoginPage = () => {
  const [login, setLogin] = useState<string | null>('');
  const [loginError, setLoginError] = useState(false);
  const [password, setPassword] = useState<string | null>('');
  const [passwordError, setPasswordError] = useState(false);
  const [adminLogin, { data, error }] = useAdminLoginMutation();

  const navigate = useNavigate();

  const loginFunc = async () => {
    if (login && password) {
      await adminLogin({
        variables: {
          input: {
            login,
            password,
          },
        },
      });
    }
  };

  const handleLoginChange = (value: string | null) => {
    setLogin(value);
    setLoginError(false);
  };

  const handlePasswordChange = (value: string | null) => {
    setPassword(value);
    setPasswordError(false);
  };

  const handleLoginBlur = () => {
    if (!login) {
      setLoginError(true);
    }
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordError(true);
    }
  };

  useEffect(() => {
    if (data?.adminLogin?.accessToken && !error) {
      setAuthToken(data?.adminLogin?.accessToken, data?.adminLogin?.refreshToken, data?.adminLogin?.expiresIn);
      navigate('/');
    }
  }, [data, error]);

  return (
    <Container>
      <Card>
          <Title>Вход в административную панель UCS</Title>
          <Margin mb={32} mt={16}>
            <Subtitle>
              Введите логин и пароль
            </Subtitle>
          </Margin>
          <UserDataInputContainer>
            <TextField
              size='l'
              label='Логин'
              placeholder='Логин'
              form='round'
              value={login}
              onChange={({ value }) => handleLoginChange(value)}
              onBlur={handleLoginBlur}
              status={loginError ? 'alert' : undefined}
              style={{ maxWidth: 'unset' }}
            />
            <TextField
              size='l'
              label='Пароль'
              placeholder='Пароль'
              form='round'
              type='password'
              value={password}
              onChange={({ value }) => handlePasswordChange(value)}
              onBlur={handlePasswordBlur}
              status={passwordError ? 'alert' : undefined}
              style={{ maxWidth: 'unset' }}
            />
            <Button
              label='Войти'
              form='round'
              width='full'
              disabled={!login || !password}
              onClick={loginFunc}
            />
          </UserDataInputContainer>
      </Card>
    </Container>
  );
};

export default LoginPage;
