import React, { FC, useMemo } from 'react';
import { DeliveryStatus } from 'types';
import { menu } from '../../../packages/index';
import { HistoryBlock } from './styled';

interface dataFormProps {
  status: DeliveryStatus | null | undefined,
}

export const statusesQueue: DeliveryStatus[] = [
  DeliveryStatus.UsaStock,
  DeliveryStatus.Sent,
  DeliveryStatus.Customs,
  DeliveryStatus.RuStok,
  DeliveryStatus.ReadyForDelivery,
  DeliveryStatus.Delivered,
];

const History: FC<dataFormProps> = ({ status }: dataFormProps) => {
  const historyArr = useMemo(() => {
    const result = ['Посылка добавлена'];
    if (status) {
      const idx = statusesQueue.findIndex(item => item === status);
      
      if (idx !== -1) {
        return [...result, ...statusesQueue.slice(0, idx + 1).map(item => menu[item])];
      }
    }
    return result;
  }, [status]);

  return (
    <HistoryBlock>
      {historyArr.length && (
        <>
          <div className='title'>
            История передвижения
          </div>
          <div className='history'>
            {historyArr.map((item, i) => (
              <div key={item + i} className='historyItem'>{item}</div>
            ))}
          </div>
        </>
      )}
    </HistoryBlock>
  );
};

export default History;
