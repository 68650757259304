import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 40px 100px;
  position: relative;

  .title {
    color: #FAFAFA;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 24px;
  }

  .buttons {
    display: flex;

    .deleteButton {
      margin-right: 24px;
      background: transparent !important;
      border: 1px solid #FF4443 !important;
      color: #FF4443 !important;
    }
  }
`;