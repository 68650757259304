import React, { FC } from 'react';
import readXlsxFile from 'read-excel-file';
import { FileField } from '@consta/uikit/FileField';
import { Button } from '@consta/uikit/Button';
import { IconDownload } from '@consta/icons/IconDownload';
import { CreateBoxInput } from 'types';
import { showWarningNotification } from 'widgets/notifications/api/showWarningNotification';
import { useImportBoxesMutation } from '../../api/importBoxes.generated';

const ExcelImport: FC<{ shipmentId: number, onImport: () => Promise<any>}> = ({ shipmentId, onImport }) => {
  const [importBoxes] = useImportBoxesMutation();

  const parseExcel = async (e: any) => {
    const rows = await readXlsxFile(e?.target?.files[0]);

    const boxes: CreateBoxInput[] = [];

    for (const item of rows) {
      const boxId = parseInt(String(item[0]).replace(/[^0-9]/g, ''));
      const track = `${item[1]}`.trim();

      if (!track) {
        continue;
      }

      const approximateWeight = parseFloat(item[2].toString().replace(',', '.'));
      if (isNaN(approximateWeight)) {
        showWarningNotification(`Неверный вес у посылки ${track}`);
        continue;
      }

      if (!isNaN(boxId)) {
        let weight = parseFloat(item[7]?.toString().replace(',', '.'));
        if (isNaN(weight)) {
          showWarningNotification(`Неверный вес у коробки ${item[0]}`);
          weight = 0;
        }

        boxes.push({
          number: boxId,
          weight,
          packages: [{ track, approximateWeight }],
        });
      } else if (boxes.length) {
        boxes[boxes.length - 1].packages.push({ track, approximateWeight });
      }
    }

    await importBoxes({
      variables: {
        shipmentId,
        boxes,
      },
    });

    await onImport();
  };
  return (
    <FileField
      id='FileFieldButton'
      onChange={e => parseExcel(e)}
    >
      {(props) => <Button
          {...props}
          label='Импорт'
          view='clear'
          iconLeft={IconDownload}
        />
      }
    </FileField>
  );
};

export default ExcelImport;