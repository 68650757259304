import { UserType } from 'types';

declare const window: Window & { jivo_api: any };

export const openChat = () => {
  window.jivo_api?.open();
};

export const setChatContact = (data: UserType) => {
  window.jivo_api?.setContactInfo({
    name: data.name,
    email: data.email,
    phone: data.phone,
  });
};

export const closeChat = () => {
  window.jivo_api?.clearHistory();
  window.jivo_api?.close();
};
