import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: rgb(255, 255, 255);
  font-weight: 400;
  h1 {
    margin: 24px 0 0 0;
    font-size: 24px;
    line-height: 36px;
  }
  h2 {
    margin: 8px 0 24px 0;
    font-size: 14px;
    line-height: 21px;
    max-width: 325px;
    text-align: center;
  }

  @media (max-width: 1280px) {
    img {
      width: 300px;
    }
    h2 {
      line-height: 16.8px;
    }
    button {
      height: 24px;
      line-height: 24px;
    }
  }

  @media (max-width: 640px) {
    img {
      width: 280px;
    }
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 12px;
      line-height: 15.6px;
    }
    button {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }
  }
`;
