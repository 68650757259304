import styled from 'styled-components';

const Subtitle = styled.p`
  color: #002033;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;
`;

export default Subtitle;