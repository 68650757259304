import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportBoxesMutationVariables = Types.Exact<{
  shipmentId: Types.Scalars['Int']['input'];
  boxes: Array<Types.CreateBoxInput> | Types.CreateBoxInput;
}>;


export type ImportBoxesMutation = { __typename?: 'Mutation', importBoxes: boolean };


export const ImportBoxesDocument = gql`
    mutation importBoxes($shipmentId: Int!, $boxes: [CreateBoxInput!]!) {
  importBoxes(shipmentId: $shipmentId, boxes: $boxes)
}
    `;
export type ImportBoxesMutationFn = Apollo.MutationFunction<ImportBoxesMutation, ImportBoxesMutationVariables>;

/**
 * __useImportBoxesMutation__
 *
 * To run a mutation, you first call `useImportBoxesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportBoxesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importBoxesMutation, { data, loading, error }] = useImportBoxesMutation({
 *   variables: {
 *      shipmentId: // value for 'shipmentId'
 *      boxes: // value for 'boxes'
 *   },
 * });
 */
export function useImportBoxesMutation(baseOptions?: Apollo.MutationHookOptions<ImportBoxesMutation, ImportBoxesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportBoxesMutation, ImportBoxesMutationVariables>(ImportBoxesDocument, options);
      }
export type ImportBoxesMutationHookResult = ReturnType<typeof useImportBoxesMutation>;
export type ImportBoxesMutationResult = Apollo.MutationResult<ImportBoxesMutation>;
export type ImportBoxesMutationOptions = Apollo.BaseMutationOptions<ImportBoxesMutation, ImportBoxesMutationVariables>;