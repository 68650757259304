import styled from 'styled-components';

const Title = styled.h1`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  color: #002033;
  margin: 0;
`;

export default Title;