import styled from 'styled-components';

export const TableCellPadding = styled.div`
  margin-top: 15px;

  transform: rotateX(180deg);
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 15px;
  }

  .Table {
    overflow: unset;
    transform: rotateX(180deg);
  }

  .TableCell-Wrapper {
    padding: 2px 8px !important;

    .customInputText ~ button {
      top: 3px;
    }

    &.TableCell-Wrapper_isHeader {
      padding: 12px 16px !important;
    }
  }
`;