import { useEffect, useState } from 'react';
import { AdminType, UserType } from 'types';
import { useAdminQuery } from 'app/api/admin.generated';
import { getAuthToken } from 'shared/api/auth';

export const useAdmin = () => {
  const token = getAuthToken();

  const { loading, data, refetch } = useAdminQuery({
    skip: !token,
    fetchPolicy: 'cache-first', // Used for first execution
    nextFetchPolicy: 'cache-first',
    refetchWritePolicy: 'overwrite',
  });

  const [admin, setUser] = useState<AdminType | undefined>(() => data?.admin);

  useEffect(() => {
    if (data?.admin) {
      setUser(data.admin);
    }
  }, [data]);

  const isAuth = !!admin;

  return {
    admin,
    isAuth,
    refetch,
    loading,
  };
};
