import React, { FC, useEffect, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';
import { TextField } from '@consta/uikit/TextField';
import { IconClose } from '@consta/uikit/IconClose';
import { useCreateAddressMutation } from 'pages/packagesAdd/api/createAddress.generated';
import { useAddresses } from 'entities/addresses';
import { showSuccessNotification } from 'widgets/notifications/api/showSuccessNotification';
import { AddressType, Maybe } from 'types';
import Margin from 'shared/ui/components/margin';
import { useEditAddressMutation } from '../api/editAddress.generated';
import { CustomModal, CloseBtnWrapper, MobileContainer } from './styled';
import SdekWidget from './sdekWidget';

interface IProps {
  isOpen: boolean,
  addressEdit?: Maybe<AddressType>,
  closeModal: () => void,
  createAddressCallback?: (id: number, value: string) => void,
};

const AddressAddModal: FC<IProps> = ({ isOpen, addressEdit, closeModal, createAddressCallback }: IProps) => {
  const { refetch } = useAddresses();
  const [createAddress] = useCreateAddressMutation();
  const [editAddress] = useEditAddressMutation();
  const isMobile = window.innerWidth < 640;

  const [inputValue, setInputValue] = useState<string>('');
  const [inputId, setInputId] = useState<string | undefined>();

  const [isManualInput, setIsManualInput] = useState(false);

  useEffect(() => {
    if (addressEdit) setInputValue(addressEdit.address);
  }, [addressEdit]);

  const handleChoseAddress = (id: string, address: string) => {
    setInputId(id);
    setInputValue(address);
    setIsManualInput(false);
  };

  const handleCloseModal = () => {
    closeModal();
    setInputValue('');
  };

  const handleCreateAddress = () => {
    if (addressEdit) {
      editAddress({
        variables: {
          id: addressEdit?.id,
          input: {
            sdekId: isManualInput ? null : inputId,
            address: inputValue,
          },
        },
      }).then(() => showNotificationAndClose());
    } else {
      createAddress({
        variables: {
          input: {
            sdekId: isManualInput ? null : inputId,
            address: inputValue,
          },
        },
      }).then((resp) => showNotificationAndClose(resp.data?.createAddress.id));
    }
  };

  const showNotificationAndClose = (id?: number) => {
    showSuccessNotification(`Адрес успешно ${addressEdit ? 'изменен' : 'добавлен'}`);
    if (!addressEdit && id) createAddressCallback?.(id, inputValue);
    handleCloseModal();
    setInputValue('');
    refetch();
  };

  const handleChangeAddress = (value: string) => {
    if (value.trim().length <= 128) {
      setIsManualInput(true);
      setInputValue(value);
    }
  };

  const renderModalContent = () => (
    <CustomModal>
      <SdekWidget onChooseAddress={handleChoseAddress} />
      <TextField
        value={inputValue}
        width='full'
        onChange={({ value }) => handleChangeAddress(value || '')}
        placeholder='Адрес пункта выдачи'
      />
      <Margin mt={16}>
      <Button
        size='s'
        width='full'
        label={`${addressEdit ? 'Сохранить' : 'Добавить'} адрес`}
        disabled={!inputValue.trim().length}
        onClick={handleCreateAddress}
        />
      </Margin>
    </CustomModal>
  );

  return (!isMobile
    ? <Modal
        isOpen={isOpen}
        hasOverlay
        onClickOutside={handleCloseModal}
        onEsc={handleCloseModal}
      >
        {renderModalContent()}
      </Modal>
    : isOpen && (
        <MobileContainer>
          {renderModalContent()}
          <CloseBtnWrapper>
            <Button iconLeft={IconClose} view='clear' size='xs' onClick={handleCloseModal}/>
          </CloseBtnWrapper>
        </MobileContainer>
      )
  );
};

export default AddressAddModal;
