import React, { FC, useEffect } from 'react';
import { VerticalMenuContainer } from './styled';

interface dataFormProps {
  zIndex: number,
  children: JSX.Element,
}

const MobileFullScreenMenu: FC<dataFormProps>  = ({ zIndex, children }: dataFormProps) => {
  useEffect(() => {
    const html = document.querySelector('html') as HTMLHtmlElement;
    html.scrollTop = 0;
    html.style.overflowY = 'hidden';
    return () => {
      html.style.overflowY = 'auto';
    };
  });

  return (
    <VerticalMenuContainer zIndex={zIndex}>{ children }</VerticalMenuContainer>
  );
};

export default MobileFullScreenMenu;
