import styled from 'styled-components';

export const ModalContent = styled.div`
  padding: 40px 115px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .orderModalTitle {
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
    color: #FAFAFA;
    margin-bottom: 32px;
  }

  .Select {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: 1280px) {
    padding: 40px 60px;
  }

  @media (max-width: 640px) {
    padding: 40px;
  }
`;