import React, { FC, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconClose } from '@consta/uikit/IconClose';
import TransferModal from '../transferModal';
import { CounterBlock } from './styled';
import 'react-international-phone/style.css';

interface dataFormProps {
  ids: number[],
  onClose: () => void,
  onTransfer: () => void,
};

const Counter: FC<dataFormProps> = ({ ids, onClose, onTransfer }: dataFormProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHiddenCounter, setIsHiddenCounter] = useState(false);

  const onSend = () => {
    setIsHiddenCounter(true);
    setIsModalOpen(true);
  };

  return (
    <>
      {ids?.length && !isHiddenCounter && (
        <CounterBlock>
          <Button
            className='close'
            label='Закрыть'
            view='clear'
            iconLeft={IconClose}
            onlyIcon
            size='s'
            onClick={onClose}
          />
          <p>Количество выбранных посылок: {ids.length}</p>
          <Button
            className='send'
            label='Передать другому пользователю'
            size='s'
            form='round'
            onClick={onSend}
          />
        </CounterBlock>
      ) || null}
      <TransferModal
        ids={ids}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setIsHiddenCounter(false);
          onClose();
        }}
        onTransfer={onTransfer}
      />
    </>
  );
};

export default Counter;
