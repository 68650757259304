import styled from 'styled-components';

export const PackageContainer = styled.div`
  display: flex;
  margin-right: -40px;

  .textButton {
    padding: 0;
    display: block;
    background: transparent;
    border: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    color: #0F9FFF;
    cursor: pointer;
    text-align: left;
  }

  .main {
    width: calc(100% - 350px);
    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 120%;
      color: #FAFAFA;
      margin-bottom: 40px;
    }

    .content {
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(246, 251, 253, 0.06);
    }
  }

  .recipientInfo {
    margin-top: 30px;

    .titleBlock {
      display: flex;
      margin-bottom: 16px;

      .recipientTitle {
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        color: #FAFAFA;
        margin-right: 70px;
      }
    }

    .titleBlock, .row {
      & > div {
        &:first-child {
          width: 20%;
        }
      }
    }
  }

  .row {
    display: flex;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    & > div {
      margin-right: 70px;

      &:first-child {
        width: 30%;
      }
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.60);
      font-size: 12px;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 4px;
    }

    .packageInfo {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      color: #FAFAFA;

      .packageInfoButton {
        margin-top: 12px;
      }
    }
  }

  @media (max-width: 1280px) {
    margin-right: 0;

    .main {
      width: 100%;
      .title {
        font-size: 24px;
        margin-bottom: 12px;
      }

      .content {
        padding-bottom: 60px;
        margin-top: 32px;
      }
    }

    .recipientInfo {
      margin-top: 24px;

      .titleBlock, .row {
        & > div {
          width: unset !important;
          &:first-child {
            width: 30% !important;
          }
        }
      }
    }

    .row {
      margin-bottom: 0;
      flex-wrap: wrap;

      &:last-child {
        margin-bottom: 0;
      }

      & > div {
        width: 60%;
        margin-bottom: 24px;

        &:first-child {
          width: 60%;
        }
      }

      &.long {
        & > div {
          width: 50%;
          margin-bottom: 24px;
          margin-right: 0;

          &:nth-child(1) {
            order: 1;
          }

          &:nth-child(2) {
            order: 3;
          }

          &:nth-child(3) {
            order: 2;
          }

          &:nth-child(4) {
            order: 4;
          }
  
          &:first-child {
            width: 50%;
          }
        }
      }
    }
  }

  @media (max-width: 640px) {
    .main {
      .content {
        padding-bottom: 48px;
        margin-top: 20px;
      }
    }

    .recipientInfo {
      margin-top: 16px;
      position: relative;
      margin-bottom: 20px;

      .titleBlock {
        .textButton {
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 100%;
        }
      }

      .titleBlock, .row {
        width: 100%;
        & > div {
          width: 50% !important;
          margin-right: 0 !important;

          &:first-child {
            width: 50% !important;
          }
        }
      }
    }

    .row {
      margin-bottom: 0;
      flex-wrap: wrap;

      & > div {
        width: 100%;

        &:first-child {
          width: 100%;
        }
      }
    }
  }
`;