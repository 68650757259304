import React, { FC, useState, useMemo } from 'react';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Tabs } from '@consta/uikit/Tabs';
import { DeliveryStatus } from 'types';
import Margin from 'shared/ui/components/margin';
import { statusesLabels } from 'shared/constants';
import Waiting from './ui/waiting';
import Transferred from './ui/transferred';
import Orders from './ui/orders';
import { NameBlock } from './styled';

export const menu: Record<DeliveryStatus | 'WAITING' | 'TRANSFERRED', string> = {
  WAITING: 'Ожидаемые посылки',
  ...statusesLabels,
  TRANSFERRED: 'Переданные',
};

const PackagesPage: FC = () => {
  const [search, setSearch] = useState<string | null>('');

  const getTabLabel = (label: string) => label;

  const [tab, setTab] = useState<string | null>(menu.WAITING);

  const currentDeliveryStatus = useMemo(() => {
    // @ts-ignore
    return Object.keys(menu).find(item => menu[item] === tab) as DeliveryStatus;
  }, [menu, tab]);

  return (
    <>
      <NameBlock>
        <h1>Посылки</h1>
        <TextField
          size='s'
          placeholder='Трек-номер или описание посылки'
          value={search}
          form='round'
          onChange={({ value }) => setSearch(value)}
          leftSide={IconSearch}
          withClearButton
        />
      </NameBlock>
      <Margin mb={20}>
        <Tabs
          value={tab}
          onChange={({ value }) => setTab(value)}
          items={Object.values(menu)}
          getItemLabel={getTabLabel}
          fitMode='scroll'
        />
      </Margin>
      {tab === menu.WAITING && <Waiting q={search || ''} />}
      {tab === menu.TRANSFERRED && <Transferred q={search || ''} />}
      {tab !== menu.WAITING && tab !== menu.TRANSFERRED && <Orders q={search || ''} status={currentDeliveryStatus} />}
    </>
  );
};

export default PackagesPage;
