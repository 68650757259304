import { styled } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  bottom: 40px;
  right: 40px;
  @media (max-width: 640px) {
    bottom: 20px;
    right: 20px;
  }
`;
