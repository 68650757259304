import React, { FC } from 'react';
import { ReactComponent as Logo } from 'shared/assets/images/logo.svg';
import { ReactComponent as TeamLogo } from 'shared/assets/images/team-logo.svg';
import { openChat } from 'shared/api/chat';
import { FooterContainer, FooterTeam } from './styled';

const Footer: FC = () => {
    const menu = [
      {
        label: 'Посылки',
        href: '/packages',
      },
      {
        label: 'Мои адреса',
        href: '/addresses',
      },
      {
        label: 'Наши склады / Инструкция',
        href: '/instructions',
      },
      {
        label: 'Выкуп',
        href: 'https://usaclubshop.com/buy',
      },
    ];

    return (
      <>
        <div id='footer'>
          <FooterContainer>
            <div className='imgBlock'>
              <Logo />
            </div>
            <div className='infoBlock'>
              <p>
                  По всем вопросам вы можете обращаться в нашу службу поддержки<br/>
                  Мы работаем по будням с 10:00 до 20:00 по московскому времени
              </p>
              <button onClick={openChat}>Написать в службу поддержки</button>
            </div>
            <div className='linksBlock'>
              {menu.map(item => <a key={item.href} href={item.href}>{item.label}</a>)}
            </div>
          </FooterContainer>
          <FooterTeam>
            <div>
              {/* <a href='/docs/oferta.pdf' target='_blank'>Публичная оферта</a> */}
              {/* <a href='/docs/privacy.pdf' target='_blank'>Политика конфиденциальности</a> */}
            </div>
            <a href='https://circle-studio.kz' target='_blank' rel='noreferrer'><TeamLogo /></a>
          </FooterTeam>
        </div> 
      </>
    );
};

export default Footer;