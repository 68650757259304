import { FC, useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Table, TableFilters, SortByProps, TableFilterContainer } from '@consta/uikit/Table';
import { TextField } from '@consta/uikit/TextField';
import { UserType, UsersListOrderInput, UsersListFilterInput, SortEnum } from 'types';
import { NameBlock } from 'pages/packages/styled';
import { useUpdateUserMutation } from 'pages/login/api/updateUser.generated';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { UserLink } from 'shared/ui/components/userLink';
import TableInput from 'shared/ui/components/tableInput';
import { TableCellPadding } from '../boxes/styled';
import { useUsersListQuery } from './api/usersList.generated';

import '../table.css';

// TODO: remove any

const Users: FC = () => {
  const pageSize = 40;
  const [offset, setOffset] = useState(0);

  const [filter, setFilter] = useState<UsersListFilterInput>({});
  const [filterCopy, setFilterCopy] = useState<UsersListFilterInput>({});
  const [sortOrder, setSortOrder] = useState<UsersListOrderInput>({ createdAt: SortEnum.Desc });

  const { data, refetch } = useUsersListQuery({
    variables: {
      filter,
      pagination: {
        limit: pageSize,
        offset: offset * pageSize,
        order: sortOrder,
      },
    },
  });

  const [users, setUsers] = useState<UserType[]>([]);
  const [total, setTotal] = useState(0);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  useEffect(() => {
    if (data?.usersList?.items) {
      if (offset) {
        setUsers([...users, ...data.usersList.items]);
      } else {
        setUsers(data.usersList.items);
        setTotal(data.usersList.total);
      }
    }
  }, [data]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  useEffect(() => {
    updateList();
  }, [filter, offset, sortOrder]);

  const updateList = async () => {
    await refetch({
      filter: filter || {},
      pagination: {
        limit: pageSize,
        offset: offset * pageSize,
        order: sortOrder || {},
      },
    });
  };

  const [updateUser, {data: dataEdit, error: errorEdit}] = useUpdateUserMutation();

  const [editingUserId, setEditingUserId] = useState<number | undefined>();
  const [editingField, setEditingField] = useState<'phone' | 'email' | 'name' | 'nameEn' | undefined>();

  const saveChanges = async (value: string | null) => {
    if (editingUserId && editingField) {
      await updateUser({
        variables: {
          input: {
            id: editingUserId,
            [editingField]: value,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (dataEdit?.updateUser?.id && !errorEdit) {
      setEditingField(undefined);
      setEditingUserId(undefined);

      refetch({
        pagination: {
          limit: users.length,
        },
      });
    }
  }, [dataEdit, errorEdit]);

  const columns = [
    {
      title: 'ID',
      accessor: 'id',
      sortable: true,
      renderCell: ({ id }: UserType) => (<UserLink>U{id}</UserLink>),
      width: 75,
    },
    {
      title: 'ТЕЛЕФОН',
      accessor: 'phone',
      sortable: false,
      renderCell: ({ id, phone }: UserType) => (
        <TableInput
          inputValue={phone}
          disabled={(id !== editingUserId || editingField !== 'phone')}
          onClear={() => setEditingUserId(undefined)}
          onSave={saveChanges}
          onClickEdit={() => {
            setEditingUserId(id);
            setEditingField('phone');
          }}
          onBlur={() => {
            setEditingUserId(undefined);
            setEditingField(undefined);
          }}
          small
        />
      ),
    },
    {
      title: 'EMAIL',
      accessor: 'email',
      sortable: true,
      renderCell: ({ id, email }: UserType) => (
        <TableInput
          inputValue={email}
          disabled={(id !== editingUserId || editingField !== 'email')}
          onClear={() => setEditingUserId(undefined)}
          onSave={saveChanges}
          onClickEdit={() => {
            setEditingUserId(id);
            setEditingField('email');
          }}
          onBlur={() => {
            setEditingUserId(undefined);
            setEditingField(undefined);
          }}
          small
        />
      ),
    },
    {
      title: 'ДАТА РЕГИСТРАЦИИ',
      accessor: 'createdAt',
      sortable: true,
      renderCell: ({ createdAt }: UserType) => (
        <>{dayjs(createdAt).format('DD.MM.YYYY')}</>
      ),
      width: 185,
    },
    {
      title: 'ИМЯ КИРИЛЛИЦА',
      accessor: 'name',
      sortable: true,
      renderCell: ({ id, name }: UserType) => (
        <TableInput
          inputValue={name}
          disabled={(id !== editingUserId || editingField !== 'name')}
          onClear={() => setEditingUserId(undefined)}
          onSave={saveChanges}
          onClickEdit={() => {
            setEditingUserId(id);
            setEditingField('name');
          }}
          onBlur={() => {
            setEditingUserId(undefined);
            setEditingField(undefined);
          }}
          small
        />
      ),
    },
    {
      title: 'ИМЯ ЛАТИННИЦА',
      accessor: 'nameEn',
      sortable: true,
      renderCell: ({ id, nameEn }: UserType) => (
        <TableInput
          inputValue={nameEn}
          disabled={(id !== editingUserId || editingField !== 'nameEn')}
          onClear={() => setEditingUserId(undefined)}
          onSave={saveChanges}
          onClickEdit={() => {
            setEditingUserId(id);
            setEditingField('nameEn');
          }}
          onBlur={() => {
            setEditingUserId(undefined);
            setEditingField(undefined);
          }}
          small
        />
      ),
    },
  ];

  const handleSortingUpdated = (value: SortByProps<any> | null) => {
    setOffset(0);
    if (value) {
      const sort = {
        [value.sortingBy]: value?.sortOrder.toUpperCase(),
      };
      setSortOrder(sort);
    } else {
      setSortOrder({ createdAt: SortEnum.Desc });
    }
  };

  const handleFilterUpdated = (value: any) => {
    setOffset(0);

    const filtersObj = { ...filter };

    Object.keys(value).forEach(item => {
      if (value[item]?.selected?.length) {
        filtersObj[item] = filterCopy?.[item];
      }

      if (filter && filter[item] && !value[item]?.selected?.length) {
        filtersObj[item] = null;
      }
    });

    setFilter(filtersObj);
    setFilterCopy(filtersObj);
  };

  const filters: TableFilters<any> = [
    {
      id: 'phone',
      name: `Телефон: ${filter?.phone}`,
      field: 'phone',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: <TextField
            value={filterCopy?.phone}
            onChange={({ value }) => {
              setOffset(0);
              setFilterCopy({ ...filterCopy, phone: value });
            }}
            width='full'
          />,
        },
      },
    },
    {
      id: 'email',
      name: `Email: ${filter?.email}`,
      field: 'email',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: <TextField
            value={filterCopy?.email}
            onChange={({ value }) => {
              setOffset(0);
              setFilterCopy({ ...filterCopy, email: value });
            }}
            width='full'
          />,
        },
      },
    },
    {
      id: 'name',
      name: `Имя (кириллица): ${filter?.name}`,
      field: 'name',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          children: <TextField
            value={filterCopy?.name}
            onChange={({ value }) => {
              setOffset(0);
              setFilterCopy({ ...filterCopy, name: value });
            }}
            width='full'
          />,
        },
      },
    },
    {
      id: 'nameEn',
      name: `Имя (латинница): ${filter?.nameEn}`,
      field: 'nameEn',
      filterer: () => true,
      component: {
        name: TableFilterContainer as any,
        props: {
          onConfirm: handleSortingUpdated,
          children: <TextField
            value={filterCopy?.nameEn}
            onChange={({ value }) => {
              setOffset(0);
              setFilterCopy({ ...filterCopy, nameEn: value });
            }}
            width='full'
          />,
        },
      },
    },
  ];

  return (
    <>
      <NameBlock>
        <h1>Пользователи</h1>
      </NameBlock>
      <TableCellPadding>
        {/* @ts-ignore */}
        <Table rows={users} columns={columns} filters={filters}
          verticalAlign='center'
          borderBetweenColumns
          zebraStriped='odd'
          onSortBy={(value) => handleSortingUpdated(value)}
          onFiltersUpdated={(value) => handleFilterUpdated(value)}
        />
      </TableCellPadding>
    </>
  );
};

export default Users;
