import styled from 'styled-components';

export const ShipmentInfoContainer = styled.div`
  width: 100%;
  display: flex;

  .column {
    width: 16.66%;

    .item {
      margin-bottom: 20px;

      & > div {
        padding-right: 10px;
  
        &:first-child {
          color: rgba(255, 255, 255, 0.60);
          font-size: 12px;
          font-weight: 400;
          line-height: 120%;
          margin-bottom: 4px;
        }
  
        &:nth-child(2) {
          color: #FAFAFA;
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
        }
      }

      .TextField {
        width: calc(100% - 69px);
        margin-right: 5px;
        padding-right: 0;
      }

      .Button {
        display: inline-block;
      }
    }
  }
`;