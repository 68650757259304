import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateShipmentClientWeightMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateShipmentClientWeightInput;
}>;


export type UpdateShipmentClientWeightMutation = { __typename?: 'Mutation', updateShipmentClientWeight: boolean };


export const UpdateShipmentClientWeightDocument = gql`
    mutation UpdateShipmentClientWeight($id: Int!, $input: UpdateShipmentClientWeightInput!) {
  updateShipmentClientWeight(id: $id, input: $input)
}
    `;
export type UpdateShipmentClientWeightMutationFn = Apollo.MutationFunction<UpdateShipmentClientWeightMutation, UpdateShipmentClientWeightMutationVariables>;

/**
 * __useUpdateShipmentClientWeightMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentClientWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentClientWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentClientWeightMutation, { data, loading, error }] = useUpdateShipmentClientWeightMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipmentClientWeightMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShipmentClientWeightMutation, UpdateShipmentClientWeightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShipmentClientWeightMutation, UpdateShipmentClientWeightMutationVariables>(UpdateShipmentClientWeightDocument, options);
      }
export type UpdateShipmentClientWeightMutationHookResult = ReturnType<typeof useUpdateShipmentClientWeightMutation>;
export type UpdateShipmentClientWeightMutationResult = Apollo.MutationResult<UpdateShipmentClientWeightMutation>;
export type UpdateShipmentClientWeightMutationOptions = Apollo.BaseMutationOptions<UpdateShipmentClientWeightMutation, UpdateShipmentClientWeightMutationVariables>;