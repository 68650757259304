import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnterPhoneMutationVariables = Types.Exact<{
  input: Types.EnterPhoneInput;
}>;


export type EnterPhoneMutation = { __typename?: 'Mutation', enterPhone: boolean };


export const EnterPhoneDocument = gql`
    mutation EnterPhone($input: EnterPhoneInput!) {
  enterPhone(input: $input)
}
    `;
export type EnterPhoneMutationFn = Apollo.MutationFunction<EnterPhoneMutation, EnterPhoneMutationVariables>;

/**
 * __useEnterPhoneMutation__
 *
 * To run a mutation, you first call `useEnterPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterPhoneMutation, { data, loading, error }] = useEnterPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnterPhoneMutation(baseOptions?: Apollo.MutationHookOptions<EnterPhoneMutation, EnterPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterPhoneMutation, EnterPhoneMutationVariables>(EnterPhoneDocument, options);
      }
export type EnterPhoneMutationHookResult = ReturnType<typeof useEnterPhoneMutation>;
export type EnterPhoneMutationResult = Apollo.MutationResult<EnterPhoneMutation>;
export type EnterPhoneMutationOptions = Apollo.BaseMutationOptions<EnterPhoneMutation, EnterPhoneMutationVariables>;