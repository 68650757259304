import styled from 'styled-components';

export const OrderCardContainer = styled.div`
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  background: transparent;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.32), 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 12px;
  padding: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .orderCardTitle {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    p {
      margin: 0;
      padding: 0;
      color: #FAFAFA;
      font-size: 20px;
      font-weight: 500;
      line-height: 120%;
    }

    button {
      margin-left: 6px;
    }

    .Badge {
      margin-left: 15px;
    }
  }

  .orderCardRow {
    display: flex;
    justify-content: space-between;

    .block {
      &.buttons {
        height: 92px;
        margin-top: -48px;
      }

      .subtitle {
        color: rgba(255, 255, 255, 0.60);
        font-size: 12px;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 4px;
      }

      .content {
        color: #FAFAFA;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
      }

      .paymentButton {
        text-align: right;
        margin-bottom: 12px;
        margin-left: auto;
        display: block;
        background: #10AE79;
      }
    }
  }

  @media (max-width: 1280px) {
    .orderCardRow {
      flex-wrap: wrap;
  
      .block {
        width: 50%;
        height: auto !important;
        margin-bottom: 12px;
        margin-top: 0 !important;

        &:nth-child(even) {
          text-align: right;
        }

        &:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 640px) {
    padding: 20px 12px;

    .orderCardRow {
      flex-direction: column;
      justify-content: flex-start;

      .block {
        display: flex;
        align-items: center;
        width: 100%;

        &:nth-child(even) {
          text-align: left;
        }

        &:nth-last-child(-n + 2) {
          margin-bottom: 12px;
        }

        &:last-child {
          margin-bottom: 0;
          display: block;

          .paymentButton {
            margin-left: 0;
          }
        }

        .subtitle {
          font-size: 10px;
          margin-bottom: 0;
          margin-right: 8px;
        }
      }
    }
  }
`;

export const TooltipContent = styled.div`
  p {
    margin: 0 0 8px 0;
    font-size: 12px;
    line-height: 16.8px;
  }
  span {
    margin-bottom: 4px;
    font-weight: 700;
  }

  @media (max-width: 640px) {
    p {
      font-size: 10px;
    }
  }
`;
