import styled from 'styled-components';

export const MobileCardBlock = styled.div`
  background: #22272B;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.32), 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 16px;
  padding: 18px 12px 12px;
  position: relative;

  .Checkbox {
    position: absolute;
    top: 18px;
    right: 12px;
  }

  & > .row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .title, .text, .customInputText {
      font-size: 10px !important;
      font-weight: 700;
      line-height: 120%;
      color: #FAFAFA;
      margin-right: 12px;
    }

    .text, .customInputText {
      font-weight: 400;
      margin-right: 0;

      &.link {
        cursor: pointer;

        a {
          color: #0F9FFF;
        }
      }
    }
  }

  .TextField, .customInputText {
    width: calc(100% - 20px);
    margin-top: 8px;
    border: 1px solid rgba(246, 251, 253, 0.28);
    border-radius: 4px;
    padding: 0 10px;

    input {
      font-size: 10px !important;
    }
  }

  .customInputText {
    min-height: 20px;
    width: calc(100% - 57px);
    padding: 10px 45px 4px 10px;
    white-space: pre-wrap;
  }

  .Button_size_s {
    top: 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;