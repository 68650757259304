import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteBoxMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteBoxMutation = { __typename?: 'Mutation', deleteBox: boolean };


export const DeleteBoxDocument = gql`
    mutation deleteBox($id: Int!) {
  deleteBox(id: $id)
}
    `;
export type DeleteBoxMutationFn = Apollo.MutationFunction<DeleteBoxMutation, DeleteBoxMutationVariables>;

/**
 * __useDeleteBoxMutation__
 *
 * To run a mutation, you first call `useDeleteBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBoxMutation, { data, loading, error }] = useDeleteBoxMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBoxMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBoxMutation, DeleteBoxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBoxMutation, DeleteBoxMutationVariables>(DeleteBoxDocument, options);
      }
export type DeleteBoxMutationHookResult = ReturnType<typeof useDeleteBoxMutation>;
export type DeleteBoxMutationResult = Apollo.MutationResult<DeleteBoxMutation>;
export type DeleteBoxMutationOptions = Apollo.BaseMutationOptions<DeleteBoxMutation, DeleteBoxMutationVariables>;