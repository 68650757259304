import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Maybe } from 'types';
import { useUser } from 'entities/user';
import { useUpdateUserMutation } from 'pages/login/api/updateUser.generated';
import { showSuccessNotification } from 'widgets/notifications/api/showSuccessNotification';
import MobileOnly from 'shared/ui/components/mobileOnly';
import Margin from 'shared/ui/components/margin';
import Breadcrumbs from 'shared/ui/components/breadcrumbs';
import { Divider, NotificationsBlock, Wrapper } from './ui/styled';
import { useDisableTelegramIntegrationMutation } from './api/disableTelegramIntegration.generated';
import ProfileForm from './ui/profileForm';
import { validationSchema } from './ui/validationSchema';

export interface UserData {
  name?: string,
  surname?: string,
  nameEn?: string,
  surnameEn?: string,
  email?: Maybe<string>,
  phone: string
};

const ProfilePage: FC = () => {
  const [disableTelegramIntegration] = useDisableTelegramIntegrationMutation();
  const [updateUser] = useUpdateUserMutation();
  const { user } = useUser();
  const [ userData, setUserData ] = useState<Maybe<UserData>>(null);
  const [ isEditMode, setIsEditMode ] = useState(false);

  useEffect(() => {
    if (!user) return;

    const getName = (index: number, str?: string): string | undefined => str?.split(' ')[index];

    const { name, nameEn, phone, email } = user;
    const initUserData = {
      name: name ? getName(0, name) : '',
      surname: name ? getName(1, name) : '',
      nameEn: nameEn ? getName(0, nameEn) : '',
      surnameEn: nameEn ? getName(1, nameEn) : '',
      email,
      phone,
    };

    setUserData(initUserData);
  }, [user]);

  const renderNotificationsEnabledInfo = (): ReactElement => (
    <>
      <p>Вы можете получать уведомления о движении посылок в вашем Telegram.</p>
      <p>Для этого нужно перейти по ссылке ниже, написать в чат команду /start и ввести свой номер телефона.</p>
      <a href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT}`} target='_blank' rel='noreferrer'>
        Подключить уведомления в Telegram
      </a>
    </>
  );

  const renderNotificationsDisabledInfo = (): ReactElement => (
    <>
      <p>Вы подключили уведомления в telegram.</p>
      <a className='disable' onClick={handleNotificationsDisable}>Отключить уведомления в Telegram</a>
    </>
  );

  const handleNotificationsDisable = () => {
    disableTelegramIntegration();
  };

  const handleSubmit = (values: UserData) => {
    if (!deepEqual(values, userData)) onSend(values);
    else setIsEditMode(false);
  };

  const deepEqual = (obj1: Maybe<object>, obj2: Maybe<object>) => JSON.stringify(obj1) === JSON.stringify(obj2);

  const onSend = async (values: UserData) => {
    const { name, surname, nameEn, surnameEn, email } = values;

    await updateUser({
      variables: {
        input: {
          name: `${name} ${surname}`,
          nameEn: `${nameEn} ${surnameEn}`,
          email,
        },
      },
    }).then(() => {
      setIsEditMode(false);
      showSuccessNotification('Профиль успешно обновлен');
    });
  };

  const navigate = useNavigate();

  return (
    <>
      <Margin mb={10}>
        <Breadcrumbs
          prevText='Посылки'
          prevClick={() => navigate('/')}
          currentText='Профиль'
        />
      </Margin>
      <Wrapper>
        {userData && <Formik
          initialValues={userData}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
        {(props) => (
          <ProfileForm
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            {...props}
          />
        )}
        </Formik>}
        <MobileOnly breakpoint={1280}>
          <Divider/>
        </MobileOnly>
        <NotificationsBlock>
          <div className='notifications_wrapper'>
            <h2>Уведомления</h2>
            {user?.isTelegramNotificationsEnabled
              ? renderNotificationsDisabledInfo()
              : renderNotificationsEnabledInfo()
            }
          </div>
        </NotificationsBlock>
      </Wrapper>
    </>
  );
};

export default ProfilePage;
