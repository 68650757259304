import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CaptchaWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PhoneInputContainer = styled.div`
  display: flex;
  justify-content: center;

  .react-international-phone-country-selector-button {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    height: 40px;
    padding: 0 10px;
  }

  .react-international-phone-input {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    margin-right: 24px;
    height: 40px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: calc(100% - 65px);
    max-width: 250px;
  }

  @media (max-width: 1280px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .react-international-phone-input {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin-left: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #002033;

    a {
      color: #0078D2;
    }
  }
`;

export const OtpInputContainer = styled.div`
  input[type=number] {
    height: 60px;
    width: 60px !important;
    margin-right: 12px;
    margin-bottom: 20px;
    background: transparent;
    border: 1px solid rgba(0, 66, 105, 0.28);
    border-radius: 8px;
    font-size: 18px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #002033;
    outline: none;
    -moz-appearance: textfield;
    
    &:last-child {
      margin-right: 0
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const Timer = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: rgba(0, 32, 51, 0.60);
`;

export const SendAgain = styled.button`
  background: transparent;
  border: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #0078D2;
  cursor: pointer;
`;
