import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  * {
    box-sizing: border-box;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
`;

export const ProfileBlock = styled.div`
  width: calc(100% - 102px);

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export const NotificationsBlock = styled.div`
  width: 310px;
  padding: 12px 0 12px 32px;
  color: rgba(250, 250, 250, 1);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(246, 251, 253, 0.1);
    width: 350px;
    height: 100%;
  }
  .notifications_wrapper {
    position: relative;
  }
  h2 {
    margin: 0 0 20px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  p, a {
    margin: 0 0 8px 0;
    font-size: 14px;
    line-height: 21px;
  }
  a {
    color: rgba(15, 159, 255, 1);
    cursor: pointer;
  }
  a.disable {
    color: rgba(247, 59, 59, 1);
  }

  @media (max-width: 1280px) {
    width: 100%;
    padding: 40px 0 0 0;
    &:before {
      display: none;
    }
    h2 {
      margin: 0 0 16px 0;
      font-size: 16px;
    }
    p, a {
      margin: 0;
      font-size: 12px;
      line-height: 18px;
    }
    a {
      line-height: 40px;
    }
  }

  @media (max-width: 1280px) {
    padding: 32px 0 0 0;
    h2 {
      font-size: 14px;
    }
    p, a {
      margin-bottom: 2px;
    }
    a {
      line-height: 32px;
    }
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    color: #FAFAFA;
  }

  @media (max-width: 1280px) {
    h1 {
      font-size: 24px;
    }
    button {
      font-size: 14px;
      line-height: 14px;
      height: 32px;
    }
  }

  @media (max-width: 640px) {
    h1 {
      font-size: 20px;
    }
    button {
      font-size: 12px;
      height:24px;
    }
  }
`;

export const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 310px 1fr;
  margin-bottom: 20px;
  &:nth-child(2n), &:nth-child(5) {
    margin-bottom: 60px;
  }
  label {
    font-size: 16px;
    color: rgba(250, 250, 250, 1);
  }
  p {
    margin: 4px 0 0 0;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.3);
  }
  a {
    color: rgba(87, 188, 255, 1);
    cursor: pointer;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 207px 1fr;
    margin-bottom: 16px;
    &:nth-child(2n), &:nth-child(5) {
      margin-bottom: 40px;
    }
    &:last-child {
      margin-bottom: 60px;
    }
    label {
      font-size: 12px;
      line-height: 18px;
    }
    input {
      height: 28px;
      font-size: 14px;
    }
    .TextField-InputContainer {
      min-height: 30px;
    }

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
      margin-bottom: 16px;
      &:nth-child(2n), &:nth-child(5) {
        margin-bottom: 32px;
      }
      &:last-child {
        margin-bottom: 48px;
      }
      label {
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        margin-bottom: 8px;
      }
      input {
        height: 22px;
        font-size: 10px;
      }
      .TextField-InputContainer {
        min-height: 24px;
      }
      p {
        margin: 2px 0 0 0;
        font-size: 8px;
      }
    }
  }
`;

export const FieldsWrapper = styled.div`
  margin-top: 60px;

  @media (max-width: 1280px) {
    margin-top: 40px;
  }

  @media (max-width: 640px) {
    margin-top: 24px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100vw;
  margin-left: -20px;
  position: absolute;
  background-color: rgba(246, 251, 253, 0.06);
`;
