import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 450px;
  background: rgba(34, 39, 43, 1);
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.1);
  .empty_message {
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
  }

  @media (max-width: 1280px) {
    border: none;
    box-shadow: none;
  }

  @media (max-width: 640px) {
    min-width: 100%;
  }
`;

export const NotificationsHeader = styled.div`
  color: #FAFAFA;
  padding: 22px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  h2 {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
`;

export const CardWrapper = styled.div`
  padding: 20px 24px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  }
  p {
    margin: 0;
    color: rgba(255, 255, 255, 0.60);
    font-size: 16px;
    font-weight: 700;
  }
  .main_info {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 16px;
  }
  .text {
    font-size: 14px;
    margin-top: 8px;
  }
  .date {
    font-size: 12px;
    margin-top: 16px;
  }
  .read, .read p {
    color: #FAFAFA;
  }

  @media (max-width: 1280px) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  }
  
  @media (max-width: 640px) {
    p {
      font-size: 14px;
    }
    .text {
      font-size: 12px;
      margin-top: 4px;
    }
    .date {
      font-size: 10px;
      margin-top: 16px;
    }
  }
`;

export const CardsWrapper = styled.div`
  overflow-y: scroll;
  max-height: 383px;

  @media (max-width: 1280px) {
    overflow-y: unset;
  }
`;
