import { setContext } from '@apollo/client/link/context';
import { FC } from 'react';
import { ApolloClient, ApolloProvider, createHttpLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { showErrorNotification } from 'widgets/notifications/api/showErrorNotification';
import { RefreshTokenDocument } from 'app/api/refreshToken.generated';
import { AuthPayloadType } from 'types';
import { getAuthToken, getExpiredDate, getRefreshToken, removeAuthToken, setAuthToken } from 'shared/api/auth';

/**
 * Setup cache.
 */
const cache = new InMemoryCache();

/**
 * Provide opportunity to make GraphQL queries over HTTP.
 */
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL + '/graphql',
});

const authLink = setContext(async (operation, { headers }) => {
  const expiredDate = getExpiredDate();

  if (operation.operationName !== 'RefreshToken' && expiredDate && Date.parse(expiredDate) <= Date.now()) {
    await refreshToken();
  }

  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || undefined,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    // @ts-ignore
    graphQLErrors.forEach(({ message, statusCode }) => {
      if (statusCode === 'UNAUTHORIZED') {
        const oldHeaders = operation.getContext().headers;
        refreshToken()
          .then((data) => {
            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: data?.accessToken,
              },
            });

            return forward(operation);
          })
          .catch(() => {
            removeAuthToken();
            window.location.reload();
          });
      } else {
        showErrorNotification(message);
      }
    });
  }

  // @ts-ignore
  if (networkError && networkError.name === 'ServerError' && networkError?.statusCode === 401) {
    removeAuthToken();
    window.location.reload();
  }
});

const refreshToken = async () => {
  try {
    const refreshResolverResponse = await client.mutate<{
      refreshToken: AuthPayloadType;
    }>({
      mutation: RefreshTokenDocument,
      variables: { token: getRefreshToken() },
    });

    const tokens = refreshResolverResponse.data?.refreshToken;

    if (tokens) setAuthToken(tokens?.accessToken, tokens?.refreshToken, tokens?.expiresIn);
    return tokens;
  } catch (err) {
    removeAuthToken();
    window.location.reload();
    throw err;
  }
};

const authFlowLink = authLink.concat(errorLink);

const client = new ApolloClient({
  link: authFlowLink.concat(httpLink),
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
});

const withApollo = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithApollo(props) {
    return (
      <ApolloProvider client={client}>
        <Component {...props} />
      </ApolloProvider>
    );
  };
};

export const clearCache = async () => await cache.reset();
export default withApollo;
