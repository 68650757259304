import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Margin from 'shared/ui/components/margin';
import Breadcrumbs from 'shared/ui/components/breadcrumbs';
import { Wrapper } from './ui/styled';
import TracksInputView from './ui/tracksInputView';
import TracksSettingsView from './ui/tracksSettingsView';

const PackagesAdd: FC = () => {
  const [tracks, setTracks] = useState<string[]>([]);
  const [step, setStep] = useState(1);

  const goToSettings = (tracksStr: string) => {
    const tracksFromStr = [...new Set(tracksStr.split('\n').map(str => str.trim()).filter(str => str.length))];
    setTracks(tracksFromStr);
    setStep(2);
  };

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Margin mb={10}>
        <Breadcrumbs
          prevText='Посылки'
          prevClick={() => navigate('/')}
          currentText='Добавление посылок'
        />
      </Margin>
      {step === 1 && <TracksInputView onContinue={goToSettings}/>}
      {step === 2 && <TracksSettingsView setStep={setStep} tracks={tracks}/>}
    </Wrapper>
  );
};

export default PackagesAdd;
