import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from '@consta/header/Menu';
import { ReactComponent as Logo } from 'shared/assets/images/logo.svg';
import Margin from 'shared/ui/components/margin';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import { MenuBlock } from './styled';

const HeaderMenu: FC = () => {
  const location = useLocation();

  const menu = [
    {
      label: 'Посылки',
      href: '/packages',
      active: location?.pathname === '/packages',
    },
    {
      label: 'Мои адреса',
      href: '/addresses',
      active: location?.pathname === '/addresses',
    },
    {
      label: 'Наши склады / Инструкция',
      href: '/instructions',
      active: location?.pathname === '/instructions',
    },
    {
      label: 'Выкуп',
      href: 'https://usaclubshop.com/buy',
      active: false,
    },
  ];

  return (
    <>
      <Margin mr={32}>
        <Logo />
      </Margin>
      <DesktopOnly breakpoint={1280}>
        <MenuBlock>
          <Menu items={menu} />
        </MenuBlock>
      </DesktopOnly>
    </>
  );
};

export default HeaderMenu;