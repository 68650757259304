import React, { FC, useState, useEffect, useMemo } from 'react';
import { useParams , useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Badge } from '@consta/uikit/Badge';
import { useUser } from 'entities/user';
import { useEditPackageMutation } from 'pages/packages/api/editPackage.generated';
import TransferModal from 'pages/packages/ui/transferModal';
import { DeliveryStatus, OrderType, PackageType, PackagesOnUsersRelation } from 'types';
import { useGetPackageQuery } from 'pages/packages/api/getPackage.generated';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import MobileOnly from 'shared/ui/components/mobileOnly';
import Breadcrumbs from 'shared/ui/components/breadcrumbs';
import Margin from 'shared/ui/components/margin';
import { menu } from '../packages/index';
import { PackageContainer } from './styled';
import History from './ui/history';
import AddressField from './ui/addressField';
import OrderModal from './ui/orderModal';

const PackagePage: FC = () => {
  const { id } = useParams();

  const { user } = useUser();

  const navigate = useNavigate();

  const [isEditAddress, setIsEditAddress] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);

  const { data, refetch } = useGetPackageQuery({
    variables: {
      id: parseInt(id as string),
    },
  });

  const [editPackage, {data: dataEdit, error: errorEdit}] = useEditPackageMutation();

  useEffect(() => {
    if (dataEdit?.editPackage?.id && !errorEdit) {
      setIsEditAddress(false);
      refetch();
    }
  }, [dataEdit, errorEdit]);

  const [packageInfo, setPackageInfo] = useState<PackageType | undefined>();

  useEffect(() => {
    if (data?.getPackage) {
      setPackageInfo(data.getPackage as PackageType);
    }
  }, [data]);

  const handleAddressChange = (addressId: number) => {
    if (packageInfo) {
      editPackage({
        variables: {
          id: packageInfo?.id,
          input: {
            addressId,
          },
        },
      });
    }
  };

  const handleOrderChange = (orderId: number) => {
    if (packageInfo) {
      editPackage({
        variables: {
          id: packageInfo?.id,
          input: {
            orderId,
          },
        },
      });
      setShowOrderModal(false);
    }
  };

  const [order, setOrder] = useState<OrderType | undefined>();

  const needShowWeight = useMemo(() => {
    return [
      DeliveryStatus.ReadyForDelivery,
      DeliveryStatus.Delivered,
    ].includes(order?.status as DeliveryStatus);
  }, [order]);

  return (
    <PackageContainer>
      {packageInfo && (
        <>
          <div className='main'>
            <Margin mb={10}>
              <Breadcrumbs
                prevText='Посылки'
                prevClick={() => navigate('/')}
                currentText={`Посылка №${packageInfo?.id}`}
              />
            </Margin>
            <div className='title'>Информация о посылке</div>
            {packageInfo?.status && (
              <MobileOnly breakpoint={1280}>
                <Badge className='badge' status='normal' label={menu[packageInfo?.status]} />
              </MobileOnly>
            )}
            <div className='content'>
              <div className='row long'>
                <div>
                  <div className='subtitle'>Трек-номер</div>
                  <div className='packageInfo'>{packageInfo?.track}</div>
                </div>
                <div>
                  <div className='subtitle'>Дата добавления</div>
                  <div className='packageInfo'>{dayjs(packageInfo?.createdAt).format('DD.MM.YYYY')}</div>
                </div>
                {order && (
                  <div>
                    <div className='subtitle'>Номер заказа</div>
                    <div className='packageInfo'>№{packageInfo?.orderId}</div>
                    {(order?.status === DeliveryStatus.UsaStock || order?.status === DeliveryStatus.Sent) && (
                      <button className='packageInfoButton textButton' onClick={() => setShowOrderModal(true)}>
                        Переместить в другой заказ
                      </button>
                    )}
                  </div>
                )}
                {needShowWeight && (
                  <div>
                    <div className='subtitle'>Вес посылки</div>
                    <div className='packageInfo'>{packageInfo?.approximateWeight} кг</div>
                  </div>
                )}
              </div>
              <div className='row'>
                <div>
                  <div className='subtitle'>Описание посылки</div>
                  <div className='packageInfo'>{packageInfo?.description}</div>
                </div>
                <div>
                  <div className='subtitle'>Адрес</div>
                  <div className='packageInfo'>
                    {isEditAddress
                      ? <AddressField
                          address={packageInfo?.address}
                          onSelect={handleAddressChange}
                        />
                      : packageInfo?.address?.address || 'Самовывоз'
                    }
                    <button className='packageInfoButton textButton' onClick={() => setIsEditAddress(!isEditAddress)}>
                      {isEditAddress ? 'Отменить изменения' : 'Изменить'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='recipientInfo'>
              <div className='titleBlock'>
                <div className='recipientTitle'>Получатель</div>
                {(packageInfo?.users?.find(u => u.relation === PackagesOnUsersRelation.Creator)?.userId === user?.id)
                && (
                  <button className='textButton' onClick={() => setShowTransferModal(true)}>
                    Передать посылку другому пользователю
                  </button>
                )}
              </div>
              <div className='row'>
                <div>
                  <div className='subtitle'>Имя Фамилия</div>
                  <div className='packageInfo'>{user?.name}</div>
                </div>
                <div>
                  <div className='subtitle'>Телефон</div>
                  <div className='packageInfo'>{user?.phone}</div>
                </div>
              </div>
            </div>
          </div>
          <DesktopOnly breakpoint={1280}>
            <History status={packageInfo?.status} />
          </DesktopOnly>
        <TransferModal
          ids={[packageInfo.id]}
          isOpen={showTransferModal}
          onClose={() => setShowTransferModal(false)}
          onTransfer={() => navigate('/')}
        />
        {packageInfo.orderId && (
          <OrderModal
            packageInfo={packageInfo}
            isOpen={showOrderModal}
            onClose={() => setShowOrderModal(false)}
            onSelect={handleOrderChange}
            setOrderData={(o) => setOrder(o)}
          />
        )}
        </>
      )}
    </PackageContainer>
  );
};

export default PackagePage;
