import styled from 'styled-components';

export const EmptyBlock = styled.div`
  width: calc(100% - 80px);
  height: calc(100% - 188px);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    color: #FAFAFA;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;

    button {
      width: fit-content;
      &:last-child {
        color: #FAFAFA !important;
        margin-left: 12px;
      }
    }
  }

  @media (max-width: 1280px) {
    width: calc(100% - 40px);
    height: calc(100% - 164px);
  }

  @media (max-width: 640px) {
    height: calc(100% - 148px);

    .buttons {
      flex-direction: column;
      align-items: center;

      button {
        font-size: 14px;
        &:last-child {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
`;