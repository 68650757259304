import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnterCodeMutationVariables = Types.Exact<{
  input: Types.EnterCodeInput;
}>;


export type EnterCodeMutation = { __typename?: 'Mutation', enterCode: { __typename?: 'AuthPayloadType', accessToken: string, refreshToken?: string | null, expiresIn?: any | null } };


export const EnterCodeDocument = gql`
    mutation EnterCode($input: EnterCodeInput!) {
  enterCode(input: $input) {
    accessToken
    refreshToken
    expiresIn
  }
}
    `;
export type EnterCodeMutationFn = Apollo.MutationFunction<EnterCodeMutation, EnterCodeMutationVariables>;

/**
 * __useEnterCodeMutation__
 *
 * To run a mutation, you first call `useEnterCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnterCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enterCodeMutation, { data, loading, error }] = useEnterCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnterCodeMutation(baseOptions?: Apollo.MutationHookOptions<EnterCodeMutation, EnterCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnterCodeMutation, EnterCodeMutationVariables>(EnterCodeDocument, options);
      }
export type EnterCodeMutationHookResult = ReturnType<typeof useEnterCodeMutation>;
export type EnterCodeMutationResult = Apollo.MutationResult<EnterCodeMutation>;
export type EnterCodeMutationOptions = Apollo.BaseMutationOptions<EnterCodeMutation, EnterCodeMutationVariables>;