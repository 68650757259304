import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TransferredPackagesListQueryVariables = Types.Exact<{
  filter: Types.PackagesListFilterInput;
  pagination: Types.PackagesListPaginationInput;
}>;


export type TransferredPackagesListQuery = { __typename?: 'Query', transferredPackagesList: { __typename?: 'PaginatedPackagesType', total: number, items: Array<{ __typename?: 'PackageType', id: number, track: string, description?: string | null, createdAt: any, weight?: number | null, status?: Types.DeliveryStatus | null, users: Array<{ __typename?: 'PackageToUserRelationType', userId: number, relation: Types.PackagesOnUsersRelation, user: { __typename?: 'UserType', id: number, name?: string | null, nameEn?: string | null } }>, totalCost?: { __typename?: 'CostType', costUsd: number, costRub?: number | null } | null }> } };


export const TransferredPackagesListDocument = gql`
    query transferredPackagesList($filter: PackagesListFilterInput!, $pagination: PackagesListPaginationInput!) {
  transferredPackagesList(filter: $filter, pagination: $pagination) {
    items {
      id
      track
      description
      createdAt
      users {
        userId
        user {
          id
          name
          nameEn
        }
        relation
      }
      weight
      totalCost {
        costUsd
        costRub
      }
      status
    }
    total
  }
}
    `;

/**
 * __useTransferredPackagesListQuery__
 *
 * To run a query within a React component, call `useTransferredPackagesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferredPackagesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferredPackagesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTransferredPackagesListQuery(baseOptions: Apollo.QueryHookOptions<TransferredPackagesListQuery, TransferredPackagesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferredPackagesListQuery, TransferredPackagesListQueryVariables>(TransferredPackagesListDocument, options);
      }
export function useTransferredPackagesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferredPackagesListQuery, TransferredPackagesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferredPackagesListQuery, TransferredPackagesListQueryVariables>(TransferredPackagesListDocument, options);
        }
export type TransferredPackagesListQueryHookResult = ReturnType<typeof useTransferredPackagesListQuery>;
export type TransferredPackagesListLazyQueryHookResult = ReturnType<typeof useTransferredPackagesListLazyQuery>;
export type TransferredPackagesListQueryResult = Apollo.QueryResult<TransferredPackagesListQuery, TransferredPackagesListQueryVariables>;