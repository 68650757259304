import React, { FC, useEffect, useState } from 'react';
import { Select } from '@consta/uikit/Select';
import { DeliveryStatus, Maybe } from 'types';
import { statusesLabels } from 'shared/constants';
import { SelectWrapper } from '../styled';
import { useUpdateShipmentMutation } from '../../api/updateShipment.generated';

interface IProps {
  statusValue: DeliveryStatus,
  shipmentId: number,
  updateList: () => void,
};

type SelectItem = {
  label: string;
  value: DeliveryStatus;
};

const StatusSelect: FC<IProps> = ({ statusValue, shipmentId, updateList }) => {
  const [updateShipment] = useUpdateShipmentMutation();
  const [selectValue, setSelectValue] = useState<Maybe<SelectItem>>();

  const items: SelectItem[] = Object.keys(statusesLabels).map(key => ({
    label: statusesLabels[key],
    value: Object.values(DeliveryStatus).find(item => item === key) || DeliveryStatus.UsaStock,
  }));

  useEffect(() => {
    if (selectValue && selectValue.value !== statusValue) {
      updateShipment({
      variables: {
        id: shipmentId,
        input: {
          status: selectValue.value,
        },
      },
    }).then(() => updateList());
  }
  }, [selectValue]);

  useEffect(() => {
    const selectItem = items.find(item => item.value === statusValue);
    setSelectValue(selectItem);
  }, [statusValue]);

  const handleUpdateShipment = (selectItem: Maybe<SelectItem>) => {
    if (!selectItem) return;
    setSelectValue(selectItem);
  };

  return (
    <SelectWrapper>
      <Select
        items={items}
        getItemKey={(item) => item.value}
        value={selectValue}
        onChange={({ value }) => handleUpdateShipment(value)}
        size='s'
      />
    </SelectWrapper>
  );
};

export default StatusSelect;
