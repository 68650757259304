import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from '@consta/uikit/Button';
import OtpInput from 'react-otp-input';
import Title from 'shared/ui/components/title';
import Subtitle from 'shared/ui/components/subtitle';
import Margin from 'shared/ui/components/margin';
import { CaptchaWrapper, ContentWrapper, OtpInputContainer, Timer, SendAgain } from './styled';

interface dataFormProps {
  phone: string,
  onSend: (code: string) => void,
  onSendPhone: (phone: string, captchaCode?: string) => void,
}

const CodeView = ({ phone, onSend, onSendPhone }: dataFormProps) => {
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [code, setCode] = useState('');
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const id = setInterval(() => {
      if (seconds !== 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(id);
  }, [seconds]);

  const sendAgain = async () => {
    const token = await captchaRef.current?.executeAsync();

    setSeconds(60);
    onSendPhone(phone, token ?? undefined);
  };

  return (
    <div>
      <ContentWrapper>
        <Title>Код из СМС</Title>
        <Margin mb={28} mt={16}>
          <Subtitle>Мы отправили СМС с кодом на номер<br />{phone}</Subtitle>
        </Margin>
        <OtpInputContainer>
          <OtpInput
            value={code}
            onChange={(c) => setCode(c)}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputType='number'
          />

        </OtpInputContainer>
        <Button
          label='Продолжить'
          form='round'
          disabled={code.length !== 4}
          onClick={() => onSend(code)}
        />
        <Margin mt={20}>
          {seconds !== 0 && (
            <Timer>
              Запросить код повторно можно через {seconds} секунд
            </Timer>
          )}
          {seconds === 0 && (
            <SendAgain onClick={sendAgain}>Отправить код повторно</SendAgain>
          )}
        </Margin>
      </ContentWrapper>
      <CaptchaWrapper>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_CAPTCHA_TOKEN as string}
          ref={captchaRef}
          size='invisible'
        />
      </CaptchaWrapper>
    </div>
  );
};

export default CodeView;
