import { useNotificationsListQuery } from 'widgets/notificationsList/api/notificationsList.generated';

export const useNotifications = () => {
  const { loading, data, refetch } = useNotificationsListQuery();

  return {
    listNotifications: data?.notificationsList,
    refetch,
    loading,
  };
};
