import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateShipmentMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateShipmentMutation = { __typename?: 'Mutation', createShipment: { __typename?: 'ShipmentType', id: number, currencyRate?: number | null, status: Types.DeliveryStatus, createdAt: any, summary: { __typename?: 'ShipmentSummaryType', totalApproximateWeigh?: number | null, totalActualWeigh?: number | null, totalPackages: number, totalWeightedPackages: number } } };


export const CreateShipmentDocument = gql`
    mutation createShipment {
  createShipment {
    id
    currencyRate
    status
    createdAt
    summary {
      totalApproximateWeigh
      totalActualWeigh
      totalPackages
      totalWeightedPackages
    }
  }
}
    `;
export type CreateShipmentMutationFn = Apollo.MutationFunction<CreateShipmentMutation, CreateShipmentMutationVariables>;

/**
 * __useCreateShipmentMutation__
 *
 * To run a mutation, you first call `useCreateShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShipmentMutation, { data, loading, error }] = useCreateShipmentMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateShipmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateShipmentMutation, CreateShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShipmentMutation, CreateShipmentMutationVariables>(CreateShipmentDocument, options);
      }
export type CreateShipmentMutationHookResult = ReturnType<typeof useCreateShipmentMutation>;
export type CreateShipmentMutationResult = Apollo.MutationResult<CreateShipmentMutation>;
export type CreateShipmentMutationOptions = Apollo.BaseMutationOptions<CreateShipmentMutation, CreateShipmentMutationVariables>;