import React, { FC } from 'react';
import { BreadcrumbsContainer } from './styled';

interface dataFormProps {
  prevText: string,
  prevClick: () => void,
  currentText: string,
};

const Breadcrumbs: FC<dataFormProps> = ({ prevText, prevClick, currentText }: dataFormProps) => {
  return (
    <BreadcrumbsContainer>
      <div className='prev' onClick={prevClick}>{prevText}</div>
      <div className='divider'>{'>'}</div>
      <div className='current'>{currentText}</div>
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;