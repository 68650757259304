import { RouteObject, Navigate } from 'react-router-dom';
import AddressesPage from 'pages/addresses';
import InstructionsPage from 'pages/instructions';
import LoginPage from 'pages/login';
import WelcomeForm from 'pages/welcomeForm';
import PackagePage from 'pages/package';
import PackagesPage from 'pages/packages';
import PackagesAdd from 'pages/packagesAdd';
import Page404 from 'pages/page404';
import ProfilePage from 'pages/profile';
import { RequireAuth, RequireNotAuth } from 'shared/auth/client';
import Layout from 'shared/ui/layout';

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <RequireNotAuth>
        <LoginPage />
      </RequireNotAuth>
    ),
  },
  {
    path: '/welcome-form',
    element: (<WelcomeForm />),
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={'packages'} replace />,
      },
      {
        path: 'packages',
        element: (
          <RequireAuth>
            <PackagesPage />
          </RequireAuth>
        ),
      },
      {
        path: 'packages/:id',
        element: (
          <RequireAuth>
            <PackagePage />
          </RequireAuth>
        ),
      },
      {
        path: 'packages/add',
        element: (
          <RequireAuth>
            <PackagesAdd />
          </RequireAuth>
        ),
      },
      {
        path: 'addresses',
        element: (
          <RequireAuth>
            <AddressesPage />
          </RequireAuth>
        ),
      },
      {
        path: 'instructions',
        element: (
          <RequireAuth>
            <InstructionsPage />
          </RequireAuth>
        ),
      },
      {
        path: 'profile',
        element: (
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        ),
      },
      {
        path: '*',
        element: (
          <RequireAuth>
            <Page404 />
          </RequireAuth>
        ),
      },
    ],
  },
];
