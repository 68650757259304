import styled from 'styled-components';

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;

  .prev, .divider, .current {
    color: rgba(255, 255, 255, 0.60);
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-right: 10px;
  }

  .prev, .current {
    cursor: pointer;
  }

  .current {
    margin-right: 0;
    color: #FAFAFA;
  }

  @media (max-width: 1280px) {
    .prev, .divider, .current {
      font-size: 14px;
    }
  }

  @media (max-width: 640px) {
    .prev, .divider, .current {
      font-size: 12px;
    }
  }
`;