import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  .TextField {
    width: 373px;
    margin-left: auto;
  }
  h1 {
    margin: 0;
    font-size: 32px;
    font-weight: 600;
    line-height: 120%;
    color: #FAFAFA;
  }
  .track_search {
    .Icon {
      color: #0f9fff;
    }
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  p {
    margin: 0 0 4px 0;
    color: rgba(255, 255, 255, 0.60);
    font-size: 12px;
  }
  .info_value {
    color: #FAFAFA;
    font-size: 14px;
  }
`;

export const TableWrapper = styled.div`
  margin-top: 40px;
  .TableCell-Wrapper_isHeader {
    padding: 12px 16px;
  }
  .weight_wrapper {
    width: 100%;
  }
  .positive_value .customInputText {
    color: rgba(16, 174, 121, 1);
  }
  .negative_value .customInputText {
    color: #fe4343;
  }
`;

export const WeightValues = styled.div`
  .positive_value {
    color: rgba(16, 174, 121, 1);
  }
  .negative_value {
    color: #fe4343;
  }
`;
