export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddRecipientInput = {
  packageId: Scalars['Int']['input'];
  phone: Scalars['String']['input'];
};

export type AddressType = {
  __typename?: 'AddressType';
  address: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isFavorite: Scalars['Boolean']['output'];
  isUsed: Scalars['Boolean']['output'];
};

export type AdminLoginInput = {
  /** Non-empty string, max 16 symbols */
  login: Scalars['String']['input'];
  /** Non-empty string, max 16 symbols */
  password: Scalars['String']['input'];
};

export type AdminType = {
  __typename?: 'AdminType';
  id: Scalars['Int']['output'];
  login: Scalars['String']['output'];
};

export type AuthPayloadType = {
  __typename?: 'AuthPayloadType';
  accessToken: Scalars['String']['output'];
  expiresIn?: Maybe<Scalars['DateTime']['output']>;
  refreshToken?: Maybe<Scalars['ID']['output']>;
};

export type BoxType = {
  __typename?: 'BoxType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  number: Scalars['Int']['output'];
  packages: Array<PackageType>;
  packagesWeight: Scalars['Float']['output'];
  shipmentId: Scalars['Int']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
};

export type BoxesListFilterInput = {
  number?: InputMaybe<Scalars['Int']['input']>;
  packageTrack?: InputMaybe<Scalars['String']['input']>;
  shipmentId: Scalars['Int']['input'];
};

export type BoxesListOrderInput = {
  createdAt?: InputMaybe<SortEnum>;
  number?: InputMaybe<SortEnum>;
  packageTrack?: InputMaybe<SortEnum>;
  packageWeight?: InputMaybe<SortEnum>;
  weight?: InputMaybe<SortEnum>;
};

export type BoxesListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<BoxesListOrderInput>;
};

export type CostType = {
  __typename?: 'CostType';
  costRub?: Maybe<Scalars['Float']['output']>;
  costUsd: Scalars['Float']['output'];
};

export type CreateAddressInput = {
  address: Scalars['String']['input'];
  sdekId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBoxInput = {
  number: Scalars['Int']['input'];
  packages: Array<CreateBoxPackageInput>;
  weight: Scalars['Float']['input'];
};

export type CreateBoxPackageInput = {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  approximateWeight: Scalars['Float']['input'];
  /** Optional string, max length 256 */
  description?: InputMaybe<Scalars['String']['input']>;
  insured?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional positive int */
  packageCost?: InputMaybe<Scalars['Int']['input']>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  /** Required string, max length 128 */
  track: Scalars['String']['input'];
};

export type CreatePackageInput = {
  addressId?: InputMaybe<Scalars['Int']['input']>;
  /** Optional string, max length 256 */
  description?: InputMaybe<Scalars['String']['input']>;
  insured?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional positive int */
  packageCost?: InputMaybe<Scalars['Int']['input']>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  /** Required string, max length 128 */
  track: Scalars['String']['input'];
};

export enum DeliveryStatus {
  Customs = 'CUSTOMS',
  Delivered = 'DELIVERED',
  ReadyForDelivery = 'READY_FOR_DELIVERY',
  RuStok = 'RU_STOK',
  Sent = 'SENT',
  UsaStock = 'USA_STOCK'
}

export type EditAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  sdekId?: InputMaybe<Scalars['String']['input']>;
};

export type EditPackageInput = {
  actualWeight?: InputMaybe<Scalars['Float']['input']>;
  addressId?: InputMaybe<Scalars['Int']['input']>;
  approximateWeight?: InputMaybe<Scalars['Float']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  deliveryCost?: InputMaybe<Scalars['Float']['input']>;
  deliveryRate?: InputMaybe<Scalars['Float']['input']>;
  /** Optional string, max length 256 */
  description?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
};

export type EnterCodeInput = {
  code: Scalars['String']['input'];
};

export type EnterPhoneInput = {
  captchaCode?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addRecipient: Scalars['Boolean']['output'];
  adminLogin: AuthPayloadType;
  createAddress: AddressType;
  createPackage: PackageType;
  createShipment: ShipmentType;
  deleteAddress: Scalars['Boolean']['output'];
  deleteBox: Scalars['Boolean']['output'];
  deleteNotification: Scalars['Boolean']['output'];
  deletePackage: Scalars['Boolean']['output'];
  deleteShipment: Scalars['Boolean']['output'];
  disableTelegramIntegration: UserType;
  editAddress: AddressType;
  editPackage: PackageType;
  enterCode: AuthPayloadType;
  enterPhone: Scalars['Boolean']['output'];
  importBoxes: Scalars['Boolean']['output'];
  readNotification: Scalars['Boolean']['output'];
  refreshToken: AuthPayloadType;
  updateBox: BoxType;
  updateOrder: OrderType;
  updateShipment: ShipmentType;
  updateShipmentClientWeight: Scalars['Boolean']['output'];
  updateUser: UserType;
};


export type MutationAddRecipientArgs = {
  input: AddRecipientInput;
};


export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreatePackageArgs = {
  input: CreatePackageInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteBoxArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePackageArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteShipmentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationEditAddressArgs = {
  id: Scalars['Int']['input'];
  input: EditAddressInput;
};


export type MutationEditPackageArgs = {
  id: Scalars['Int']['input'];
  input: EditPackageInput;
};


export type MutationEnterCodeArgs = {
  input: EnterCodeInput;
};


export type MutationEnterPhoneArgs = {
  input: EnterPhoneInput;
};


export type MutationImportBoxesArgs = {
  boxes: Array<CreateBoxInput>;
  shipmentId: Scalars['Int']['input'];
};


export type MutationReadNotificationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['ID']['input'];
};


export type MutationUpdateBoxArgs = {
  id: Scalars['Int']['input'];
  input: UpdateBoxInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['Int']['input'];
  input?: InputMaybe<UpdateOrderInput>;
};


export type MutationUpdateShipmentArgs = {
  id: Scalars['Int']['input'];
  input: UpdateShipmentInput;
};


export type MutationUpdateShipmentClientWeightArgs = {
  id: Scalars['Int']['input'];
  input: UpdateShipmentClientWeightInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  read: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NumberRangeInput = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type OrderSummaryType = {
  __typename?: 'OrderSummaryType';
  shipmentId?: Maybe<Scalars['Int']['output']>;
  totalActualWeigh?: Maybe<Scalars['Float']['output']>;
  totalApproximateWeigh?: Maybe<Scalars['Float']['output']>;
  totalClientWeigh?: Maybe<Scalars['Float']['output']>;
  totalCost?: Maybe<CostType>;
  totalPackages: Scalars['Int']['output'];
  totalWeight?: Maybe<Scalars['Float']['output']>;
};

export type OrderType = {
  __typename?: 'OrderType';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  paid?: Maybe<Scalars['Float']['output']>;
  paymentType?: Maybe<PaymentType>;
  status: DeliveryStatus;
  summary: OrderSummaryType;
  user: UserType;
};

export type OrdersListFilterInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  packageId?: InputMaybe<Scalars['Int']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  paymentType?: InputMaybe<PaymentType>;
  /** Search query string, min length 1, max length 32 */
  q?: InputMaybe<Scalars['String']['input']>;
  shipmentId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<DeliveryStatus>>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userPhone?: InputMaybe<Scalars['String']['input']>;
};

export type OrdersListOrderInput = {
  deliveryCost?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  insuranceCost?: InputMaybe<SortEnum>;
  status?: InputMaybe<SortEnum>;
};

export type OrdersListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<OrdersListOrderInput>;
};

export type PackageToUserRelationType = {
  __typename?: 'PackageToUserRelationType';
  relation: PackagesOnUsersRelation;
  user: UserType;
  userId: Scalars['Int']['output'];
};

export type PackageType = {
  __typename?: 'PackageType';
  actualWeight?: Maybe<Scalars['Float']['output']>;
  address?: Maybe<AddressType>;
  approximateWeight?: Maybe<Scalars['Float']['output']>;
  box?: Maybe<BoxType>;
  clientWeight?: Maybe<Scalars['Float']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deliveryCost?: Maybe<Scalars['Float']['output']>;
  deliveryRate?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  insured?: Maybe<Scalars['Boolean']['output']>;
  insuredPrice?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<OrderType>;
  orderId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<DeliveryStatus>;
  stock?: Maybe<StockType>;
  totalCost?: Maybe<CostType>;
  track: Scalars['String']['output'];
  users: Array<PackageToUserRelationType>;
  /** Weight to show to user */
  weight?: Maybe<Scalars['Float']['output']>;
};

export type PackagesListFilterInput = {
  boxId?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  deliveryCost?: InputMaybe<NumberRangeInput>;
  deliveryRate?: InputMaybe<NumberRangeInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  paymentType?: InputMaybe<PaymentType>;
  /** Search query string, min length 1, max length 32 */
  q?: InputMaybe<Scalars['String']['input']>;
  shipmentId?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<DeliveryStatus>>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  track?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['Int']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type PackagesListOrderInput = {
  actualWeight?: InputMaybe<SortEnum>;
  approximateWeight?: InputMaybe<SortEnum>;
  clientWeight?: InputMaybe<SortEnum>;
  createdAt?: InputMaybe<SortEnum>;
  deliveryCost?: InputMaybe<SortEnum>;
  deliveryRate?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  insuredPrice?: InputMaybe<SortEnum>;
  orderId?: InputMaybe<SortEnum>;
  track?: InputMaybe<SortEnum>;
};

export type PackagesListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<PackagesListOrderInput>;
};

export enum PackagesOnUsersRelation {
  Creator = 'CREATOR',
  Recipient = 'RECIPIENT'
}

export type PaginatedBoxesType = {
  __typename?: 'PaginatedBoxesType';
  items: Array<BoxType>;
  total: Scalars['Int']['output'];
};

export type PaginatedOrdersType = {
  __typename?: 'PaginatedOrdersType';
  items: Array<OrderType>;
  total: Scalars['Int']['output'];
};

export type PaginatedPackagesType = {
  __typename?: 'PaginatedPackagesType';
  items: Array<PackageType>;
  total: Scalars['Int']['output'];
};

export type PaginatedShipmentsType = {
  __typename?: 'PaginatedShipmentsType';
  items: Array<ShipmentType>;
  total: Scalars['Int']['output'];
};

export type PaginatedUsersType = {
  __typename?: 'PaginatedUsersType';
  items: Array<UserType>;
  total: Scalars['Int']['output'];
};

export enum PaymentType {
  BankTransfer = 'BANK_TRANSFER',
  PaymentGateway = 'PAYMENT_GATEWAY',
  RubCash = 'RUB_CASH',
  UsdCash = 'USD_CASH'
}

export type Query = {
  __typename?: 'Query';
  admin: AdminType;
  boxesList: PaginatedBoxesType;
  getOrder: OrderType;
  getPackage: PackageType;
  getShipment: ShipmentType;
  listAddresses: Array<AddressType>;
  me: UserType;
  notificationsList: Array<NotificationType>;
  ordersList: PaginatedOrdersType;
  packagesList: PaginatedPackagesType;
  shipmentsList: PaginatedShipmentsType;
  stocksList: Array<StockType>;
  transferredPackagesList: PaginatedPackagesType;
  userShipmentPackages: Array<UserShipmentPackageType>;
  usersList: PaginatedUsersType;
};


export type QueryBoxesListArgs = {
  filter: BoxesListFilterInput;
  pagination?: InputMaybe<BoxesListPaginationInput>;
};


export type QueryGetOrderArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetPackageArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetShipmentArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOrdersListArgs = {
  filter?: InputMaybe<OrdersListFilterInput>;
  pagination?: InputMaybe<OrdersListPaginationInput>;
};


export type QueryPackagesListArgs = {
  filter?: InputMaybe<PackagesListFilterInput>;
  pagination?: InputMaybe<PackagesListPaginationInput>;
};


export type QueryShipmentsListArgs = {
  filter?: InputMaybe<ShipmentsListFilterInput>;
  pagination?: InputMaybe<ShipmentsPaginationInput>;
};


export type QueryTransferredPackagesListArgs = {
  filter?: InputMaybe<PackagesListFilterInput>;
  pagination?: InputMaybe<PackagesListPaginationInput>;
};


export type QueryUserShipmentPackagesArgs = {
  filter: UserShipmentPackagesFilterInput;
  order?: InputMaybe<UserShipmentPackagesOrderInput>;
};


export type QueryUsersListArgs = {
  filter?: InputMaybe<UsersListFilterInput>;
  pagination?: InputMaybe<UsersListPaginationInput>;
};

export type ShipmentPaymentSummaryType = {
  __typename?: 'ShipmentPaymentSummaryType';
  paymentType: PaymentType;
  total?: Maybe<Scalars['Float']['output']>;
};

export type ShipmentSummaryType = {
  __typename?: 'ShipmentSummaryType';
  paymentSummary: Array<ShipmentPaymentSummaryType>;
  shipmentWeight: Scalars['Float']['output'];
  totalActualWeigh?: Maybe<Scalars['Float']['output']>;
  totalApproximateWeigh?: Maybe<Scalars['Float']['output']>;
  totalBoxes: Scalars['Int']['output'];
  totalClientWeigh?: Maybe<Scalars['Float']['output']>;
  totalCost?: Maybe<CostType>;
  totalPackages: Scalars['Int']['output'];
  totalWeightedPackages: Scalars['Int']['output'];
};

export type ShipmentType = {
  __typename?: 'ShipmentType';
  createdAt: Scalars['DateTime']['output'];
  currencyRate?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  status: DeliveryStatus;
  summary: ShipmentSummaryType;
};

export type ShipmentsListFilterInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<DeliveryStatus>;
};

export type ShipmentsListOrderInput = {
  createdAt?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  status?: InputMaybe<SortEnum>;
};

export type ShipmentsPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ShipmentsListOrderInput>;
};

export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StockAddressType = {
  __typename?: 'StockAddressType';
  addressFirst: Scalars['String']['output'];
  addressSecond?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  suite?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

export type StockType = {
  __typename?: 'StockType';
  address: StockAddressType;
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type UpdateBoxInput = {
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateOrderInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  paid?: InputMaybe<Scalars['Float']['input']>;
  paymentType?: InputMaybe<PaymentType>;
  status?: InputMaybe<DeliveryStatus>;
};

export type UpdateShipmentClientWeightInput = {
  clientWeight: Scalars['Float']['input'];
  userId: Scalars['Int']['input'];
};

export type UpdateShipmentInput = {
  currencyRate?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<DeliveryStatus>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UserShipmentPackageType = {
  __typename?: 'UserShipmentPackageType';
  actualWeight?: Maybe<Scalars['Float']['output']>;
  clientWeight?: Maybe<Scalars['Float']['output']>;
  count: Scalars['Int']['output'];
  deliveryCost?: Maybe<Scalars['Float']['output']>;
  insuranceCost?: Maybe<Scalars['Float']['output']>;
  recipientId: Scalars['Int']['output'];
  recipientName?: Maybe<Scalars['String']['output']>;
  totalCost?: Maybe<CostType>;
};

export type UserShipmentPackagesFilterInput = {
  insuranceCost?: InputMaybe<NumberRangeInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  shipmentId: Scalars['Int']['input'];
};

export type UserShipmentPackagesOrderInput = {
  actualWeight?: InputMaybe<SortEnum>;
  clientWeight?: InputMaybe<SortEnum>;
  count?: InputMaybe<SortEnum>;
  deliveryCost?: InputMaybe<SortEnum>;
  insuranceCost?: InputMaybe<SortEnum>;
  recipientName?: InputMaybe<SortEnum>;
};

export type UserType = {
  __typename?: 'UserType';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isTelegramNotificationsEnabled: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
};

export type UsersListFilterInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UsersListOrderInput = {
  createdAt?: InputMaybe<SortEnum>;
  email?: InputMaybe<SortEnum>;
  id?: InputMaybe<SortEnum>;
  name?: InputMaybe<SortEnum>;
  nameEn?: InputMaybe<SortEnum>;
  phone?: InputMaybe<SortEnum>;
};

export type UsersListPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<UsersListOrderInput>;
};
