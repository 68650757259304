import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShipmentsListQueryVariables = Types.Exact<{
  filter: Types.ShipmentsListFilterInput;
  pagination: Types.ShipmentsPaginationInput;
}>;


export type ShipmentsListQuery = { __typename?: 'Query', shipmentsList: { __typename?: 'PaginatedShipmentsType', total: number, items: Array<{ __typename?: 'ShipmentType', id: number, status: Types.DeliveryStatus, createdAt: any }> } };


export const ShipmentsListDocument = gql`
    query shipmentsList($filter: ShipmentsListFilterInput!, $pagination: ShipmentsPaginationInput!) {
  shipmentsList(filter: $filter, pagination: $pagination) {
    items {
      id
      status
      createdAt
    }
    total
  }
}
    `;

/**
 * __useShipmentsListQuery__
 *
 * To run a query within a React component, call `useShipmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useShipmentsListQuery(baseOptions: Apollo.QueryHookOptions<ShipmentsListQuery, ShipmentsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentsListQuery, ShipmentsListQueryVariables>(ShipmentsListDocument, options);
      }
export function useShipmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsListQuery, ShipmentsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentsListQuery, ShipmentsListQueryVariables>(ShipmentsListDocument, options);
        }
export type ShipmentsListQueryHookResult = ReturnType<typeof useShipmentsListQuery>;
export type ShipmentsListLazyQueryHookResult = ReturnType<typeof useShipmentsListLazyQuery>;
export type ShipmentsListQueryResult = Apollo.QueryResult<ShipmentsListQuery, ShipmentsListQueryVariables>;