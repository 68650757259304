import styled from 'styled-components';

export const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  color: #FAFAFA;
`;

export const TableWrapper = styled.div`
  margin-top: 40px;
  width: 568px;
  .TableCell-Wrapper_isHeader {
    padding: 12px 16px;
  }
`;

export const SelectWrapper = styled.div`
  padding: 0 12px;
  width: 100%;
  max-width: 262px;
`;
