/* eslint-disable react/no-unused-prop-types */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { SortByProps, Table, TableColumn, TableFilters, TableRow, TableChoiceGroupFilter } from '@consta/uikit/Table';
import { IconArrowDown } from '@consta/icons/IconArrowDown';
import { IconArrowUp } from '@consta/icons/IconArrowUp';
import { Select } from '@consta/uikit/Select';
import { SelectedFilters } from '@consta/uikit/__internal__/src/components/Table/filtering';
import { usePackagesListQuery } from 'pages/packages/api/packagesList.generated';
import { useOrdersListQuery } from 'pages/packages/api/ordersList.generated';
import { useStocksListQuery } from 'pages/packagesAdd/api/stocksList.generated';
import { OrderType, Maybe, PaymentType, UpdateOrderInput, DeliveryStatus, SortEnum } from 'types';
import { showErrorNotification } from 'widgets/notifications/api/showErrorNotification';
import Margin from 'shared/ui/components/margin';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { paymentTypes, statusesLabels } from 'shared/constants';
import Ellipsis from 'shared/ui/components/ellipsis';

import TableInput from 'shared/ui/components/tableInput';
import CustomInputTableFilter from 'shared/ui/components/customInputTableFilter';
import { TableCellPadding } from '../boxes/styled';
import { Title, TableWrapper, StatusWrapper } from './ui/styled';
import { useUpdateOrderMutation } from './api/updateOrder.generated';

type Row = TableRow & {
  order: Maybe<number>;
  track?: string;
  trackUCS?: string;
  stock?: string
  name?: string;
  status: string;
  weight?: number
  shipment?: number
  email?: string
  phone?: string
  comment?: Maybe<string>
  costUsd?: number
  costRub?: number
  insuredPrice?: number
  paymentType?: Maybe<PaymentType>
  paid?: Maybe<number>
};

type RowClassProps = {
  column: TableColumn<any>;
  row: Row;
  isActive: boolean;
}

type OrdersPackages = {
  [key: number]: any[]
}

type OpenedOrders = {
  [key: number]: boolean
}

type SelectItem = {
  label: string;
  value: PaymentType;
};

type EditableCellType = {
  id: string
  comment?: Maybe<string>
  paid?: Maybe<number>
  paymentType?: Maybe<PaymentType>
};

const Orders: FC = () => {
  const pageSize = 40;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const [orders, setOrders] = useState<any[]>([]);
  const [isOpenedOrders, setIsOpenedOrders] = useState<OpenedOrders>({});
  const [currentOrderId, setCurrentOrderId] = useState<Maybe<number>>(null);
  const [ordersPackages, setOrdersPackages] = useState<OrdersPackages>({});

  const [editingField, setEditingField] = useState<'paid' | 'comment' | undefined>();
  const [editingOrderId, setEditingOrderId] = useState<number | undefined>();

  const [orderFilter, setOrderFilter] = useState<Maybe<number>>(null);
  const [trackUCSFilter, setTrackUCSFilter] = useState<Maybe<number>>(null);
  const [statusFilter, setStatusFilter] = useState<Maybe<DeliveryStatus>>(null);
  const [stockFilter, setStockFilter] = useState<Maybe<number>>(null);
  const [shipmentFilter, setShipmentFilter] = useState<Maybe<number>>(null);
  const [paymentFilter, setPaymentFilter] = useState<Maybe<PaymentType>>(null);
  const [phoneFilter, setPhoneFilter] = useState<Maybe<string>>(null);
  const [emailFilter, setEmailFilter] = useState<Maybe<string>>(null);
  const [commentFilter, setCommentFilter] = useState<Maybe<string>>(null);
  const [insuranceCostOrder, setInsuranceCostOrder] = useState<Maybe<SortEnum>>(null);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const [updateOrder] = useUpdateOrderMutation();

  const { data } = useOrdersListQuery({
    variables: {
      filter: {
        id: orderFilter ?? undefined,
        packageId: trackUCSFilter ?? undefined,
        status: statusFilter ? [statusFilter] : undefined,
        stockId: stockFilter || undefined,
        shipmentId: shipmentFilter ?? undefined,
        paymentType: paymentFilter ?? undefined,
        userPhone: phoneFilter ? phoneFilter.replace('+', '') : undefined,
        userEmail: emailFilter ?? undefined,
        comment: commentFilter ?? undefined,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
        order: insuranceCostOrder ? { insuranceCost: insuranceCostOrder } : { id: SortEnum.Asc },
      },
    },
  });

  const { data: dataPackages } = usePackagesListQuery({
    skip: typeof currentOrderId !== 'number',
    variables: {
      filter: {
        orderId: currentOrderId as number,
        statuses: statusFilter ? [statusFilter] : undefined,
        stockId: stockFilter || undefined,
        ids: trackUCSFilter ? [trackUCSFilter] : undefined,
      },
      pagination: {
        limit: 1000,
      },
    },
  });

  useEffect(() => {
    const newOrders = [...orders];
    const index = newOrders.findIndex(order => order.id === currentOrderId?.toString());

    if (currentOrderId) {
      const packages = ordersPackages[currentOrderId]?.map(({
        id, track, status, clientWeight, shipmentId, totalCost, insuredPrice, stock,
      }) => ({
        id: id.toString(),
        order: null,
        track,
        trackUCS: id,
        stock: stock?.code === 'eu' ? 'Европа' : stock?.code === 'us' ? 'США' : undefined,
        status: status ? statusesLabels[status]: undefined,
        weight: clientWeight ?? undefined,
        shipment: shipmentId,
        costUsd: totalCost?.costUsd,
        costRub: totalCost?.costRub,
        insuredPrice,
      }));
      if (isOpenedOrders[currentOrderId] && packages && !newOrders[index + 1]?.trackUCS) {
        newOrders.splice(index + 1, 0, ...packages);
      }
    }
    setOrders(newOrders);
  }, [ordersPackages]);

  const { data: stocks } = useStocksListQuery();

  useEffect(() => {
    setIsOpenedOrders({});
  }, [
    orderFilter,
    trackUCSFilter,
    statusFilter,
    stockFilter,
    shipmentFilter,
    paymentFilter,
    phoneFilter,
    emailFilter,
    commentFilter,
    insuranceCostOrder,
  ]);

  useEffect(() => {
    const items = data?.ordersList?.items;

    if (items) {
      setRowsFromOrders( [...items] as any[]);
      if (!offset) {
        setTotal(data?.ordersList?.total);
      }
    }

  }, [data]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  useEffect(() => {
    const newOrders = {...ordersPackages};
    const items = dataPackages?.packagesList?.items;

    if (items && currentOrderId) {
      newOrders[currentOrderId] = [...items];
      setOrdersPackages(newOrders);
    }
  }, [dataPackages]);

  const setRowsFromOrders = (items: OrderType[]) => {
    const ordersWithRows: Row[] = items.map(({ id, user, status, summary, comment, paymentType, paid }: OrderType) => ({
      id: id.toString(),
      order: id,
      name: user?.name ?? undefined,
      status: statusesLabels[status],
      weight: summary?.totalClientWeigh ?? undefined,
      shipment: summary?.shipmentId ?? undefined,
      phone: user?.phone ? '+' + user?.phone : undefined,
      email: user?.email ?? undefined,
      comment,
      costUsd: summary?.totalCost?.costUsd,
      costRub: summary?.totalCost?.costRub ?? undefined,
      paymentType: paymentType ?? null,
      paid,
    }));
    if (offset) {
      setOrders([...orders, ...ordersWithRows]);
    } else {
      setOrders(ordersWithRows);
    }
  };

  const renderOrderCell = ({ order }: Row) => (
    <>
      {order && (
        <Margin mr={12}>
          <Button
            iconLeft={isOpenedOrders[order] ? IconArrowUp : IconArrowDown}
            view='clear'
            size='xs'
            onClick={() => openOrder(order.toString())}
          />
        </Margin>
      )}
      {order}
    </>
  );

  const renderEllipsisCell = (value?: string) => (
    value ? <Ellipsis>{value}</Ellipsis> : null
  );

  const renderStatusCell = ({ status }: Row) => (
    status
      ? <StatusWrapper>
          <p>{status}</p>
        </StatusWrapper>
      : null
  );

  const renderPaymentTypeCell = ({ paymentType, order }: Row) => {
    const items: SelectItem[] = Object.keys(paymentTypes).map(key => ({
      label: paymentTypes[key],
      value: key as PaymentType,
    }));

    return paymentType !== undefined && (
      <Select
        items={items}
        getItemKey={(item) => item.value}
        value={items.find(item => item.value === paymentType)}
        onChange={({ value }) => order && saveChanges(order, { paymentType: value?.value })}
        size='s'
      />
    );
  };

  const renderEditableCell = ({ id, paid, comment, paymentType }: EditableCellType, isPaid = false) => {
    const paidValue = getPaidValue(paymentType, paid);
    const commentValue = comment?.toString();
    const inputValue = isPaid ? paidValue : commentValue;

    const disabled = +id !== editingOrderId ||
      (isPaid ? editingField !== 'paid' : editingField !== 'comment');

    const newEditingField = isPaid ? 'paid' : 'comment';

    const handleSave = async (value: string | null) => {
      if (!editingOrderId) {
        return;
      }

      if (isPaid) {
        if (!value) {
          setEditingOrderId(undefined);
        } else {
          const isValid = /^[1-9]?[0-9]+[,.]?[0-9]?[0-9]?$/.test(value) && value.split(/[.,]/).shift()!.length < 8;
          if (isValid) {
            await saveChanges(+editingOrderId, { paid: +value });
          } else {
            showErrorNotification('Неправильное значение');
          }
        }
      } else {
        const isValid = !value || value.length < 33;
        if (isValid) {
          await saveChanges(+editingOrderId, { comment: value });
        } else {
          showErrorNotification('Неправильное значение');
        }
      }
    };

    const handleClickEdit = () => {
      setEditingOrderId(+id);
      setEditingField(newEditingField);
    };

    const handleBlur = () => {
      setEditingOrderId(undefined);
      setEditingField(undefined);
    };

    return (
      <TableInput
        inputValue={inputValue}
        disabled={disabled}
        onClear={() => setEditingOrderId(undefined)}
        onSave={handleSave}
        onClickEdit={handleClickEdit}
        onBlur={handleBlur}
        height={32}
      />
    );
  };

  const columns = [
    {
      title: 'НОМЕР ЗАКАЗА',
      accessor: 'order',
      width: 120,
      renderCell: renderOrderCell,
    },
    {
      title: 'ТРЕК-НОМЕР ПОСЫЛКИ',
      accessor: 'track',
      width: 200,
      renderCell: ({ track }: Row) => renderEllipsisCell(track),
    },
    {
      title: 'ТРЕК-НОМЕР UCS',
      accessor: 'trackUCS',
      width: 130,
      renderCell: ({ trackUCS }: Row) => renderEllipsisCell(trackUCS),
    },
    {
      title: 'СКЛАД',
      accessor: 'stock',
      width: 130,
      renderCell: ({ stock }: Row) => renderEllipsisCell(stock || ''),
    },
    {
      title: 'ФИО',
      accessor: 'name',
      width: 180,
      renderCell: ({ name }: Row) => renderEllipsisCell(name),
    },
    {
      title: 'СТАТУС',
      accessor: 'status',
      width: 190,
      renderCell: renderStatusCell,
    },
    {
      title: 'ВЕС',
      accessor: 'weight',
      width: 100,
    },
    {
      title: 'ПАРТИЯ',
      accessor: 'shipment',
      width: 110,
    },
    {
      title: 'СУММА $',
      accessor: 'costUsd',
      width: 120,
    },
    {
      title: 'СУММА РУБ',
      accessor: 'costRub',
      width: 130,
    },
    {
      title: 'СТОИМОСТЬ СТРАХОВКИ $',
      accessor: 'insuredPrice',
      width: 140,
      sortable: true,
    },
    {
      title: 'СПОСОБ ОПЛАТЫ',
      accessor: 'paymentType',
      width: 160,
      renderCell: renderPaymentTypeCell,
    },
    {
      title: 'СУММА ОПЛАТЫ',
      accessor: 'paid',
      width: 160,
      renderCell: ({id, paid, paymentType}: Row) => (
        paid !== undefined ? renderEditableCell({ id, paid, paymentType }, true) : null
      ),
    },
    {
      title: 'ТЕЛЕФОН',
      accessor: 'phone',
      width: 120,
    },
    {
      title: 'EMAIL',
      accessor: 'email',
      width: 160,
      renderCell: ({ email }: Row) => renderEllipsisCell(email),
    },
    {
      title: 'ПРИМЕЧАНИЕ',
      accessor: 'comment',
      width: 180,
      renderCell: ({ comment, id }: Row) => (
        comment !== undefined ? renderEditableCell({ id, comment }) : null
      ),
    },
  ];

  const saveChanges = async (id: number, input: UpdateOrderInput) => {
    await updateOrder({
      variables: { id, input },
    }).then(() => {
      setEditingField(undefined);
      setEditingOrderId(undefined);

      const newOrders = [...orders];
      const row = newOrders.findIndex(ord => ord.order === id);

      if (input.paymentType && row >= 0) {
        newOrders[row] = {...newOrders[row], paymentType: input.paymentType};
      }

      if (input.paid !== undefined && row >= 0) {
        newOrders[row] = {...newOrders[row], paid: input.paid};
      }

      if (input.comment !== undefined && row >= 0) {
        newOrders[row] = {...newOrders[row], comment: input.comment};
      }

      setOrders(newOrders);
    });
  };

  const openOrder = (id: string) => {
    setCurrentOrderId(+id);
    const newIsOpenedOrders = {...isOpenedOrders};

    const deletePackages = () => {
      const newOrders = [...orders];
      const index = newOrders.findIndex(order => order.id === id);
      if (id) newOrders.splice(index + 1, ordersPackages[id]?.length);
      setOrders(newOrders);
    };

    if (newIsOpenedOrders[id] === true) {
      deletePackages();
      newIsOpenedOrders[id] = false;
    }
    else {
      const newOrders = [...orders];
      const index = newOrders.findIndex(order => order.id === id?.toString());

      if (id) {
        const packages = ordersPackages[id]?.map(({
          id: pckgId,
          track,
          status,
          stock,
          clientWeight,
          insuredPrice,
          totalCost,
        }) => ({
          id: pckgId.toString(),
          order: null,
          track,
          trackUCS: pckgId,
          status: status ? statusesLabels[status] : null,
          stock: stock?.code === 'eu' ? 'Европа' : stock?.code === 'us' ? 'США' : undefined,
          weight: clientWeight,
          insuredPrice,
          costUsd: totalCost?.costUsd,
          costRub: totalCost?.costRub,
        }));
        if (packages) newOrders.splice(index + 1, 0, ...packages);
      }
      newIsOpenedOrders[id] = true;
      setOrders(newOrders);
    }
    setIsOpenedOrders(newIsOpenedOrders);
  };

  const getAdditionalClassName = ({ row }: RowClassProps): string => {
    return row.order ? row.paid ? 'success' : 'filled' : '';
  };

  const getPaidValue = (paymentType?: Maybe<PaymentType>, paid?: Maybe<number>) => {
    const val = !paymentType ? '' : paymentType === PaymentType.UsdCash ? ' $' : ' РУБ';
    return !paid ? undefined : (paid + val);
  };

  const filterOrder: TableFilters<typeof orders[number]> = [{
    id: 'order',
    name: 'Номер заказа: ' + orderFilter,
    filterer: () => true,
    field: 'order',
    component: {
      name: CustomInputTableFilter,
      props: {
        placeholder: 'Номер заказа',
        onFilter: (value) => setOrderFilter(+value),
        filterValue: orderFilter,
        inputRegexp: /^\d+$/,
      },
    },
  }];

  const filterTrackUCS: TableFilters<typeof orders[number]> = [{
    id: 'trackUCS',
    name: 'Трек-номер UCS: ' + trackUCSFilter,
    filterer: () => true,
    field: 'trackUCS',
    component: {
      name: CustomInputTableFilter,
      props: {
        placeholder: 'Трек-номер UCS',
        onFilter: (value) => setTrackUCSFilter(+value),
        filterValue: trackUCSFilter,
        inputRegexp: /^\d+$/,
      },
    },
  }];

  const filterShipmentId: TableFilters<typeof orders[number]> = [{
    id: 'shipment',
    name: 'Номер партии: ' + shipmentFilter,
    filterer: () => true,
    field: 'shipment',
    component: {
      name: CustomInputTableFilter,
      props: {
        placeholder: 'Номер партии',
        onFilter: (value) => setShipmentFilter(+value),
        filterValue: trackUCSFilter,
        inputRegexp: /^\d+$/,
      },
    },
  }];

  const filterPhone: TableFilters<typeof orders[number]> = [{
    id: 'phone',
    name: 'Телефон: ' + phoneFilter,
    filterer: () => true,
    field: 'phone',
    component: {
      name: CustomInputTableFilter,
      props: {
        placeholder: 'Телефон',
        onFilter: setPhoneFilter,
        filterValue: phoneFilter,
      },
    },
  }];

  const filterEmail: TableFilters<typeof orders[number]> = [{
    id: 'email',
    name: 'Email: ' + emailFilter,
    filterer: () => true,
    field: 'email',
    component: {
      name: CustomInputTableFilter,
      props: {
        placeholder: 'Email',
        onFilter: setEmailFilter,
        filterValue: emailFilter,
      },
    },
  }];

  const filterComment: TableFilters<typeof orders[number]> = [{
    id: 'comment',
    name: 'Комментарий: ' + commentFilter,
    filterer: () => true,
    field: 'comment',
    component: {
      name: CustomInputTableFilter,
      props: {
        placeholder: 'Комментарий',
        onFilter: setCommentFilter,
        filterValue: commentFilter,
      },
    },
  }];

  const filterStatus: TableFilters<typeof orders[number]> = Object.keys(statusesLabels).map((status) => ({
    id: Object.values(DeliveryStatus).find(item => item === status) || DeliveryStatus.UsaStock,
    name: statusesLabels[status],
    filterer: () => true,
    field: 'status',
  }));

  const filterStock: TableFilters<typeof orders[number]> = [{
    id: 'stock',
    name: 'Склад: ',
    field: 'stock',
    filterer: () => true,
    component: {
      name: TableChoiceGroupFilter,
      props: {
        items: stocks?.stocksList?.map(stock => ({
          name: stock?.code === 'eu' ? 'Европа' : stock?.code === 'us' ? 'США' : '',
          value: stock.id,
        })),
      },
    },
  }];

  const filterPayment: TableFilters<typeof orders[number]> = Object.values(PaymentType).map((payment) => ({
    id: payment,
    name: paymentTypes[payment],
    filterer: () => true,
    field: 'paymentType',
  }));

  const filters = [
    ...filterOrder,
    ...filterTrackUCS,
    ...filterStatus,
    ...filterStock,
    ...filterShipmentId,
    ...filterPayment,
    ...filterPhone,
    ...filterEmail,
    ...filterComment,
  ];

  const handleFilterUpdated = ({
    order,
    trackUCS,
    status,
    stock,
    shipment,
    paymentType,
    phone,
    email,
    comment,
  }: SelectedFilters) => {
    setOffset(0);

    resetFilters();
    if (order?.value) setOrderFilter(+order?.value);
    if (trackUCS?.value) setTrackUCSFilter(+trackUCS?.value);
    if (status?.selected) setStatusFilter(status.selected[0] as DeliveryStatus);
    if (stock?.value?.value) setStockFilter(stock?.value?.value);
    if (shipment?.value) setShipmentFilter(+shipment?.value);
    if (paymentType?.selected) setPaymentFilter(paymentType.selected[0] as PaymentType);
    if (phone?.value) setPhoneFilter(phone?.value);
    if (email?.value) setEmailFilter(email?.value);
    if (comment?.value) setCommentFilter(comment?.value);
  };

  const resetFilters = () => {
    setOrderFilter(null);
    setTrackUCSFilter(null);
    setStatusFilter(null);
    setShipmentFilter(null);
    setPaymentFilter(null);
    setPhoneFilter(null);
    setEmailFilter(null);
    setCommentFilter(null);
    setStockFilter(null);
  };

  const handleSortingUpdated = (value: SortByProps<any> | null) => {
    setOffset(0);
    const sort = value?.sortOrder.toUpperCase() === SortEnum.Asc ? SortEnum.Asc : SortEnum.Desc;
    setInsuranceCostOrder(value ? sort : null);
  };

  return (
    <>
      <Title>Заказы</Title>
      <TableWrapper>
        <TableCellPadding>
          <Table
            rows={orders}
            columns={columns}
            verticalAlign='center'
            borderBetweenColumns
            borderBetweenRows
            defaultExpandAll
            getAdditionalClassName={getAdditionalClassName}
            filters={filters}
            onFiltersUpdated={handleFilterUpdated}
            onSortBy={handleSortingUpdated}
          />
        </TableCellPadding>
      </TableWrapper>
    </>
  );
};

export default Orders;
