import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetShipmentQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetShipmentQuery = { __typename?: 'Query', getShipment: { __typename?: 'ShipmentType', id: number, currencyRate?: number | null, status: Types.DeliveryStatus, createdAt: any, summary: { __typename?: 'ShipmentSummaryType', totalApproximateWeigh?: number | null, totalActualWeigh?: number | null, totalClientWeigh?: number | null, totalPackages: number, shipmentWeight: number, totalBoxes: number, totalWeightedPackages: number, totalCost?: { __typename?: 'CostType', costUsd: number, costRub?: number | null } | null, paymentSummary: Array<{ __typename?: 'ShipmentPaymentSummaryType', paymentType: Types.PaymentType, total?: number | null }> } } };


export const GetShipmentDocument = gql`
    query getShipment($id: Int!) {
  getShipment(id: $id) {
    id
    currencyRate
    status
    createdAt
    summary {
      totalApproximateWeigh
      totalActualWeigh
      totalClientWeigh
      totalPackages
      shipmentWeight
      totalBoxes
      totalWeightedPackages
      totalCost {
        costUsd
        costRub
      }
      paymentSummary {
        paymentType
        total
      }
    }
  }
}
    `;

/**
 * __useGetShipmentQuery__
 *
 * To run a query within a React component, call `useGetShipmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShipmentQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentQuery, GetShipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentQuery, GetShipmentQueryVariables>(GetShipmentDocument, options);
      }
export function useGetShipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentQuery, GetShipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentQuery, GetShipmentQueryVariables>(GetShipmentDocument, options);
        }
export type GetShipmentQueryHookResult = ReturnType<typeof useGetShipmentQuery>;
export type GetShipmentLazyQueryHookResult = ReturnType<typeof useGetShipmentLazyQuery>;
export type GetShipmentQueryResult = Apollo.QueryResult<GetShipmentQuery, GetShipmentQueryVariables>;