import StoreNotify from '../model/storeNotify';
import { NotifyItem } from '../model/types';

export const showSuccessNotification = (message: string): void => {
  const notification: NotifyItem = {
    message,
    key: 0,
    status: 'success',
  };

  StoreNotify.add(notification);
};
