import { FC, useEffect, useState } from 'react';
import { Loader } from '@consta/uikit/Loader';
import { SdekWidgetContainer } from './styled';

interface IProps {
  onChooseAddress: (id: string, address: string) => void;
}

const SdekWidget: FC<IProps> = ({ onChooseAddress }) => {
  const [widget, setWidget] = useState<any>();

  useEffect(() => {
    let didCancel = false;

    const getLib = async () => {
      const { ISDEKWidget } = await import('shared/api/sdek');

      if (!didCancel) {
        const _widget = ISDEKWidget({
          defaultCity: 'Москва',
          link: 'forpvz',
          hidedelt: true,
          path: '/sdek/',
          templatepath: 'https://widget.cdek.ru/widget/scripts/template.php',
          servicepath: 'https://widget.cdek.ru/widget/scripts/service.php',
        });

        _widget.binders.add(
          (point) => onChooseAddress(point?.id, `${point?.cityName}, ${point?.PVZ?.Address || ''}`.trim()),
          'onChoose',
        );

        setWidget(_widget);
      }
    };

    !widget && getLib();

    return () => {
      didCancel = true;
    };
  }, [widget]);

  return (
    <SdekWidgetContainer id='forpvz'>
      {!widget && <Loader/>}
    </SdekWidgetContainer>
  );
};

export default SdekWidget;
