import React, { FC, FocusEvent } from 'react';
import dayjs from 'dayjs';
import { Button } from '@consta/uikit/Button';
import { Table } from '@consta/uikit/Table';
import { Checkbox } from '@consta/uikit/Checkbox';
import { IconTrash } from '@consta/uikit/IconTrash';
import { PackageType } from 'types';
import { LinkButton } from 'pages/packages/styled';
import TableInput from 'shared/ui/components/tableInput';

interface dataFormProps {
  packages: PackageType[],
  chosenPackagesList: number[],
  editingPackageId?: number,
  onCheck: (id: number, value: boolean) => void,
  onCheckAll: (value: boolean) => void,
  onDelete: (id: number) => void,
  onClickEdit: (id: number) => void,
  onClear: () => void,
  onSave: (value: string | null) => void,
  onBlur: () => void,
};

const TableView: FC<dataFormProps> = ({
  packages,
  chosenPackagesList,
  onCheck,
  onCheckAll,
  onDelete,
  editingPackageId,
  onClickEdit,
  onClear,
  onSave,
  onBlur,
}: dataFormProps) => {
  const columns = [
    {
      title: <Checkbox
          checked={chosenPackagesList.length === packages.filter(item => item.users[0]?.userId === item.users[1]?.userId).length}
          onChange={(object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
            onCheckAll(object.checked);
          }}
          size='l'
        />,
      accessor: 'id',
      renderCell: ({id, users}: PackageType) => (
        <Checkbox
          checked={chosenPackagesList.includes(id)}
          onChange={
            (object: {e: React.ChangeEvent<HTMLInputElement>, checked: boolean}): void => {
              onCheck(id, object.checked);
            }
          }
          disabled={users[0]?.userId !== users[1]?.userId}
        />
      ),
      width: 50,
      align: 'center',
    },
    {
      title: 'ТРЕК-НОМЕР',
      accessor: 'track',
      sortable: false,
      renderCell: ({ id, track }: PackageType) => (
        <a href={`/packages/${id}`}>
          <LinkButton>{track}</LinkButton>
        </a>
      ),
    },
    {
      title: 'ОПИСАНИЕ ПОСЫЛКИ',
      accessor: 'description',
      sortable: false,
      renderCell: ({ id, description }: PackageType) => (
        <TableInput
          inputValue={description}
          disabled={id !== editingPackageId}
          onClear={onClear}
          onSave={onSave}
          onClickEdit={() => onClickEdit(id)}
          onBlur={onBlur}
        />
      ),
    },
    {
      title: 'ДАТА ДОБАВЛЕНИЯ',
      accessor: 'createdAt',
      sortable: false,
      width: 160,
      align: 'right',
      renderCell: ({ createdAt }: PackageType) => (
        <>{dayjs(createdAt).format('DD.MM.YYYY')}</>
      ),
    },
    {
      title: 'УДАЛИТЬ',
      sortable: false,
      width: 90,
      align: 'right',
      renderCell: ({ id }: PackageType) => (
        <Button
          label='Удалить'
          view='clear'
          iconLeft={IconTrash}
          onlyIcon
          onClick={() => onDelete(id)}
        />
      ),
    },
  ];

  return (
    <>
      {/* @ts-ignore */}
      <Table rows={packages} columns={columns}
        verticalAlign='center'
        borderBetweenColumns
        zebraStriped='odd'
      />
    </>
  );
};

export default TableView;
