import styled from 'styled-components';

export const WarningCaution = styled.p`
  border-radius: 8px;
  border: 1px solid #F38B01;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.32), 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  padding: 24px 72px;
  color: rgba(250, 250, 250, 1);
  text-align: center;
  font-size: 20px;
  line-height: 140%;
  margin: 0 0 100px 0;

  @media (max-width: 1280px) {
    font-size: 14px;
    padding: 24px 23px;
    margin: 0 0 40px 0;
  }

  @media (max-width: 640px) {
    padding: 20px 16px;
    margin: 0 0 60px 0;
  }
`;

export const ChapterTitle = styled.h2`
  color: #FAFAFA;
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 10px 0;

  @media (max-width: 1280px) {
    font-size: 24px;
  }

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export const ChapterDescription = styled.div`
  color: #FAFAFA;
  font-size: 14px;
  line-height: 150%;
  max-width: 517px;
  p {
    margin: 0 0 40px 0;
  }
  .address {
    max-width: 378px;
    margin: 0 0 36px 0;
  }

  @media (max-width: 1280px) {
    font-size: 12px;
    max-width: 442px;
    .address {
      max-width: 290px;
      margin: 0 0 24px 0;
    }
  }

  @media (max-width: 640px) {
    max-width: 100%;
    .address {
      max-width: 100%;
    }
  }
`;

export const AddressesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 0 0 100px 0;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr;
    gap: 12px;
    margin: 0 0 60px 0;
  }

  @media (max-width: 640px) {
    gap: 24px;
  }
`;

export const AddressItem = styled.div`
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.32), 0px 2px 2px 0px rgba(0, 0, 0, 0.08);
  padding: 32px 24px;
  p {
    font-size: 20px;
    color: #FAFAFA;
    margin: 0 0 16px 0;
  }
  .info_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    p {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 0 0 12px 0;
      .label {
        color: rgba(255, 255, 255, 0.60);
        margin-right: 8px;
        white-space: nowrap;
      }
      .label:after {
        content: ':';
      }
      button {
        margin-left: 8px;
      }
    }
    p:last-child {
      margin: 0;
    }
  }

  @media (max-width: 640px) {
    border-radius: 0;
    border: none;
    padding: 20px 12px;
    .info_wrapper {
      grid-template-columns: 1fr;
    }
    p {
      font-size: 16px;
    }
  }
`;

export const InstructionsList = styled.ol`
  list-style-position: inside;
  max-width: 787px;
  color: #FAFAFA;
  padding: 0;
  li {
    margin: 0 0 48px 0;
    font-size: 18px;
    p {
      font-size: 14px;
      line-height: 160%;
      margin: 0 0 12px 0;
    }
    p:last-child {
      margin: 0;
    }
    p:first-child {
      margin: 16px 0 12px 0;
    }
    span {
      font-weight: 600;
    }
  }

  @media (max-width: 1280px) {
    li {
      font-size: 16px;
      margin: 0 0 36px 0;
      p {
        font-size: 12px;
        line-height: 150%;
        margin: 0 0 8px 0;
      }
    }
  }

  @media (max-width: 640px) {
    li {
      font-size: 14px;
      margin: 0 0 28px 0;
    }
  }
`;
