import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { Loader } from '@consta/uikit/Loader';
import { useAddresses } from 'entities/addresses';
import { AddressType, Maybe } from 'types';
import { CardsContainer, EmptyBlock, TitleContainer } from './ui/styled';
import AddressCard from './ui/addressCard';
import AddressAddModal from './ui/addressAddModal';

const AddressesPage: FC = () => {
  const { listAddresses, loading } = useAddresses();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFavoriteAddressIds, setCurrentFavoriteAddressIds] = useState<Maybe<number[]>>(null);
  const [editingAddress, setEditingAddress] = useState<Maybe<AddressType>>(null);

  useEffect(() => {
    const currentFavoriteIds = listAddresses?.filter(adr => adr.isFavorite).map(adr => adr.id);
    if (currentFavoriteIds) setCurrentFavoriteAddressIds(currentFavoriteIds);
  }, [listAddresses]);

  const renderAddressesCards = (): ReactElement => (
    <CardsContainer>
      {listAddresses?.map(renderAddressCard)}
    </CardsContainer>
  );

  const renderAddressCard = (address: AddressType, idx: number): ReactElement => (
    <AddressCard
      key={`address-${address.id}`}
      address={address}
      idx={idx}
      currentFavoriteAddressIds={currentFavoriteAddressIds}
      onEditAddress={handleEditAddress}
    />
  );

    const handleEditAddress = (address: AddressType): void => {
      setEditingAddress(address);
      setIsModalOpen(true);
    };

  const renderEmptyMessage = (): ReactElement => (
    <EmptyBlock>
      <p>Вы еще не добавили ни одного адреса :(</p>
    </EmptyBlock>
  );

  const handleCloseModal = (): void => setIsModalOpen(false);
  const handleOpenModal = (): void => {
    setEditingAddress(null);
    setIsModalOpen(true);
  };

  return (
    <>
      <TitleContainer>
        <h1>Мои адреса</h1>
        <Button
          label='Добавить новый адрес'
          form='round'
          iconRight={IconAdd}
          className='new_address_btn'
          onClick={handleOpenModal}
        />
      </TitleContainer>

      {loading ? <Loader/> : listAddresses?.length
        ? renderAddressesCards()
        : renderEmptyMessage()
      }

      <AddressAddModal isOpen={isModalOpen} closeModal={handleCloseModal} addressEdit={editingAddress}/>
    </>
  );
};

export default AddressesPage;
