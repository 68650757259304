import styled from 'styled-components';

const DesktopOnly = styled.div<{breakpoint: number}>`
  display: block;

  @media (max-width: ${({ breakpoint }) => `${breakpoint}px`}) {
    display: none;
  }
`;

export default DesktopOnly;