import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { EmptyBlock } from './styled';

const Empty: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <EmptyBlock>
        <p>Вы еще не добавили ни одной посылки :(</p>
        <div className='buttons'>
          <Button
            label='Добавить посылку'
            form='round'
            iconRight={IconAdd}
            onClick={() => navigate('/packages/add')}
          />
          <Button
            label='Инструкция по добавлению посылок'
            form='round'
            view='secondary'
            onClick={() => navigate('/instructions')}
          />
        </div>
      </EmptyBlock>
    </>
  );
};

export default Empty;
