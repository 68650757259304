import styled from 'styled-components';

export const CounterBlock = styled.div`
  position: fixed;
  bottom: 16px;
  width: 414px;
  padding: 20px;
  left: calc(50% - 252px);
  background: #161A1D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .close {
    position: absolute;
    right: 6px;
    top: 6px;
  }

  p {
    font-size: 12px;
    font-weight: 700;
    line-height: 140%;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    color: #FAFAFA;
    margin-bottom: 16px;
  }

  .delete {
    background: #F73B3B !important;
  }

  @media (max-width: 640px) {
    width: calc(100vw - 80px);
    left: 20px;
  }
`;