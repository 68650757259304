import styled from 'styled-components';

export const VerticalMenuContainer = styled.div<{zIndex: number}>`
  position: fixed;
  height: calc(100vh - 60px);
  width: 100vw;
  top: 60px;
  left: 0;
  background: #22272B;
  z-index: ${({ zIndex }) => zIndex};
  overflow-y: auto;
`;