import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditAddressMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.EditAddressInput;
}>;


export type EditAddressMutation = { __typename?: 'Mutation', editAddress: { __typename?: 'AddressType', id: number, address: string, isFavorite: boolean, createdAt: any } };


export const EditAddressDocument = gql`
    mutation EditAddress($id: Int!, $input: EditAddressInput!) {
  editAddress(id: $id, input: $input) {
    id
    address
    isFavorite
    createdAt
  }
}
    `;
export type EditAddressMutationFn = Apollo.MutationFunction<EditAddressMutation, EditAddressMutationVariables>;

/**
 * __useEditAddressMutation__
 *
 * To run a mutation, you first call `useEditAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAddressMutation, { data, loading, error }] = useEditAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAddressMutation(baseOptions?: Apollo.MutationHookOptions<EditAddressMutation, EditAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAddressMutation, EditAddressMutationVariables>(EditAddressDocument, options);
      }
export type EditAddressMutationHookResult = ReturnType<typeof useEditAddressMutation>;
export type EditAddressMutationResult = Apollo.MutationResult<EditAddressMutation>;
export type EditAddressMutationOptions = Apollo.BaseMutationOptions<EditAddressMutation, EditAddressMutationVariables>;