import styled from 'styled-components';

export const ContentContainer = styled.div`
  padding: 40px;
  min-height: calc(100vh - 140px);
  position: relative;

  @media (max-width: 1280px) {
    padding: 28px 20px;
    min-height: calc(100vh - 116px);
  }

  @media (max-width: 640px) {
    padding: 20px;
    min-height: calc(100vh - 100px);
  }
`;