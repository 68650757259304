import React, { FC, ReactElement } from 'react';
import * as Apollo from '@apollo/client';
import { Button } from '@consta/uikit/Button';
import { useNotifications } from 'entities/notifications';
import { NotificationType } from 'types';
import DesktopOnly from 'shared/ui/components/desktopOnly';
import { ReadNotificationMutation, useReadNotificationMutation } from './api/readNotification.generated';
import { CardsWrapper, NotificationsHeader, Wrapper } from './ui/styled';
import NotificationCard from './ui/notificationCard';

const NotificationsList: FC = () => {
  const { listNotifications, refetch } = useNotifications();
  const [readNotification] = useReadNotificationMutation();

  const renderNotification = (notification: NotificationType): ReactElement => (
    <NotificationCard notification={notification} readAllNotifications={handleReadAllNotifications}/>
  );

  const renderNotifications = (): ReactElement => (
    <CardsWrapper>
      {listNotifications?.map(renderNotification)}
    </CardsWrapper>
  );

  const getUnreadIds = () => listNotifications?.filter(notify => !notify.read).map(notify => notify.id) ?? [];

  const handleReadAllNotifications = async () => {
    const unreadIds = getUnreadIds();
    if (!unreadIds.length) return;

    const promises: Promise<Apollo.FetchResult<ReadNotificationMutation>>[] = [];

    unreadIds.forEach(id => {
      promises.push(readNotification({
        variables: {
          id,
        },
      }));
    });

    await Promise.all(promises).then(() => {
      refetch();
    });
  };

  const renderNotificationsList = (): ReactElement => (
    <>
      <DesktopOnly breakpoint={640}>
        <NotificationsHeader>
          <h2>Уведомления</h2>
          {!!getUnreadIds().length && <Button
            label='Отметить все как прочитанные'
            view='clear'
            size='xs'
            onClick={handleReadAllNotifications}
          />}
        </NotificationsHeader>
      </DesktopOnly>
      {renderNotifications()}
    </>
  );

  return (
    <Wrapper>
      {listNotifications?.length
        ? renderNotificationsList()
        : <p className='empty_message'>Нет уведомлений</p>
      }
    </Wrapper>
  );
};

export default NotificationsList;
