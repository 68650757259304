import React, { useEffect, useState} from 'react';
import { SnackBar, SnackBarPropGetItemIcon } from '@consta/uikit/SnackBar';
import { IconAlert } from '@consta/uikit/IconAlert';
import { IconCheck } from '@consta/uikit/IconCheck';
import { IconRing } from '@consta/uikit/IconRing';
import { IconWarning } from '@consta/uikit/IconWarning';
import StoreNotify from '../model/storeNotify';
import { NotifyItem } from '../model/types';
import { Wrapper } from './styled';

let lastKey = 0;
const newKey = (): number => lastKey++;

const Notifications = () => {
  const [notifications, setNotifications] = useState<NotifyItem[]>([]);

  useEffect(() => {
    StoreNotify.register(addNotify);
  }, []);

  const addNotify = (notification: NotifyItem): void => {
    notification.key = newKey();

    setNotifications((prevNotifications)  => [notification, ...prevNotifications]);
  };

  const handleRemoveNotify = (removeNotify: NotifyItem): void => {
    setNotifications(notifications.filter((notify: NotifyItem) => notify.key !== removeNotify.key));
  };

  const getItemIcon: SnackBarPropGetItemIcon<NotifyItem> = (item: NotifyItem) => {
    const statusIcons = {
      alert: IconAlert,
      normal: IconRing,
      system: undefined,
      success: IconCheck,
      warning: IconWarning,
    };

    return statusIcons[item.status];
  };

  return (
    <Wrapper>
      <SnackBar
        items={notifications}
        onItemClose={handleRemoveNotify}
        getItemIcon={getItemIcon}
        getItemAutoClose={() => 5}
      />
    </Wrapper>
  );
};

export default Notifications;
