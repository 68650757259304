import styled from 'styled-components';

export const FooterContainer = styled.div`
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  background: #161A1D;
  padding: 24px 40px;
  height: 57px;

  img {
    width: 60px;
  }

  p, button, a {
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    color: rgba(255, 255, 255, 0.60);
    margin: 0;
  }

  .infoBlock {
    button {
      margin-top: 12px;
      border: none;
      background: transparent;
      cursor: pointer;
      color: #57BCFF;
      padding: 0;
    }
  }

  .linksBlock {
    display: flex;

    a {
      margin-right: 60px;
      display: block;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1280px) {
    padding: 24px 20px;
    width: calc(100% - 40px);
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 102px;

    .imgBlock {
      width: 30%;
      order: 1;
    }
  
    .infoBlock {
      width: 70%;
      order: 3;
      margin-left: 30%;
    }
  
    .linksBlock {
      width: 70%;
      order: 2;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 640px) {
    justify-content: flex-start;
    height: 182px;

    .imgBlock {
      width: 70%;
    }
  
    .infoBlock {
      margin-left: 0;
    }
  
    .linksBlock {
      width: 30%;
      margin-bottom: 20px;
      flex-direction: column;
      align-items: flex-end;

      a {
        margin-right: 0;
        margin-bottom: 12px;
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const FooterTeam = styled.div`
  height: 28px;
  border-top: 1px solid rgba(246, 251, 253, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 40px;
  background: #161A1D;

  div {
    a {
      font-size: 10px;
      display: block;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 4px;
      color: rgba(255, 255, 255, 0.60);
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1280px) {
    padding: 8px 20px;
  }
`;