import styled from 'styled-components';

export const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 120%;
  color: #FAFAFA;
`;

export const TableWrapper = styled.div`
  .icons--Icon {
    --icon-size: var(--graphics-size-s);
  }
  .filled {
    background-color: rgba(246, 251, 253, 0.06);
  }
  .success {
    background-color: rgba(0, 255, 127, 0.26);
  }
  .TableCell-Wrapper {
    padding: 2px 8px;
  }
  .TableCell:not(.filled) {
    .TableCell-Wrapper {
      padding: 8px 8px;
    }
  }
`;

export const StatusWrapper = styled.div`
  border-radius: 4px;
  background-color: rgba(36, 195, 142, 1);
  padding: 4px 8px;
  p {
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
  }
`;
